import React from "react";
import styled from "styled-components";
import { COLORS_PRIMARY } from "../style/variables";
import ButtonStyle from "./ButtonStyle";

const Style = styled(ButtonStyle)`
  color: ${COLORS_PRIMARY};
  border: 1px solid ${COLORS_PRIMARY};
  background: white;
  cursor: pointer;
`;

const SelectedStyle = styled(Style)`
  background: ${COLORS_PRIMARY};
  border: 1px solid ${COLORS_PRIMARY};
  color: white;
  cursor: pointer;
`;

const BorderButton = ({ text, onClick, selected }: { text: string, onClick?: () => void, selected?: boolean }) => {
  if (selected) {
    return <SelectedStyle onClick={onClick}>{text}</SelectedStyle>;
  }
  return <Style onClick={onClick}>{text}</Style>;
};

export default BorderButton;
