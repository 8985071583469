import React, { useEffect, useState } from "react";
import { getImage, getKrazyGale } from "../services/roundsService";
import { createUrlForKrazyGale } from "../audio/audioUtil";
import Player from "../player/Player";
import { Incident } from "../model/incident";
import { GunshotObservation } from "../model/rounds";
import { FlexStartRow } from "../layout/Row";
import Input from "../input/Input";
import styled from "styled-components";

interface KrazyGaleParameters {
  gunshotThreshold: number;
  windowLength: number;
}
const KrazyGaleControllerContainer = styled.div`
  z-index: 10;
  border: 1px solid #0b1525;
  border-radius: 10px;
  padding: 1rem;
`;

const KrazyGaleInput = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const KrazyGaleController = ({
  krazyGaleParameters,
  setKrazyGaleParameters,
}: {
  krazyGaleParameters: KrazyGaleParameters;
  setKrazyGaleParameters: (_: KrazyGaleParameters) => void;
}) => {
  return (
    <KrazyGaleControllerContainer>
      <KrazyGaleInput>
        <h2 className={"Round-title"}>KrazyGale settings</h2>
      </KrazyGaleInput>
      <FlexStartRow>
        <KrazyGaleInput>
          <p className={"Round-title"}>Gunshot threshold</p>
          <Input
            id="gunshotthreshold"
            value={`${krazyGaleParameters.gunshotThreshold}`}
            onBlur={(v) => {
              setKrazyGaleParameters({
                ...krazyGaleParameters,
                gunshotThreshold: +v,
              });
            }}
          />
        </KrazyGaleInput>
        <KrazyGaleInput>
          <p className={"Round-title"}>Window length</p>
          <Input
            id="windowlength"
            value={`${krazyGaleParameters.windowLength}`}
            onBlur={(v) => {
              setKrazyGaleParameters({
                ...krazyGaleParameters,
                windowLength: +v,
              });
            }}
          />
        </KrazyGaleInput>
      </FlexStartRow>
    </KrazyGaleControllerContainer>
  );
};

const ExtendedSliceOverlay = ({
  incident,
  jwtToken,
  url,
  imgUrl,
  observation,
  closeCallback,
}: {
  incident: Incident;
  jwtToken: string;
  url: string;
  observation: GunshotObservation;
  imgUrl: string;
  closeCallback: () => void;
}) => {
  const [img, setImg] = useState<string | undefined>(undefined);
  const [timings, setTimings] = useState<{ [id: string]: number[] }>({});
  const [loading, setLoading] = useState(false);
  const [krazyGaleParameters, setKrazyGaleParamers] = useState({
    gunshotThreshold: 0.8,
    windowLength: 4800,
  });
  useEffect(() => {
    setLoading(true);

    getImage(imgUrl, jwtToken, (i) => {
      setLoading(false);
      setImg(i);
    });

    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
    // eslint-disable-next-line
  }, [incident.id]);
  useEffect(() => {
    setTimings({ "Loading...": [] });
    getKrazyGale(
      createUrlForKrazyGale(
        observation,
        0,
        5,
        5,
        krazyGaleParameters.gunshotThreshold,
        krazyGaleParameters.windowLength
      ),
      jwtToken,
      (i) => {
        const newTiming: { [id: string]: number[] } = {};
        newTiming[`KrazyGale (${i.length})`] = i;
        setTimings(newTiming);
      }
    );
    // eslint-disable-next-line
  }, [incident.id, krazyGaleParameters]);
  console.log("TIMINGS", timings);
  return (
    <div className={"Round-image-overlay"}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {img && !loading && (
          <img className={"Round-image"} alt="Debug shot" src={`${img}`} />
        )}
        <Player
          width={"100%"}
          incident={{ ...incident, peaks: timings }}
          jwtToken={jwtToken}
          url={url}
          additionalPeaks={timings}
          relativePeaks={{}}
          onCursorChange={() => {}}
        />
        <KrazyGaleController
          krazyGaleParameters={krazyGaleParameters}
          setKrazyGaleParameters={setKrazyGaleParamers}
        />
        <p className={"Dashboard-clickable-text"} onClick={closeCallback}>
          Close
        </p>
      </div>
    </div>
  );
};

export default ExtendedSliceOverlay;
