import React, { useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import { setDecibelAction, inputFieldInFocusAction } from "./roundReducer";
import { bindActionCreators, Dispatch } from "redux";
import { store } from "..";

const DecibelAdjuster = ({ decibel, setDecibel }: Props) => {
  const [value, setValue] = useState(decibel);
  return (
    <div
      style={{
        marginLeft: "10px"
      }}
    >
      <input
        className={"Round-input"}
        value={value}
        placeholder={"Amplify(dB)"}
        onChange={event => {
          setValue(event.target.value);
        }}
        onFocus={() => {
          setTimeout(() => store.dispatch(inputFieldInFocusAction(true)), 200);
        }}
        onBlur={() => {
          setDecibel(value);
          store.dispatch(inputFieldInFocusAction(false));
        }}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setDecibel(value);
          }
        }}
      />
    </div>
  );
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  decibel: string;
}

interface DispatchToProps {
  setDecibel: (_: string) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  decibel: state.round.decibel
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setDecibel: setDecibelAction
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(DecibelAdjuster);
