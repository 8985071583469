import { db, store } from "../..";
import { OrganisationInfo } from "../../services/adminService";
import { addOrganisationInfoAction } from "../organisationReducer";

export const getOrganisationInfo = (
  organisationId: string
) => {
  console.log("Get org info", organisationId);
  return db
    .doc("commander")
    .collection("organisations")
    .doc(organisationId)
    .get()
    .then((snapshot) => {
      const data = snapshot.data();
      if (data) {
        
        store.dispatch(addOrganisationInfoAction({units: "metric", mode: "DEFAULT", sales: false, ...data, organisationId} as OrganisationInfo));
      }
    });
};
