import React, { useState, useEffect } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import LogoutWidget from "../logout/LogoutWidget";
import { UserData } from "../model/user";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { setUserAction, setUserDataAction } from "../loginReducer";
import { connect } from "react-redux";
import "./SystemSettings.css";
import "../widget.scss";
import {
  getConfigStore,
  updateConfigStore,
  getConfigStoreSuggestions
} from "../services/adminService";
import LoadingAnimation from "../animation/LoadingAnimation";
import { listenForConfigStoreFiles } from "../firebase/configStore";
import { db } from "..";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";

const Section = (props: ConfigStoreProps) => {
  return (
    <section style={{width: "95vw"}} className={"Widget-section"}>
      <div style={{width: "100%"}} className={"Widget-content"}>
        <div className="Widget-widgets">
          <ConfigStoreWidget {...props} />
        </div>
      </div>
    </section>
  );
};

const NewFile = ({ files }: { files: string[] }) => {
  const [newFile, setNewFile] = useState("");
  const [loading, setLoading] = useState(false);
  return (
    <div style={{ display: "flex" }}>
      <input
        style={{ width: "100%",margin: "0.7rem 0" }}
        placeholder={"Expose name [enter]"}
        onKeyDown={e => {
          if (e.keyCode === 13) {
            setLoading(true);
            db.doc("configStore")
              .set({ names: [newFile, ...files] })
              .then(() => {
                setLoading(false);
                setNewFile("");
              });
          }
        }}
        onChange={e => setNewFile(e.target.value)}
        value={newFile}
      />
      {loading && (
        <span style={{ margin: "0.7rem" }}>
          <LoadingAnimation height={15} width={15} />
        </span>
      )}
    </div>
  );
};

const NameSuggestions = ({
  jwtToken,
  files
}: {
  jwtToken: string;
  files: string[];
}) => {
  const [fileSuggestions, setFileSuggestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!fileSuggestions.length && !loading) {
      setLoading(true);
      getConfigStoreSuggestions(jwtToken, f => {
        setFileSuggestions(f);
        setLoading(false);
      });
    }
  }, [fileSuggestions.length, loading, jwtToken]);
  return (
    <div style={{ display: "flex" }}>
      <select
      style={{ width: "100%" }}
        onChange={e => {
          const value = e.target.value;
          if (files.find(f2 => f2 === value) === undefined) {
            setLoading(true);
            db.doc("configStore")
              .set({ names: [value, ...files] })
              .then(() => {
                setLoading(false);
              });
          }
        }}
      >
        <option disabled selected>
          [triangula-settings suggestions]
        </option>
        {fileSuggestions.map((f: string) => {
          const alreadyAdded = files.find(f2 => f2 === f) !== undefined;

          return (
            <option onSelect={() => {}} value={f}>
              {f}
              {alreadyAdded ? " [Already added]" : ""}
            </option>
          );
        })}
      </select>
      {loading && (
        <span style={{ margin: "0.7rem" }}>
          <LoadingAnimation height={15} width={15} />
        </span>
      )}
    </div>
  );
};

const Properties = ({ jwtToken, files }: ConfigStoreProps) => {
  const [selected, setSelected] = useState("");
  const [content, setContent] = useState("");
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className={"ConfigStore-properties-and-field"}>
      <div>
        <NameSuggestions jwtToken={jwtToken} files={files} />
        <NewFile files={files} />
        {files.map(f => (
          <div>
            <p
              style={{ margin: "0.5rem 0", padding: 0 }}
              className={`Dashboard-clickable-text ${
                selected === f ? "Dashboard-clickable-text-selected" : ""
              }`}
              onClick={() => {
                setLoading(true);
                setSelected(f);
                getConfigStore(jwtToken, f, c => {
                  setLoading(false);
                  setContent(JSON.stringify(c, null, 2));
                });
              }}
            >
              {f}
              {loading && selected === f && (
                <span>
                  <LoadingAnimation height={15} width={15} />
                </span>
              )}
            </p>
          </div>
        ))}
      </div>
      {selected.length > 0 && (
        <div className={"ConfigStore-area-wrap"}>
          <textarea
            className={"ConfigStore-area"}
            value={content}
            onChange={e => setContent(e.target.value)}
          />
          <div style={{ display: "flex" }}>
            {!loading && (
              <div
                style={{ width: "50px", marginTop: "10px" }}
                className={"Round-adjustable-button Round-button"}
                onClick={() => {
                  setSaving(true);
                  db.doc("configStore")
                    .set({ names: files.filter(e => e !== selected) })
                    .then(() => {
                      setSaving(false);
                      setSelected("");
                    });
                }}
              >
                {saving ? (
                  <LoadingAnimation height={15} width={15} color={"white"} />
                ) : (
                  <span>REMOVE</span>
                )}
              </div>
            )}
            {!loading && (
              <div
                style={{ width: "50px", marginTop: "10px" }}
                className={"Round-button"}
                onClick={() => {
                  try {
                    setContent(JSON.stringify(JSON.parse(content), null, 2));
                  } catch (e) {
                    alert(e);
                    return;
                  }
                  setSaving(true);
                  updateConfigStore(jwtToken, selected, content, () =>
                    setSaving(false)
                  );
                }}
              >
                {saving ? (
                  <LoadingAnimation height={15} width={15} color={"white"} />
                ) : (
                  <span>SAVE</span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const ConfigStoreWidget = (props: ConfigStoreProps) => {
  return (
    <div className={"Widget-item"}>
      <h3 className={"Widget-title"}>Config store</h3>
      <p>Read and edit config store properties</p>
      <Properties {...props} />
    </div>
  );
};

interface ConfigStoreProps {
  jwtToken: string;
  files: string[];
}

const ConfigStorePage = (props: Props) => {
  const [files, setFiles] = useState<string[]>([]);
  useEffect(() => {
    const unsubscribe = listenForConfigStoreFiles(props.userData, f =>
      setFiles(f)
    );
    return () => unsubscribe();
  }, [props.userData]);
  return (
    <div className="Dashboard">
      <div className={"Dashboard-header"}>
      <SmallLogoWithDashboard fontSizeRem={1.625}/>
        <LogoutWidget {...props} />
      </div>
      {files.length > 0 && (
        <>
          <Section files={files} jwtToken={props.jwtToken!} />
        </>
      )}
    </div>
  );
};

interface State {}

interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
  user: state.login.user,
  userData: state.login.userData
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
      setUserData: setUserDataAction
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(ConfigStorePage);
