import React, { useEffect, useState } from "react";
import { RoundGunshots, RoundStatistics, ShotStatistics } from "../model/rounds";
import { getRoundInfo } from "../services/roundsService";
import { map } from "../map/AlignmentMap";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { selectRoundAction } from "./roundReducer";
import { connect } from "react-redux";
import LoadingAnimation from "../animation/LoadingAnimation";
import { store } from "../index";
import { updateUrl } from "./urlUtil";
import { cssColorFromBasedOnPercentage } from "../player/Player";

const ShotItem = ({
  selected,
  nr,
  jwtToken,
  selectRound,
  selectedRoundName,
  isLoading,
  shotStatistics,
}: {
  nr: number;
  selected: boolean;
  jwtToken: string;
  selectRound: (_: RoundGunshots) => void;
  selectedRoundName: string;
  isLoading: boolean;
  shotStatistics?: ShotStatistics;
}) => {
  const [loading, setLoading] = useState(false);

  const loadNewRound = (roundNr: number) => {
    setLoading(true);
    getRoundInfo(jwtToken, selectedRoundName, roundNr, round => {
      map.setCenter({
        lat: round.observations[0].device.position.master.lat,
        lng: round.observations[0].device.position.master.lon
      });
      selectRound(round);
      updateUrl(store.getState().round.selectedDevice);
      setLoading(false);
    });
  };

  const percentageCorrected = shotStatistics && (shotStatistics.corrected + shotStatistics.ambiguous)/ (shotStatistics.corrected + shotStatistics.ambiguous + shotStatistics.notCorrected)*100

  return (
    <div style={{ marginLeft: "10px", display: "flex", flexDirection: "column", alignItems: "center"}}>
      <div
        className={`Shot-item ${selected ? "Shot-selected" : ""}`}
        onClick={() => loadNewRound(nr)}
      >
        <div style={{ position: "fixed", marginTop: "2px" }}>
          {(loading || isLoading) && (
            <LoadingAnimation height={30} width={30} />
          )}
        </div>
        <div>{nr}</div>
      </div>
      {percentageCorrected &&(<div style={{ marginLeft: "5px", fontSize: "10px", fontWeight: "bold", color: cssColorFromBasedOnPercentage(percentageCorrected) }}>
         {(percentageCorrected).toFixed(0)}%
      </div>)
      }
    </div>
  );
};

const ShotSelector = ({
  selectedRound,
  selectRound,
  jwtToken,
  selectedRoundName,
  roundStatistics,
}: Props) => {
  const [loadingIndex, setLoadingIndex] = useState<number | undefined>(
    undefined
  );
  useEffect(() => {
    const arrowKeyHandler = (e: KeyboardEvent) => {
      const ARROW_LEFT_KEY = 37;
      const ARROW_RIGHT_KEY = 39;
      const selectedNumber = selectedRound.observations[0].shot.index;
      if (ARROW_LEFT_KEY === e.keyCode && selectedNumber > 0) {
        if (store.getState().round.inputFieldInFocus) {
          return;
        }
        loadNewRound(selectedNumber - 1);
      }
      if (ARROW_RIGHT_KEY === e.keyCode) {
        if (store.getState().round.inputFieldInFocus) {
          return;
        }
        loadNewRound(selectedNumber + 1);
      }
    };

    document.addEventListener("keydown", arrowKeyHandler);
    return () => {
      document.removeEventListener("keydown", arrowKeyHandler);
    };
  });

  const loadNewRound = (roundNr: number) => {
    setLoadingIndex(roundNr);
    getRoundInfo(jwtToken, selectedRoundName, roundNr, round => {
      setLoadingIndex(undefined);
      map.setCenter({
        lat: round.observations[0].device.position.master.lat,
        lng: round.observations[0].device.position.master.lon
      });
      selectRound(round);
      updateUrl(store.getState().round.selectedDevice);
    });
  };

  const items = [];
  for (let i = 1; i <= selectedRound.observations[0].round.total_shots; i++) {
    items.push(
      // @ts-ignore
      <ShotItem
        key={i}
        nr={i}
        selected={i === selectedRound.observations[0].shot.index}
        jwtToken={jwtToken}
        selectRound={selectRound}
        selectedRoundName={selectedRoundName}
        isLoading={loadingIndex === i}
        shotStatistics={roundStatistics && roundStatistics.shotStatistics[i-1]}
      />
    );
  }
  return <div className={"Shot-horizontal-scroll"}>{items}</div>;
};


interface Props extends StateToProps, DispatchToProps, OwnProps {}

interface OwnProps {
  selected: RoundGunshots
}
interface StateToProps {
  selectedRound: RoundGunshots;
  jwtToken: string;
  selectedRoundName: string;
  roundStatistics?: RoundStatistics
}

interface DispatchToProps {
  selectRound: (_: RoundGunshots) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  selectedRound: state.round.selectedRound!,
  jwtToken: state.login.jwtToken!,
  selectedRoundName: state.round.selectedRoundName!,
  roundStatistics: state.round.roundStatistics
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectRound: selectRoundAction
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, OwnProps, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(ShotSelector);
