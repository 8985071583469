// These imports load individual services into the firebase namespace.
import "firebase/auth";
import {db} from "../index";
export interface Configuration {
    timingScores: TimingScores;
    detection: DetectionSettings;
    temperature: number;
}

export interface TimingScores {
    gale: number;
    average: number;
  }
  

export interface DetectionSettings {
  model: string;
  predictionThreshold: number;
  ambientThreshold: number;
  gain: number;
}

export const listenForConfiguration = (
    updateConfiguration: (_: Configuration) => void,
) => {
    db.doc("configuration")
        .onSnapshot(doc => {
            if (doc.exists) {
                const configuration = doc.data() as Configuration;

                updateConfiguration(configuration);
            }
        });
};