import { Action, Reducer } from "redux";
import { Incident } from "../model/incident";
import { ClassificationScore } from "../services/adminService";
const FILTER = "FILTER";
const ADD_INCIDENT = "ADD_INCIDENT";
const ADD_PEAKS = "ADD_PEAKS";
const ADD_WEAPON_TYPES = "ADD_WEAPON_TYPES";
const ADD_CLASSIFICATIONS = "ADD_CLASSIFICATIONS";
const ADD_ORGANISATION = "ADD_ORGANISATION";
const SET_SELECTED_INCIDENT = "SET_SELECTED_INCIDENT";
const INCIDENT_FOR_OVERLAY = "INCIDENT_FOR_OVERLAY";

export interface AddFilterAction extends Action {
  type: "FILTER";
  filter: string | undefined;
}

export const addFilterAction = (
  filter: string | undefined
): AddFilterAction => {
  return { type: FILTER, filter };
};

export interface AddOrganisationAction extends Action {
  type: "ADD_ORGANISATION";
  organisation: OrganisationDetails;
}

export const addOrganisationAction = (
  organisation: OrganisationDetails
): AddOrganisationAction => {
  return { type: ADD_ORGANISATION, organisation };
};

export interface AddIncident extends Action {
  type: "ADD_INCIDENT";
  incident: Incident;
}

export const addIncidentAction = (incident: Incident): AddIncident => {
  return { type: ADD_INCIDENT, incident };
};

export interface AddPeaks extends Action {
  type: "ADD_PEAKS";
  incidentId: string;
  peaks: { [id: string]: number[] };
}

export const addPeaksAction = (
  incidentId: string,
  peaks: { [id: string]: number[] }
): AddPeaks => {
  return { type: ADD_PEAKS, incidentId, peaks };
};

export interface WeaponTypes extends Action {
  type: "ADD_WEAPON_TYPES";
  incidentId: string;
  weaponTypes: { [id: string]: number };
}

export const addWeaponTypesAction = (
  incidentId: string,
  weaponTypes: { [id: string]: number }
): WeaponTypes => {
  return { type: ADD_WEAPON_TYPES, incidentId, weaponTypes };
};

export interface AddClassifications extends Action {
  type: "ADD_CLASSIFICATIONS";
  incidentId: string;
  classifications: ClassificationScore[];
}

export const setSelectedIncidentAction = (
  incident: Incident | undefined
): SetSelectedIncident => {
  return { type: SET_SELECTED_INCIDENT, incident };
};

export interface SetSelectedIncident extends Action {
  type: "SET_SELECTED_INCIDENT";
  incident: Incident | undefined;
}

export const addClassificationsAction = (
  incidentId: string,
  classifications: ClassificationScore[]
): AddClassifications => {
  return { type: ADD_CLASSIFICATIONS, incidentId, classifications };
};

export interface IncidentForOverlay extends Action {
  type: "INCIDENT_FOR_OVERLAY";
  incident: Incident | undefined;
}

export const incidentForOverlayAction = (
  incident: Incident | undefined,
): IncidentForOverlay => {
  
  return { type: INCIDENT_FOR_OVERLAY, incident };
};

export interface OrganisationDetails {
  organisationId: string;
  displayName: string;
  devices: DeviceDetails[];
}

export interface DeviceDetails {
  deviceId: string;
  displayName: string;
}

export interface IncidentReducerState {
  filter: string | undefined;
  incidents: Incident[];
  selectedIncident: Incident | undefined;
  incidentForOverlay: Incident | undefined;
  organisations: OrganisationDetails[];
}

const initialState: IncidentReducerState = {
  filter: undefined,
  selectedIncident: undefined,
  incidents: [],
  organisations: [],
  incidentForOverlay: undefined,
};

const reducer: Reducer<IncidentReducerState> = (
  state: IncidentReducerState = initialState,
  action
) => {
  switch ((action as Action).type) {
    case FILTER: {
      return {
        ...state,
        filter: state.filter === action.filter ? undefined : action.filter
      };
    }
    case ADD_INCIDENT: {
      return {
        ...state,
        incidents: [...state.incidents, action.incident]
      };
    }

    case INCIDENT_FOR_OVERLAY: {
      return {
        ...state,
        incidentForOverlay: action.incident,
      }
    }
    case SET_SELECTED_INCIDENT: {
      return {
        ...state,
        selectedIncident: action.incident
      };
    }
    case ADD_PEAKS: {
      return {
        ...state,
        incidents: state.incidents.map(i =>
          i.id === action.incidentId ? { ...i, peaks: action.peaks } : i
        )
      };
    }
    case ADD_WEAPON_TYPES: {
      return {
        ...state,
        incidents: state.incidents.map(i =>
          i.id === action.incidentId
            ? { ...i, weaponTypes: action.weaponTypes }
            : i
        )
      };
    }
    case ADD_ORGANISATION: {
      return {
        ...state,
        organisations: [...state.organisations, action.organisation]
      };
    }
    case ADD_CLASSIFICATIONS: {
      return {
        ...state,
        incidents: state.incidents.map(i =>
          i.id === action.incidentId
            ? { ...i, classifications: action.classifications }
            : i
        )
      };
    }
    default:
      return state;
  }
};

export default reducer;
