import { db, store } from "../..";
import { ClockTimeRange } from "../../analytics/ClockAnalyticsPage";
import { dateToTut, tutToDate } from "../../util/dateUtil";
import { Scout, ScoutState } from "../model/scout";
import { removeScoutAction, setScoutAction } from "../organisationReducer";

export const listenForScouts = (organisationId: string) => {
  return db
    .doc("commander")
    .collection("organisations")
    .doc(organisationId)
    .collection("scouts")
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const scout: Scout = docToScout(change.doc);
        if (change.type === "added" || change.type === "modified") {
          // console.log(JSON.stringify(change.doc.data()));

          if (scout.status === undefined) {
            // Ignoring scouts without status
            return;
          }

          store.dispatch(setScoutAction(scout));
        }

        if (change.type === "removed") {
          store.dispatch(removeScoutAction(scout));
        }
      });
    });
};

export const queryScouts = (organisationId: string, callback: (_: Scout[]) => void) => {
  return db
    .doc("commander")
    .collection("organisations")
    .doc(organisationId)
    .collection("scouts")
    .get()
    .then((snapshot) => {
      let result: Scout[] = [];
      snapshot.docs.forEach((doc) => {
        // @ts-ignore
        const scout: Scout = docToScout(doc);
        if (scout.state !== ScoutState.OfflineIgnore) {
          result.push(scout);
        }
      });
      callback(result);
    });
};

export const listenForClockAnalytics = (
  deviceId: string,
  callback: (measurement: { [type: string]: number }) => void
) => {
  return db
    .doc("analytics")
    .collection("scouts")
    .doc(deviceId)
    .collection("clock")
    .orderBy("hostTime", "desc")
    .limit(1)
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          callback(change.doc.data());
        }
      });
    });
};

export const queryForClockAnalytics = (
  deviceId: string,
  timeRange: ClockTimeRange,
  callback: (measurement: { [type: string]: number }[]) => void
) => {
  let result = [];
  return db
    .doc("analytics")
    .collection("scouts")
    .doc(deviceId)
    .collection("clock")
    .where("NETWORK", ">", dateToTut(new Date(Date.now() - timeRange * 60 * 60 * 1000)))
    .orderBy("NETWORK", "asc")
    .get()
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        // @ts-ignore
        result.push(doc.data());
      });
      callback(result);
    });
};

export const deleteClockAnalyticsMeasurements = (deviceId: string, finishedCallback: () => void) => {
  return db
    .doc("analytics")
    .collection("scouts")
    .doc(deviceId)
    .collection("clock")
    .get()
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        console.log("Deleting clock measurement", doc.id);
        db.doc("analytics").collection("scouts").doc(deviceId).collection("clock").doc(doc.id).delete();
      });
      finishedCallback();
    });
};

export const getDiffMsSinceLastSeen = (scout: Scout) => {
  const tut = (scout?.status?.receivedTut ?? 0) ? scout.status.receivedTut : 0;
  const lastSeen = tut ? tutToDate(tut) : undefined;
  return lastSeen === undefined ? undefined : getPositiveDiffMs(lastSeen.getTime());
};

export const getPositiveDiffMs = (earlierMs: number) => Math.max(1, Date.now() - earlierMs);

export const SCOUT_OFFLINE_AFTER_MS = 60 * 1000 * 60;
export const SCOUT_OFFLINE_FOR_A_WEEK_MS = 60 * 1000 * 60 * 24 * 7;

export enum TimeState {
  // noinspection JSUnusedGlobalSymbols
  Unknown = 0,
  Initializing = 1,
  Ready = 2,
}

export const getStateFor = (scout: Scout) => {
  const diffMs = getDiffMsSinceLastSeen(scout);
  const notHeardFromInAWeek = diffMs === undefined || diffMs >= SCOUT_OFFLINE_FOR_A_WEEK_MS;
  const notHeardFromInAWhile = diffMs !== undefined && diffMs >= SCOUT_OFFLINE_AFTER_MS;
  return notHeardFromInAWeek
    ? ScoutState.OfflineIgnore
    : notHeardFromInAWhile
    ? ScoutState.Offline
    : scout.status.latitude === undefined ||
      scout.status.latitude === null ||
      scout.status.longitude === undefined ||
      scout.status.longitude === null ||
      scout.status.timeState !== TimeState.Ready
    ? ScoutState.Initializing
    : scout.status.scoutLocation === undefined || scout.status.scoutLocation === null
    ? ScoutState.InaccurateLocation
    : scout.status.listening
    ? ScoutState.Listening
    : ScoutState.Ready;
};
export function docToScout(doc: any) {
  const data = doc.data() as Scout;
  const state = getStateFor(data);
  console.log("Scout state", state, "Device ID", doc.id, "Data", data);

  const scout: Scout = {
    ...data,
    deviceId: doc.id,
    state,
  };
  return scout;
}
