// This import loads the firebase namespace along with all its type information.
// These imports load individual services into the firebase namespace.
import "firebase/auth";
import * as React from "react";
import {HashRouter as Router, Route} from "react-router-dom";
import PlayerPage from "../player/PlayerPage";
import IncidentPage from "../player/IncidentPage";
import Dashboard from "../dashboard/Dashboard";
import AlignmentPage from "../round/RoundPage";
import SystemSettingsPage from "../settings/SystemSettingsPage";
import ConfigStorePage from "../settings/ConfigStorePage";
import MonitorPage from "../monitor/MonitorPage";
import OrganisationPage from "../organisation/OrganisationPage";
import OrganisationIncidentPage from "../player/OrganisationIncidentPage";
import RoundOverviewPage from "../page/RoundOverviewPage";
import ScoutVersionPage from "../player/ScoutVersionPage";
import ClockAnalyticsPage from "../analytics/ClockAnalyticsPage";

const RouterComponent = () => (
    <Router>
        <div>
            <Route exact={true} path="/" component={Dashboard}/>
            <Route exact={true} path="/player/" component={PlayerPage}/>
            <Route exact={true} path="/systemSettings/" component={SystemSettingsPage}/>
            <Route exact={true} path="/scoutVersions/" component={ScoutVersionPage}/>
            <Route exact={true} path="/configStore/" component={ConfigStorePage}/>
            <Route exact={true} path="/monitor/" component={MonitorPage}/>
            <Route exact={true} path="/organisation/:id" component={OrganisationPage}/>
            <Route exact={true} path="/analytics/scout/:deviceId/clock" component={ClockAnalyticsPage}/>
            <Route exact={true} path="/analytics/organisation/:organisationId/clock" component={ClockAnalyticsPage}/>
            <Route exact={true} path="/incidents" component={OrganisationIncidentPage}/>
            <Route exact={true} path="/incidentPlayer/" component={IncidentPage}/>
            <Route exact={true} path="/round/overview" component={RoundOverviewPage}/>
            <Route exact={true} path="/round/" component={AlignmentPage}/>
        </div>
    </Router>
);

export default RouterComponent;
