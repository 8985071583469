import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip, ResponsiveContainer, ReferenceLine } from "recharts";
import React from "react";
import { ClockAnalyticsGraphType } from "../analytics/ClockAnalyticsPage";
import { COLORS } from "./PlotGraph";

interface Props {
  graphData: { [id: string]: number }[];
  graphType: ClockAnalyticsGraphType;
  intervalInMinutes: number;
  height: number;
  width: number;
  excludeClocks: string[];
  masterClock: string;
}

const ClockSyncChart = ({ graphData, graphType, intervalInMinutes, height, width, excludeClocks, masterClock }: Props) => {
  let data = graphData;
  let maxThreshold: number | undefined = undefined;
  let minThreshold: number | undefined = undefined;

  let clocks = findAllClockTypeThatExistsInTheData(data);
  // Sort clocks
  clocks = clocks.sort();
  clocks = clocks.filter((v) => !excludeClocks.includes(v));
  if (graphType === ClockAnalyticsGraphType.NETWORK_DIFF) {
    // Remove network, since this is what we diff
    clocks = clocks.filter((v) => v !== masterClock);
    data = mapDataToDiffFromMasterClock(masterClock, clocks, graphData, intervalInMinutes);
  } else {
    const result = mapDataToRateOfChange(clocks, intervalInMinutes, graphData);
    data = result.data;
    maxThreshold = result.maxRateOfChange;
    minThreshold = result.minRateOfChange;
  }
  return (
    <ResponsiveContainer width="100%" height={400} style={{paddingBottom: "5rem"}}>
      <LineChart
        data={data}
        width={width}
        height={height}
        margin={{
          top: 0,
          right: 10,
          left: 10,
          bottom: 0,
        }}>
        <CartesianGrid strokeDasharray="5 5 " opacity={0.2} />
        <XAxis dataKey="index" type="number" label={{ value: "Time in minutes", position: "insideBottomLeft" }}/>
        {maxThreshold !== undefined && minThreshold !== undefined ? (
          <YAxis
          domain={[minThreshold-0.01, maxThreshold+0.01]}
          label={{ value: "Rate of change: ms/s", angle: -90, position: "insideBottomLeft" }}
          />
          ) : (
            <YAxis
            type="number"
            label={{ value: `Diff from ${masterClock} clock in Ms`, angle: -90, position: "insideBottomLeft" }}
            />
            )}
        <Legend />
        <ReferenceLine y={graphType === ClockAnalyticsGraphType.NETWORK_DIFF ? 0 : 1000} stroke="#000" strokeDasharray="3 3" />
        {clocks.map((clock, i) => (
          <Line
          type="monotone"
          dataKey={`${clock}`}
          name={`${clock}`}
          stroke={`${COLORS[i]}`}
          activeDot={{ r: 8 }}
          strokeWidth={2}
          />
          ))}
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
};

const findAllClockTypeThatExistsInTheData = (data: { [id: string]: number }[]): string[] => {
  if (data.length === 0) {
    return [];
  }
  let clocks = Object.keys(data[data.length - 1]);
  // remove key "hostTime"
  return clocks.filter((v) => v !== "hostTime");
};

const mapDataToDiffFromMasterClock = (masterClock: string, clocks: String[], data: { [id: string]: number }[], intervalInMinutes: number) => {
  if (data.length === 0) {
    return [];
  }
  data = data.sort((a, b) => a["NETWORK"] - b["NETWORK"]);
  const firstMeasurementHostTime = data[0]["NETWORK"]
  let previousMeasurement = data[0];
  const result:{ [id: string]: number }[] = []
  data.forEach((v, i) => {
    // If not big enough interval, ignore
    if ( (v["hostTime"]-previousMeasurement["hostTime"])/1_000_000_000 < 60 * intervalInMinutes) {
      return
    }
    let item = {};
    clocks.forEach((clock) => {
      // Calculate diff in millis
      item[`${clock}`] =
        v[`${clock}`] != undefined && v[`${clock}`] > 0 ? Math.floor((v[`${clock}`] - v[masterClock]) / 1000) : undefined;
    });
    item["index"] = (v["NETWORK"]-firstMeasurementHostTime)/60_000_000;
    previousMeasurement = v;
    result.push(item);
  });
  return result;
};

interface RateOfChangeData {
  data: { [id: string]: number }[];
  maxRateOfChange: number;
  minRateOfChange: number;
}

const mapDataToRateOfChange = (clocks: String[], minuteIntervalBetweenMeasurements: number,  data: { [id: string]: number }[]): RateOfChangeData => {
  data = data.sort((a, b) => a["NETWORK"] - b["NETWORK"]);
  const firstMeasurementHostTime = data[0]["NETWORK"]
  var maxRate: number | undefined = undefined;
  var minRate: number | undefined = undefined;
  let previousMeasurement = data[0];
  const result:{ [id: string]: number }[] = []
  data.forEach((v, i) => {
    // If not big enough interval, ignore
    if ( (v["hostTime"]-previousMeasurement["hostTime"])/1_000_000_000 < 60 * minuteIntervalBetweenMeasurements) {
      return
    }
    let item = {};
    clocks.forEach((clock) => {
      
      let currentValueForClock = v[`${clock}`] != undefined && v[`${clock}`] > 0 ? v[`${clock}`] : undefined;

      let previousValueForClock =
        previousMeasurement[`${clock}`] != undefined && previousMeasurement[`${clock}`] > 0
          ? previousMeasurement[`${clock}`]
          : undefined;

      // calculate the rate of change from the previousMeasurement
      let rateOfChangeMsPerSecond =
        currentValueForClock != undefined && previousValueForClock != undefined
          ? calculateRateOfChangeMsPerSecond(currentValueForClock, previousValueForClock, v, previousMeasurement)
          : undefined;

      // If rateOfChangeMsPerSecond is > maxRate, update maxRate
      if (rateOfChangeMsPerSecond != undefined && (!maxRate || rateOfChangeMsPerSecond > maxRate)) {
        maxRate = rateOfChangeMsPerSecond;
      }
      // If rateOfChangeMsPerSecond is < minRate, update minRate
      if (rateOfChangeMsPerSecond != undefined && (!minRate || rateOfChangeMsPerSecond < minRate)) {
        minRate = rateOfChangeMsPerSecond;
      }
      // Add the rateOfChangeMsPerSecond to the item
      item[`${clock}`] = rateOfChangeMsPerSecond;
    });
    // Index is number of minutes since first measurement
    item["index"] = Math.floor((v["NETWORK"]-firstMeasurementHostTime)/60_000_000);
    previousMeasurement = v;
    result.push(item);
  });
  return { data: result, maxRateOfChange: maxRate ?? 0, minRateOfChange: minRate ?? 0 };
};

function calculateRateOfChangeMsPerSecond(currentValueForClock: number, previousValueForClock: number, v: { [id: string]: number; }, previousMeasurement: { [id: string]: number; }) {
  // Calculate the rate of change from the previousMeasurement
  // Micro pr Ms == Ms pr Second
  return +(getDiffBetweenClocksInMicros(currentValueForClock, previousValueForClock) /
    (getDiffBetweenMeasurementsInMs(v, previousMeasurement))).toFixed(3);
}

function getDiffBetweenMeasurementsInMs(v: { [id: string]: number; }, previousMeasurement: { [id: string]: number; }) {
  return (v["hostTime"] - previousMeasurement["hostTime"]) / 1_000_000;
}

function getDiffBetweenClocksInMicros(currentValueForClock: number, previousValueForClock: number) {
  return currentValueForClock - previousValueForClock;
}

export default ClockSyncChart;

