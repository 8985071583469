import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import { CalculatedTiming } from "../model/timing";
import { MapViewType } from "./MapViewType";

export interface MapboxProps {
  timing?: CalculatedTiming;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  mapType?: MapViewType;
}

export interface Props extends OwnProps, MapboxProps {}

function addTimingLayer(map: Map) {
  map.addLayer({
    id: "timing-lines",
    paint: {
      "line-color": ["get", "color"],
      "line-width": ["get", "width"]
    },
    source: "timing-lines",
    type: "line"
  });
}

function addTimingSource(map: Map, timing?: CalculatedTiming) {
  map.addSource("timing-lines", {
    data: timingLines(timing),
    type: "geojson"
  });
}

export const timingLines = (
  timing?: CalculatedTiming
): GeoJSON.FeatureCollection => {
  return {
    features: createFeatures(timing),
    type: "FeatureCollection"
  };
};

const createFeatures = (timing?: CalculatedTiming): any => {
  const features = [];
  if (timing) {
    // @ts-ignore
    features.push({
      geometry: {
        coordinates: [
          [timing._location.gunshot.lon, timing._location.gunshot.lat],
          [timing._location.impact.lon, timing._location.impact.lat]
        ],
        type: "LineString"
      },
      id: `gunshot2impact`,
      properties: {
        id: `gunshot2impact`,
        color: `yellow`,
        width: 3
      },
      type: "Feature"
    });
    // @ts-ignore
    features.push({
      geometry: {
        coordinates: [
          [timing._location.gunshot.lon, timing._location.gunshot.lat],
          [timing._location.device.lon, timing._location.device.lat]
        ],
        type: "LineString"
      },
      id: `gunshot2device`,
      properties: {
        id: `gunshot2device`,
        color: `blue`,
        width: 3
      },
      type: "Feature"
    });
    // @ts-ignore
    features.push({
      geometry: {
        coordinates: [
          [timing._location.device.lon, timing._location.device.lat],
          [timing._location.impact.lon, timing._location.impact.lat]
        ],
        type: "LineString"
      },
      id: `impact2device`,
      properties: {
        id: `impact2device`,
        color: `blue`,
        width: 3
      },
      type: "Feature"
    });
    if (timing._location.intersection) {
      // @ts-ignore
      features.push({
        geometry: {
          coordinates: [
            [
              timing._location.intersection.lon,
              timing._location.intersection.lat
            ],
            [timing._location.device.lon, timing._location.device.lat]
          ],
          type: "LineString"
        },
        id: `gunshot2device`,
        properties: {
          id: `gunshot2device`,
          color: `blue`,
          width: 3
        },
        type: "Feature"
      });
    }
  }
  return features;
};

function updateTimingSource(map: Map, timing?: CalculatedTiming) {
  map
    .getSource("timing-lines")
    // @ts-ignore
    .setData(timingLines(timing));
}

const TimingLinesLayer = ({ map, loaded, timing }: Props) => {
  if (map && loaded) {
    if (!map.getSource("timing-lines")) {
      addTimingSource(map, timing);
      addTimingLayer(map);
    } else {
      updateTimingSource(map, timing);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedRound = state.round.selectedRound;
  const observation =
    selectedRound &&
    selectedRound.observations.find(
      o => o.device.name === state.round.selectedDevice
    );

  return {
    timing: observation ? observation.calculatedTiming : undefined
  };
};

// @ts-ignore
export default connect<MapboxProps, {}, {}>(mapStateToProps)(TimingLinesLayer);
