import {db} from "../index";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {UserData} from "../model/user";

interface TmpUserData {
    displayName: string;
    email: string;
    organisation: string;
}

export const listenForUserInfo = (
    user: firebase.User,
    userDataAction: (_: UserData | undefined) => void,
    initialLoad: () => void,
) => {
    db.doc("commander").collection("users")
        .doc(user.uid)
        .onSnapshot(doc => {
            if (doc.exists) {
                const data = doc.data() as TmpUserData;
                const userData = {
                    displayName: data.displayName,
                    email: data.email,
                    organisationId: data.organisation,
                };

                userDataAction(userData);
                initialLoad();
            } else {
                initialLoad();
            }
        });
};