import { Action, Reducer } from "redux";
import { Configuration } from "../firebase/configuration";
const UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION";

export interface UpdateConfigurationAction extends Action {
  type: "UPDATE_CONFIGURATION";
  configuration: Configuration;
}

export const updateConfigurationAction = (
  configuration: Configuration
): UpdateConfigurationAction => {
  return { type: UPDATE_CONFIGURATION, configuration };
};

export interface ConfigurationReducerState{
  configuration: Configuration | undefined;
}

const initialState: ConfigurationReducerState = {
  configuration: undefined,
};

const reducer: Reducer<ConfigurationReducerState> = (
  state: ConfigurationReducerState = initialState,
  action
) => {
  switch ((action as Action).type) {
    case UPDATE_CONFIGURATION: {
      return {...state, configuration: action.configuration};
    }
    default:
      return state;
  }
};

export default reducer;
