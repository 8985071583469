export const A_KEY = 65;
export const M_KEY = 77;
export const D_KEY = 68;
export const S_KEY = 83;
export const Y_KEY = 89;
export const R_KEY = 82;
export const N_KEY = 78;
export const L_KEY = 76;
export const U_KEY = 85;
export const ONE_KEY = 49;
export const TWO_KEY = 50;
export const THREE_KEY = 51;
export const QUESTION_MARK_KEY = 191;
export const SPACE_KEY = 32;
export const G_KEY = 71;
export const E_KEY = 69;
export const F_KEY = 70;
export const DOT_KEY = 190;
