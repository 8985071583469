import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import { nextShotOnSaveAction } from "./roundReducer";
import { bindActionCreators, Dispatch } from "redux";

const NextShotOnSave = ({ nextShotOnSave, setNextShotOnSave }: Props) => {
  return (
    <div
    style={{"marginBottom": "0.25rem"}}
      className={nextShotOnSave ? "Incident-tag-filtered" : "Incident-tag"}
      onClick={() => {
        setNextShotOnSave(!nextShotOnSave);
      }}
    >
      Next on save
    </div>
  );
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  nextShotOnSave: boolean;
}

interface DispatchToProps {
  setNextShotOnSave: (_: boolean) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  nextShotOnSave: state.round.nextShotOnSave
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setNextShotOnSave: nextShotOnSaveAction
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(NextShotOnSave);
