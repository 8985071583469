import React from "react";
import './Player.css';
import WaveSurfer from 'wavesurfer.js';
// @ts-ignore
// import Timeline from 'wavesurfer.js/dist/plugin/wavesurfer.timeline';
// @ts-ignore
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';
// @ts-ignore
import MinimapPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.minimap.min.js';
// @ts-ignore
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
// @ts-ignore
window.WaveSurfer = require('wavesurfer.js');

let waveSurfer: WaveSurfer;

interface State {
    currentTime: number;
    currentTimeSamples: number;
    slider: number;
}

function updateRegions(start: number) {
    waveSurfer.clearRegions();
    waveSurfer.addRegion({
        start: start,
        end: start + 2,
        color: 'hsla(230,98%,68%,0.1)'
    });
    waveSurfer.addRegion({
        start: start + 0.1,
        end: start + 2,
        color: 'hsla(0,100%,40%,0.1)'
    });
}

class Player extends React.Component<{}, State> {
    private myRef = React.createRef<HTMLDivElement>();

    constructor(props: {}) {
        super(props);
        this.state = {
            currentTime: 0,
            currentTimeSamples: 0,
            slider: 0,
        };
    }

    public componentDidMount() {
        const HEIGHT = 300;

        waveSurfer = WaveSurfer.create({
            container: "#waveform",
            mediaControls: true,
            height: HEIGHT,
            maxCanvasWidth: 500,
            minPxPerSec: 1,
            // backend: 'MediaElement',
            forceDecode: true,
            scrollParent: true,
            waveColor: "#53c68c",
            progressColor: "#203864",
            // barWidth: 5,
            // barGap: 1,
            // normalize: true,
            // pixelRatio: 0.3,
            responsive: true,
            xhr: {
                // @ts-ignore
                mode: 'no-cors',
                onreadystatechange: () => {
                }

            },
            plugins: [
                // @ts-ignore
                CursorPlugin.create({
                    showTime: true,
                    opacity: 1,
                    customShowTimeStyle: {
                        'background-color': '#000',
                        color: '#fff',
                        padding: '2px',
                        'font-size': '10px'
                    }
                }),
                MinimapPlugin.create({
                    container: '#wave-minimap',
                    waveColor: '#53c68c',
                    progressColor: '#203864',
                    height: 50
                }),
                RegionsPlugin.create({
                    regions: [],
                })
            ]
        });
        waveSurfer.on('ready', function () {
            updateRegions(0.7806753318510135);
        });
        waveSurfer.on('interaction', () => {

            setTimeout(
                () => this.setState((prev) => ({
                    ...prev,
                    currentTime: waveSurfer.getCurrentTime(),
                    currentTimeSamples: waveSurfer.getCurrentTime() * 48000
                })), 200);
            //
            // document.getElementById('time-total').innerText = Math.round(totalTime * 1000);
            // document.getElementById('time-current').innerText = Math.round(currentTime * 1000);
            // document.getElementById('time-remaining').innerText = Math.round(remainingTime * 1000);
        });
        waveSurfer.on('audioprocess', () => {
            if (waveSurfer.isPlaying()) {

                this.setState((prev) => ({
                    ...prev,
                    currentTime: waveSurfer.getCurrentTime(),
                    currentTimeSamples: waveSurfer.getCurrentTime() * 48000
                }));
                //
                // document.getElementById('time-total').innerText = Math.round(totalTime * 1000);
                // document.getElementById('time-current').innerText = Math.round(currentTime * 1000);
                // document.getElementById('time-remaining').innerText = Math.round(remainingTime * 1000);
            }
        });

    }

    render() {
        return (
            <div className={"Player"}>
                <div id={"wave-minimap"} className={"Player-minimap-container"} ref={this.myRef}/>
                <div id={"waveform"} className={"Player-container"} ref={this.myRef}/>
                <div id={"wave-spectrogram"} className={"Player-timeline"}/>
                <input id="slider" data-action="zoom" type="range" min="0" max="150000" value={this.state.slider}
                       style={{width: "100px"}} onChange={(event) => {
                    const newValue = +event.target.value;
                    this.setState((p) => ({...p, slider: newValue}));
                    if (newValue > 90000 && waveSurfer.params.pixelRatio !== 90000) {
                        waveSurfer.params.pixelRatio = 0.3;
                        waveSurfer.setHeight(600);
                    } else if (newValue > 50000 && waveSurfer.params.pixelRatio !== 50000) {
                        waveSurfer.params.pixelRatio = 0.5;
                        waveSurfer.setHeight(600);
                    } else if (newValue > 24000 && waveSurfer.params.pixelRatio !== 24000) {
                        waveSurfer.params.pixelRatio = 0.7;
                        waveSurfer.setHeight(600);
                    } else if (newValue <= 24000 && waveSurfer.params.pixelRatio !== 2) {
                        waveSurfer.params.pixelRatio = 2;
                        waveSurfer.setHeight(600);
                    }

                    waveSurfer.zoom(newValue);
                }
                }/>
                <input id="slider" data-action="zoom" type="range" min="0" max="22000" value={this.state.slider}
                       style={{width: "100px"}} onChange={(event) => {
                    const newValue = +event.target.value;
                    this.setState((p) => ({...p, slider: newValue}));
                    waveSurfer.zoom(newValue);
                }
                }/>
                <div onClick={() => {
                    // waveSurfer.load('example/media/demo.wav');
                    // waveSurfer.load('https://storage.cloud.google.com/triangula-status-audio/sliced/Steinsjoen-2018-11-06/40643723/3e4303ed-94a3-447b-bf63-7f8b37410878-peak150-shift0.wav');
                    // let options = waveSurfer.util.extend(
                    //     {
                    //         url: "https://storage.cloud.google.com/triangula-status-audio/sliced/Steinsjoen-2018-11-06/40643723/3e4303ed-94a3-447b-bf63-7f8b37410878-peak150-shift0.wav",
                    //         responseType: 'arraybuffer'
                    //     },
                    //     waveSurfer.params.xhr
                    // );
                    // fetchFile();

                    waveSurfer.load("3e4303ed-94a3-447b-bf63-7f8b37410878-peak150-shift0.wav");
                    // waveSurfer.load("sliced-lots.wav");
                }}>Load file
                </div>
                <div onClick={() => {
                    // waveSurfer.load('example/media/demo.wav');
                    waveSurfer.play();
                }}>Play
                </div>
                <div>{this.state.currentTimeSamples} samples ({this.state.currentTime} seconds)</div>
            </div>
        );
    }
}

export default Player;