import { GunshotObservation } from "../model/rounds";

export const createUrl = (objectPath: string, bucket: string) => {
  return `https://api.triangula.no/backoffice/audio/storage?url=${objectPath}&bucket=${bucket}`;
};

export const createUrlForShot = (
  observation: GunshotObservation,
  db: number,
  noiseReduction: boolean,
  pre?: number,
  post?: number
) => {
  return `https://api.triangula.no/backoffice/audio/gunshot?roundName=${
    observation.round.name
  }&deviceId=${observation.device.name}&audioTut=${
    observation.audio.tut
  }&db=${db}&noiseReduction=${noiseReduction}${pre ? `&pre=${pre}` : ""}${
    pre ? `&post=${post}` : ""
  }`;
};

export const createUrlForKrazyGale = (
  observation: GunshotObservation,
  db: number,
  pre: number,
  post: number,
  gunshotThreshold: number,
  windowLength: number,
) => {
  return `https://api.triangula.no/classifiers/gunshot/krazyGale?roundName=${
    observation.round.name
  }&deviceId=${observation.device.name}&audioTut=${
    observation.audio.tut
  }&pre=${pre}&post=${post}&gunshotThreshold=${gunshotThreshold}&windowLength=${windowLength}`;
};

export const createImageUrl = (
  observation: GunshotObservation,
  pre?: number,
  post?: number
) => {
  return `https://api.triangula.no/backoffice/audio/gunshot/image?roundName=${
    observation.round.name
  }&deviceId=${observation.device.name}&shotNumber=${observation.shot.index}${
    pre ? `&pre=${pre}` : ""
  }${pre ? `&post=${post}` : ""}`.replace("+", "%2B");
};
