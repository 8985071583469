import { db, store } from "../..";
import { dateToTut } from "../../util";
import { tutToDate } from "../../util/dateUtil";
import { Observation } from "../model/observation";
import { Scout } from "../model/scout";
import {
  addObservationAction,
  cleanupObservationAction,
} from "../organisationReducer";

export const listenForObservations = (organisationId: string) => {
  setInterval(() => store.dispatch(cleanupObservationAction()), 1000);

  return db
    .doc("admin")
    .collection("potentialIncidents")
    .where("organisationId", "==", organisationId)
    .where("tut",">",dateToTut() - (1000000*60*60))
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change: any) => {
        if (change.type === "added") {
          const data = change.doc.data();
          const observation = {
            deviceId: data.deviceId,
            timeInMillis: tutToDate(data.tut).getTime(),
          } as Observation;
          if (observation.timeInMillis > Date.now() - 60000) {
            store.dispatch(addObservationAction(observation));
          }
        }
      });
    });
};

export const getDiffMsSinceLastSeen = (scout: Scout) => {
  const tut = scout.status.dateTime ? scout.status.dateTime : 0;
  const lastSeen = tut ? tutToDate(tut) : undefined;
  return lastSeen === undefined
    ? undefined
    : getPositiveDiffMs(lastSeen.getTime());
};

export const getPositiveDiffMs = (earlierMs: number) =>
  Math.max(1, Date.now() - earlierMs);

export const SCOUT_OFFLINE_AFTER_MS = 60 * 1000 * 60;
export const SCOUT_OFFLINE_FOR_A_WEEK_MS = 60 * 1000 * 60 * 24 * 7;

export enum TimeState {
  // noinspection JSUnusedGlobalSymbols
  Unknown = 0,
  Initializing = 1,
  Ready = 2,
}
