import { db, store } from "../..";

import { addGunshotAction, removeScoutAction } from "../organisationReducer";

export const ONE_HOUR_AGO = 3600000;

export interface Gunshot {
    id: string, 
    timestamp: Date
}

export const listenForGunshots = (organisationId: string) => {
  return db
    .doc("commander")
    .collection("organisations")
    .doc(organisationId)
    .collection("gunshots")
    .where("timestamp", ">", new Date(Date.now() - ONE_HOUR_AGO))
    .onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change: any) => {
          if (change.type === "added") {
              const data = change.doc.data() as any;
              data.timestamp = data.timestamp.toDate();
          store.dispatch(addGunshotAction(data as Gunshot));
        }

        if (change.type === "removed") {
          store.dispatch(removeScoutAction(change.doc.id));
        }
      });
    });
};