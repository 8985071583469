import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import LoadingAnimation from "../animation/LoadingAnimation";
import Input from "../input/Input";
import { FlexStartRow } from "../layout/Row";
import { ApplicationState } from "../reducers";
import { OrganisationInfo } from "../services/adminService";
import { setOperatingMode } from "../services/organisationService";
import { ONE_HOUR_AGO } from "./firestore/triangulations";
import { ScoutState } from "./model/scout";

const StatusSummary = ({ name, count }: { name: string; count: number }) => {
  return (
    <div className={"Organisation-status-summary"}>
      <p>{name}</p>
      <p>{count}</p>
    </div>
  );
};

const OperatingMode = ({
  organisationInfo,
  organisationJwt,
}: {
  organisationInfo: OrganisationInfo;
  organisationJwt: string;
}) => {
  const [loading, setLoading] = React.useState(false);
  if (loading) {
    return <LoadingAnimation height={30} width={30} />;
  }
  return (
    <FlexStartRow>
      <h4 style={{ paddingRight: "0.5rem" }}>Operating mode</h4>
      <Input
        id={`operating-mode-input`}
        placeholder={"Not specified"}
        value={organisationInfo.mode}
        onConfirm={(t) => {
          setLoading(true);
          setOperatingMode(t, organisationJwt)
            .then(() => {
              setLoading(false);
            })
            .catch((e) => {
              alert(e.message);
            });
        }}
      />
    </FlexStartRow>
  );
};



const OrganisationStatus = ({
  observationCount,
  listeningScoutCount,
  gunshotCount,
  organisationInfo,
  organisationJwt,
}: Props) => {
  return (
    <>
      <section
        className={["Organisation-status-section", "Organisation-section"].join(
          " "
        )}
      >
        <StatusSummary name={"Shots last hour"} count={gunshotCount} />
        <StatusSummary name={"Listening devices"} count={listeningScoutCount} />
        <StatusSummary name={"Events last minute"} count={observationCount} />
      </section>
      <section
        className={["Organisation-status-section", "Organisation-section"].join(
          " "
        )}
      ></section>
      {organisationInfo && organisationJwt && (
        <section className="Widget-item">
          <OperatingMode
            organisationInfo={organisationInfo}
            organisationJwt={organisationJwt}
          />
        </section>
      )}
    </>
  );
};

interface StateToProps {
  observationCount: number;
  listeningScoutCount: number;
  gunshotCount: number;
  organisationInfo?: OrganisationInfo;
  organisationJwt: string | undefined;
}

interface DispatchFromProps {}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  observationCount: Object.values(state.organisation.observations).reduce(
    (acc, v) => acc + v.length,
    0
  ),
  listeningScoutCount: Object.values(state.organisation.scouts).filter(
    (s) => s.state === ScoutState.Listening
  ).length,
  gunshotCount: state.organisation.gunshots.filter(
    (g) => g.timestamp.getTime() > Date.now() - ONE_HOUR_AGO
  ).length,
  organisationInfo: state.organisation.organisationInfo,
  organisationJwt: state.organisation.userJwtToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationStatus);
