import { store } from "./index";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import axios from "axios";
import { setJwtTokenAction } from "./loginReducer";

export const getJwtExpiration = (jwtToken: string) => {
  const exp = parseJwt(jwtToken).exp * 1000;
  console.log("JWT expiry date", exp);
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const createJwtToken = (user: firebase.User) => {
  user.getIdToken(true).then((token) => {
    axios
      .post("https://api.triangula.no/auth/generateToken", { token })
      .then((response) => {
        if (response.status === 200) {
          const jwtToken = response.data.token;
          const parsedToken = parseJwt(jwtToken);
          console.log(new Date(parsedToken.exp * 1000), new Date());
          const timeoutForRenewal =
            new Date(parsedToken.exp * 1000).getTime() - Date.now() - 60000;
          console.log(`Renewing token in ${timeoutForRenewal}milliseconds`);
          setTimeout(() => createJwtToken(user), timeoutForRenewal);
          store.dispatch(setJwtTokenAction(jwtToken));
        } else {
          store.dispatch(setJwtTokenAction(undefined));
        }
      });
  });
};