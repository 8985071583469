import * as React from "react";
import { store } from "..";
import { inputFieldInFocusAction } from "./roundReducer";

export const CustomInputField = ({
  className,
  disabled,
  style,
  value,
  onChange
}: {
  disabled?: boolean;
  className: string;
  style?: React.CSSProperties;
  value: string;
  onChange: (value: string) => void;
}) => {
  return (
    <input
      disabled={disabled ? true : false}
      className={className}
      style={style}
      onFocus={() => {
        setTimeout(() => store.dispatch(inputFieldInFocusAction(true)), 200);
      }}
      onBlur={() => {
        store.dispatch(inputFieldInFocusAction(false));
      }}
      onKeyDown={e => {
        if (e.keyCode === 13) {
          store.dispatch(inputFieldInFocusAction(false));
        }
      }}
      value={value}
      onChange={event => {
        onChange(event.target.value);
      }}
    />
  );
};
