import React, { useEffect, useState } from "react";
import LoadingAnimation from "../animation/LoadingAnimation";
import { Incident } from "../model/incident";
import { createUrl } from "../audio/audioUtil";
import Player from "../player/Player";
import { ApplicationState } from "../reducers";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { setSelectedIncidentAction } from "../player/incidentReducer";
import { markAsNotGunshot } from "../services/adminService";

const MarkAsNotGunshotOverlay = ({
  jwtToken,
  incident,
  closeCallback
}: {
  jwtToken: string;
  incident: Incident;
  closeCallback: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
  });


  return (
    <div
      className={"Round-image-overlay"}
      style={{
        textAlign: "center",
        padding: "70px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <div className={"Round-subheader"}>Mark as not gunshot</div>
      <p className={"Round-item"}>
        Take an extra look to make sure you want to mark this incident as not a
        gunshot
      </p>

      <div>
        <Player
          incident={incident}
          jwtToken={jwtToken}
          altId={`${incident.id}_mark_gunshot`}
          url={createUrl(incident.objectPath, "triangula-scout-audio")}
        />
      </div>
      <div
        style={{ width: "200px" }}
        className={"Round-button"}
        onClick={() => {
          setLoading(true);
          markAsNotGunshot(incident.deviceId, incident.id, jwtToken).then(
            res => {
              if (res.status === 200) {
                setLoading(false);
                closeCallback();
              }
            }
          );
        }}
      >
        {loading ? (
          <LoadingAnimation height={15} width={15} color={"white"} />
        ) : (
          "Confirmed not a gunshot"
        )}
      </div>
    </div>
  );
};

const MarkAsNotGunshotOverlayWrapper = ({
  jwtToken,
  incident,
  setSelectedIncident
}: Props) => {
  return incident ? (
    <MarkAsNotGunshotOverlay
      jwtToken={jwtToken!}
      incident={incident}
      closeCallback={() => {
        setSelectedIncident(undefined);
      }}
    />
  ) : (
    <></>
  );
};

interface StateToProps {
  incident: Incident;
  jwtToken: string | undefined;
}

interface DispatchFromProps {
  setSelectedIncident: (_: Incident | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  incident: state.incident.selectedIncident!,
  jwtToken: state.login.jwtToken
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSelectedIncident: setSelectedIncidentAction
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(MarkAsNotGunshotOverlayWrapper);
