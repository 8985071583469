export enum Status {
  Ok = "Ok",
  Warning = "Warning",
  Critical = "Critical",
  Up = "UP",
  Down = "DOWN"
  
}

export interface StatusMessage {
  message: string;
  component: string;
  id: string;
  status: Status;
  useInPopup?: boolean;
}


