import * as React from "react";

interface Props {
  backgroundColor: string;
  width: number;
  height: number;
}

const ArrowDownIcon = ({ width, height, backgroundColor }: Props) => (
  
  <svg xmlns="http://www.w3.org/2000/svg" height={`${height}px`} viewBox="0 0 24 24" width={`${width}px`} fill={backgroundColor}><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7 10l5 5 5-5H7z"/></svg>
);

export default ArrowDownIcon;