import styled from "styled-components";

export const ColumnCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ColumnEnd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;