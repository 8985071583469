import {combineReducers, Reducer} from "redux";
import loginReducer, {LoginReducerState} from "./loginReducer";
import incidentReducer, {IncidentReducerState} from "./player/incidentReducer";
import roundReducer, {RoundReducerState} from "./round/roundReducer";
import configurationReducer, { ConfigurationReducerState } from "./player/systemReducer";
import organisationReducer, { OrganisationReducerState } from "./organisation/organisationReducer";

export interface ApplicationState {
  login: LoginReducerState;
  incident: IncidentReducerState;
  round: RoundReducerState;
  configuration: ConfigurationReducerState;
  organisation: OrganisationReducerState;
}

const combine: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  login: loginReducer,
  incident: incidentReducer,
  round: roundReducer,
  configuration: configurationReducer,
  organisation: organisationReducer,
});

export default combine;
