import * as React from "react";
import { cssColorFromBasedOnPercentage } from "../../player/Player";
import { AudioStatistics } from "../model/scout";
import "./ambientNoiseWidget.scss";

const AmbientNoiseWidget = ({
  statistics,
}: {
  statistics?: AudioStatistics;
}) => {
  if (
    statistics !== undefined &&
    statistics.sampleAverages !== undefined &&
    statistics.sampleAverages !== null &&
    statistics.sampleAverages.length > 0
  ) {
    const maxValue = 96;
    const value = statistics.sampleAverages[0].sampleAverage;
    const valueWithOffset = value + 96;
    const color = cssColorFromBasedOnPercentage(100 - valueWithOffset);
    return (
      <div
        className={"AmbientNoiseWidget-container"}
      >
        <p className={"AmbientNoiseWidget-label"}>Noise level</p>
        <progress
          id={"myprogressbar"}
          className={"AmbientNoiseWidget-progressBar"}
          max={maxValue}
          value={valueWithOffset}
        />
        <div
          style={{
            color,
            fontSize: "0.6rem",
            fontWeight: "bold",
          }}
        >
          {value.toFixed(0)} dBfs
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default AmbientNoiseWidget;
