import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { UserData } from "../model/user";
import { db } from "../index";


export const listenForConfigStoreFiles = (
  user: UserData | undefined,
  callback: (_: string[]) => void
): (() => void) => {
  if (user) {
    return db.doc("configStore").onSnapshot((snapshot) => {
      const document = snapshot.data();
      if (document) {
        callback(document["names"] as string[]);
      }
    });
  } else {
    return () => {};
  }
};
