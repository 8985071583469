// @ts-ignore
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { useState } from "react";
import WarningIcon from "../icons/WarningIcon";
import ActionButton from "../button/ActionButton";
import {
  addToFastTrack,
  removeFromFastTrack,
} from "../services/fastTrackService";
import { Status, StatusMessage } from "../status/status";
import { addPopupAction } from "./organisationReducer";
import { PairValue } from "../layout/Pair";
import { H3 } from "../layout/Title";
import { VerCenteredRow } from "../layout/Row";



const FastTrack = ({ deviceId, inFastTrack, jwtToken, addPopup }: Props) => {
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <H3>Fast track</H3>
      <VerCenteredRow>
        <WarningIcon width={30} height={30} backgroundColor={"#f59e0b"} />
        <PairValue style={{ marginLeft: "0.5rem" }}>
          Be aware that it may take 1 minute until devices are added.
        </PairValue>
      </VerCenteredRow>
      {loading ? (
        <PairValue>Updating fast track...</PairValue>
      ) : inFastTrack ? (
        <ActionButton
          style={{ marginLeft: "0rem" }}
          text={"REMOVE FROM FAST TRACK"}
          onClick={() => {
            setLoading(true);
            removeFromFastTrack(
              jwtToken,
              deviceId,
              createSuccessCallback(
                addPopup,
                deviceId,
                `${deviceId.substr(0, 4)} removed from fast track`,
                setLoading
              ),
              createErrorCallback(
                addPopup,
                deviceId,
                `Failed to remove ${deviceId.substr(0, 4)} from fast track`,
                setLoading
              )
            );
          }}
        />
      ) : (
        <ActionButton
          style={{ marginLeft: "0rem" }}
          text={"ADD TO FAST TRACK"}
          onClick={() => {
            setLoading(true);
            addToFastTrack(
              jwtToken,
              deviceId,
              createSuccessCallback(
                addPopup,
                deviceId,
                `${deviceId.substr(0, 4)} added to fast track`,
                setLoading
              ),
              createErrorCallback(
                addPopup,
                deviceId,
                `Failed to add ${deviceId.substr(0, 4)} to fast track`,
                setLoading
              )
            );
          }}
        />
      )}
    </div>
  );
};

interface Props extends OwnProps, StateToProps, DispatchToProps {}

interface OwnProps {
  deviceId: string;
}

interface StateToProps {
  inFastTrack: boolean;
  jwtToken: string;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addPopup: addPopupAction,
    },
    dispatch
  );

interface DispatchToProps {
  addPopup: (_: StatusMessage) => void;
}

const mapStateToProps = (state: any, ownProps: OwnProps) => ({
  inFastTrack:
    state.organisation.fastTrack.find(
      (s: string) => s === ownProps.deviceId
    ) !== undefined,
  jwtToken: state.login.jwtToken,
});

export default connect<StateToProps, DispatchToProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(FastTrack);

function createSuccessCallback(
  addPopup: (_: StatusMessage) => void,
  deviceId: string,
  message: string,
  setLoading: (_: boolean) => void
): (scouts: string[]) => void {
  return () => {
    setLoading(false);
    addPopup({
      id: `${deviceId}_fasttrack`,
      message,
      component: "FastTrack",
      status: Status.Ok,
    });
  };
}

function createErrorCallback(
  addPopup: (_: StatusMessage) => void,
  deviceId: string,
  message: string,
  setLoading: (_: boolean) => void
): ((errorMsg: string) => void) | undefined {
  return () => {
    setLoading(false);
    addPopup({
      id: `${deviceId}_fasttrack`,
      message,
      component: "FastTrack",
      status: Status.Warning,
    });
  };
}
