import React from "react";
import styled from "styled-components";
import BorderButton from "../../button/BorderButton";
import { FlexStartRow } from "../../layout/Row";
import { RoundChange, RoundOverview } from "../../model/rounds";
import { createRoundAnalyzerUrl } from "./util";

const Content = styled.div`
  margin-bottom: 3rem;
`;

const Author = ({
  author,
  change,
  round,
}: {
  author: string;
  change: RoundChange;
  round: string;
}) => {
  return (
    <a
      href={createRoundAnalyzerUrl(round, change.shotNumber, change.deviceId)}
      target={"_blank"}
    >
      <BorderButton text={`${author} • ${change.shotNumber}`} />
    </a>
  );
};

const LastCorrected = ({ overview }: { overview: RoundOverview }) => {
  const changesForDevice = Object.entries(overview.changes);
  return (
    <Content>
      <FlexStartRow>
        {changesForDevice.map(([author, change]) => (
          <Author author={author} change={change} round={overview.roundName} />
        ))}
      </FlexStartRow>
    </Content>
  );
};

export default LastCorrected;
