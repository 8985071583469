import React from "react";
import { AudioFile, getPeakCategoryGraphData } from "../services/adminService";
import GraphOverlay from "./GraphOverlay";

const PeakCategoryOverlay = ({
  file,
  jwtToken,
  closeCallback
}: {
  file: AudioFile;
  jwtToken: string;
  closeCallback: () => void;
}) => {
  return (
    <GraphOverlay
    title={"Peak categories"}
    file={file}
    jwtToken={jwtToken}
    apiRequest={ () => getPeakCategoryGraphData(file, jwtToken)}
    closeCallback={closeCallback}
    />
  );
};

export default PeakCategoryOverlay;
