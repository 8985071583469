import * as mapbox from "mapbox-gl";
import { Map, ScaleControl } from "mapbox-gl";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Mapbox from "./Mapbox";
import { MapViewType } from "./MapViewType";
import { getMapStyling } from "./mapUtil";
import { ApplicationState } from "../reducers";
import { selectDeviceAction, suggestTargetAction } from "../round/roundReducer";
import { store } from "..";
import { updateUrl } from "../round/urlUtil";

export interface MainMapState {
  loaded: boolean;
  currentMapType: MapViewType;
  movingEventInProgress: boolean;
  height: number;
  width: number;
}

interface Props extends MainMapProps, DispatchFromProps {}

interface MainMapProps {
  jwtToken: string | undefined;
  mapType: MapViewType;
}

export let map: Map;

class MainMap extends React.Component<Props, MainMapState> {
  private myRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      currentMapType: MapViewType.ROADMAP,
      height: window.innerHeight,
      loaded: false,
      movingEventInProgress: false,
      width: window.innerWidth
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  public updateWindowDimensions() {
    this.setState(state => ({
      ...state,
      height: window.innerHeight,
      width: window.innerWidth
    }));
  }

  public updateWindowDimensionsOnOrientationChange(e: DeviceOrientationEvent) {
    setTimeout(() => {
      this.setState(state => ({
        ...state,
        height: window.innerHeight,
        width: window.innerWidth
      }));
      map.getCanvas().style.width = `${window.innerWidth}px`;
      map.getCanvas().style.height = `${window.innerHeight}px`;
      setTimeout(() => {
        map.resize();
      }, 300);
    }, 200);
  }

  public componentDidMount() {
    const lat = 34;
    const lng = 5;
    const zoom = 17;
    window.addEventListener("resize", this.updateWindowDimensions);

    // @ts-ignore
    mapbox.accessToken =
      "pk.eyJ1IjoibWFyaXVzaGVycmluZyIsImEiOiJjanI2YnpvaWMyNnBhNDRxcWg4d3g4bmo3In0.TmJdk1oZ0ceEFx1ih0-1Og";
    if (this.myRef.current) {
      map = new Map({
        center: [lat, lng],
        container: this.myRef.current,
        style: "mapbox://styles/mapbox/cjaudgl840gn32rnrepcb9b9g",
        zoom
      });
      map.doubleClickZoom.disable();

      const scale = new ScaleControl({
        maxWidth: 400,
        unit: "metric"
      });
      map.addControl(scale);

      map.on("load", () => {
        mapOnClick(map, "local-location", id => {
          updateUrl(id);
          this.props.setSelectedDevice(id)
        }
        );
        dblClickSetTarget(map, "local-location", () =>
          console.log("SET TARGET")
        );

        this.setState({ loaded: true });
        // mapOnClick(map, "scouts", id =>
        //     this.props.setFocus({type: FocusType.Scouts, id}, false)
        // );
        // scoutOnPress(map, this.props.jwtToken);
      });

      // map.on("dragstart", () => {
      //     this.props.disableAutomaticFocus();
      // });
      //
      // map.on("rotatestart", () => {
      //     if (!this.state.movingEventInProgress) {
      //         this.props.resetMapAngleCallback(false);
      //     }
      // });
      //
      // map.on("rotateend", () => {
      //     if (this.state.movingEventInProgress) {
      //         this.props.resetMapAngleCallback(true);
      //         this.setState(prevState => ({
      //             ...prevState,
      //             movingEventInProgress: false,
      //         }));
      //     }
      // });
    }
  }

  public render() {
    const style = {
      bottom: "0",
      height: `${window.innerHeight}px`,
      width: this.state.width
    };

    const { mapType } = this.props;
    const { loaded, currentMapType } = this.state;

    if (map && loaded) {
      // scoutOnPress(map, this.props.jwtToken, this.props.dragScoutsMode);

      if (mapType !== currentMapType) {
        // removeLayersAndSources(map);

        map.setStyle(getMapStyling(mapType));
        setTimeout(() => {
          this.setState(prevState => ({
            ...prevState,
            currentMapType: mapType
          }));
        }, 1000);
      }
    }

    return (
      <Mapbox
        map={map}
        styling={style}
        mapRef={this.myRef}
        focused={undefined}
        loaded={loaded}
        currentMapType={this.state.currentMapType}
      />
    );
  }
}

export const mapOnClick = (
  map: Map,
  layer: string,
  onClick: (id: string | undefined) => void
) => {
  map.on("click", e => {
    const features = map.queryRenderedFeatures(
      [
        [e.point.x, e.point.y],
        [e.point.x, e.point.y]
      ],
      { layers: [layer] }
    );

    if (features.length > 0) {
      // @ts-ignore
      onClick(features[0].properties.id);
    } else if (layer === "local-location") {
      onClick(undefined);
    }
  });
};

export const dblClickSetTarget = (
  map: Map,
  layer: string,
  onClick: () => void
) => {
  map.on("dblclick", e => {
    store.dispatch(
      suggestTargetAction({
        position: { lon: e.lngLat.lng, lat: e.lngLat.lat }
      })
    );
  });
};

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
  mapType: MapViewType.SATELLITE
});

interface DispatchFromProps {
  setSelectedDevice: (_: string | undefined) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSelectedDevice: selectDeviceAction
    },
    dispatch
  );

export default connect<MainMapProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(MainMap);
