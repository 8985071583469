import React, { useEffect, useState } from "react";
import { AudioFile } from "../services/adminService";
import Plot from "react-plotly.js";

export const COLORS = [
  "red",
  "blue",
  "green",
  "pink",
  "orange",
  "purple",
  "grey",
  "black",
];

const GraphOverlay = ({
  file,
  apiRequest,
  closeCallback,
  title,
}: {
  file: AudioFile;
  jwtToken: string;
  title: string;
  apiRequest: () => Promise<{ [id: string]: number[] }>;
  closeCallback: () => void;
}) => {
  const [objectPath, setObjectPath] = useState("");
  const [graphData, setGraphData] = useState<
    { [id: string]: number[] } | undefined
  >(undefined);

  useEffect(() => {
    if (file.filename !== objectPath) {
      setObjectPath(file.filename);
      setGraphData(undefined);
      apiRequest().then((i) => setGraphData(i));
    }

    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
  }, [file.filename, objectPath, apiRequest, closeCallback]);

  return (
    <div
      style={{ width: `${window.innerWidth - 100}px` }}
      className={"Round-image-overlay"}
    >
      {graphData !== undefined && Object.keys(graphData).length === 0 && (
        <div style={{ color: "black" }}>NO DATA RETURNED</div>
      )}
      {graphData !== undefined ? (
        <Plot
          data={Object.keys(graphData)
            .filter((key) => key !== "x")
            .map((key, i) => ({
              name: key,
              x: graphData.x,
              y: graphData[key],
              type: "scatter",
              mode: "lines",
              marker: { color: COLORS[i] },
            }))}
          layout={{
            width: window.innerWidth - 200,
            title: title,
            paper_bgcolor: "rgba(0,0,0,0)",
            plot_bgcolor: "rgba(0,0,0,0)",
          }}
        />
      ) : (
        <div style={{ color: "black" }}>LOADING</div>
      )}
    </div>
  );
};

export default GraphOverlay;
