import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// @ts-ignore
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";

let uiConfig:any  = {
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
  //signInSuccessUrl: "/",
  // Need to use popup because of this:
  // https://github.com/firebase/firebase-js-sdk/issues/6716
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      disableSignUp: { status: true },
    },
    "google.com",
  ],
};

const LoginPage = () => (
  <div style={imageStyle} className="App">
    <div className={"App-header"}>
      <SmallLogoWithDashboard fontSizeRem={2} />
    </div>
    <div className={"App-content"}>
      <div className={"App-container"}>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    </div>
  </div>
);

const imageStyle = {
  backgroundImage: "objectPath(images/map.svg)",
  backgroundPositionY: "30%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
  height: "100vh",
};

export default LoginPage;
