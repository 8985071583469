import styled from "styled-components";

export const BottomOverlay = styled.div`
  position: fixed;
  left: 50%;
  bottom: 35rem;
  transform: translate(-50%, 0%);
  color: white;
  background: #666666;
  z-index: 1000;
  background: white;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 10%),
    0 4px 4px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 10%);
`;
