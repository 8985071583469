import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { RoundStatistics } from "../model/rounds";

const StatisticsGraphOverlay = ({
  statistics,
  closeCallback,
}: {
  statistics: RoundStatistics;
  closeCallback: () => void;
}) => {
  const [statisticsData, setStatisticsData] = useState<RoundStatistics>(
    statistics
  );
  useEffect(() => {
    if (
      statisticsData !== undefined &&
      statisticsData.shotStatistics.length !== statistics.shotStatistics.length
    ) {
      setStatisticsData(statistics);
    }
    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
  }, [statisticsData, statistics, closeCallback]);

  return (
    <div
      id={`graph_${statistics.shotStatistics.length}`}
      style={{ width: `${window.innerWidth - 100}px` }}
      className={"Round-image-overlay"}
    >
      {statisticsData !== undefined ? (
        <Plot
          data={[
            {
              name: "Corrected",
              x: statisticsData.shotStatistics.map((s, i) => i + 1),
              y: statisticsData.shotStatistics.map((i) =>
                (
                  ((i.corrected) / (i.corrected + i.ambiguous + i.notCorrected)) *
                  100
                ).toFixed(0)
              ),
              type: "scatter",
              mode: "lines",
              marker: { color: "green" },
            },
            {
              name: "Ambiguous",
              x: statisticsData.shotStatistics.map((s, i) => i + 1),
              y: statisticsData.shotStatistics.map((i) =>
                (
                  (i.ambiguous / (i.corrected + i.ambiguous + i.notCorrected)) *
                  100
                ).toFixed(0)
              ),
              type: "scatter",
              mode: "lines",
              marker: { color: "red" },
            },
          ]}
          layout={{
            width: window.innerWidth - 200,
            title: "Corrected statistics",
            xaxis: {title: "Shot number"},
            // yaxis: {title: "Percentage of devices"},
          }}
        />
      ) : (
        <div style={{ color: "black" }}>LOADING</div>
      )}
    </div>
  );
};

export default StatisticsGraphOverlay;
