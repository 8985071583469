import React, { useEffect, useState } from "react";
import { getDistinctAmmos } from "../services/roundsService";
import { GunshotObservation, Ammunition } from "../model/rounds";
import { CustomInputField } from "./CustomInputField";

const AmmoOverlay = ({
  jwtToken,
  observation,
  closeCallback,
}: {
  jwtToken: string;
  observation: GunshotObservation;
  closeCallback: () => void;
}) => {
  const [observationId, setObservationId] = useState(observation.shot.index);
  const [bc, setBc] = useState(defaultBc(observation));
  const [v0, setV0] = useState(defaultV0(observation));
  const [id, setId] = useState(defaultId(observation));
  const [url, setUrl] = useState(defaultUrl(observation));
  const [weight, setWeight] = useState(defaultWeight(observation));
  const [size, setSize] = useState(defaultSize(observation));
  const [unit, setUnit] = useState(defaultUnit(observation));
  const [blank, setBlank] = useState(defaultBlank(observation));
  const [description, setDescription] = useState(
    defaultDescription(observation)
  );
  const [ammoSuggestions, setAmmoSuggestions] = useState<Ammunition[]>([]);
  

  //@ts-ignore
  useEffect(() => {
    if (observation.shot.index !== observationId) {
      setObservationId(observation.shot.index);
      setBc(defaultBc(observation));
      setV0(defaultV0(observation));
      setId(defaultId(observation));
      setWeight(defaultWeight(observation));
      setBlank(defaultBlank(observation));
      setSize(defaultSize(observation));
      setUnit(defaultUnit(observation));
      setUrl(defaultUrl(observation));
      setDescription(defaultDescription(observation));
    }
    getDistinctAmmos(jwtToken, (ammo) => setAmmoSuggestions(ammo));
    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observation, observationId, closeCallback]);
  return (
    <div
      className={"Round-image-overlay"}
      style={{
        textAlign: "center",
        padding: "70px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", marginTop: "50px" }}>
        <div className={"Round-subheader"}>
          <div>
            <div className={"Round-label"}>Ammunition</div>
            <select
              onChange={(e) => {
                const choosenAmmo = ammoSuggestions.find(
                  (a) => a.id === e.target.value
                );
                if (choosenAmmo) {
                  setBc(`${choosenAmmo.bc}`);
                  setV0(`${choosenAmmo.v0}`);
                  setId(`${choosenAmmo.id}`);
                  setWeight(`${choosenAmmo.weight}`);
                  setBlank(choosenAmmo.blank!);
                  setSize(`${choosenAmmo.size}`);
                  setUnit(`${choosenAmmo.unit}`);
                  setDescription(`${choosenAmmo.description}`);
                  setUrl(`${choosenAmmo.url}`);
                }
              }}
            >
              {ammoSuggestions.map((ammo) => (
                <option value={ammo.id}>{ammo.description}</option>
              ))}
            </select>
          </div>
        </div>
        <div className={"Round-subheader"}></div>
      </div>
      <div style={{ display: "flex", marginTop: "50px" }}>
        <div className={"Round-subheader"}>
          <div>
            <div className={"Round-label"}>ID</div>
            <CustomInputField
              disabled={observation.shot.ammunition ? true : false}
              className={"Round-subheader Round-ammo-input"}
              style={{ width: "200px" }}
              value={id}
              onChange={(newValue) => {
                setId(newValue);
              }}
            />
          </div>
        </div>
        <div className={"Round-subheader"}>
          <div>
            <div className={"Round-label"}>Description</div>
            <CustomInputField
              className={"Round-subheader Round-ammo-input"}
              style={{ width: "200px" }}
              value={description}
              onChange={(newValue) => {
                setDescription(newValue);
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "50px" }}>
        <div className={"Round-subheader"}>
          <div>
            <div className={"Round-label"}>URL</div>
            <CustomInputField
              className={"Round-subheader Round-ammo-input"}
              style={{ width: "450px" }}
              value={url}
              onChange={(newValue) => {
                setUrl(newValue);
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ display: "flex", marginTop: "50px" }}>
        <div>
          <div className={"Round-label"}>Size</div>
          <CustomInputField
            className={"Round-ammo-input"}
            value={size}
            onChange={(newValue) => {
              setSize(newValue);
            }}
          />
        </div>
        <div>
          <div className={"Round-label"}>Unit</div>
          <select
            value={unit}
            onChange={(e) => {
              setUnit(e.target.value);
            }}
          >
            <option value={"inches"}>inches</option>
            <option value={"mm"}>mm</option>
          </select>
        </div>
        <div>
          <div className={"Round-label"}>Weight</div>
          <CustomInputField
            className={"Round-ammo-input"}
            value={weight}
            onChange={(newValue) => {
              setWeight(newValue);
            }}
          />
        </div>
        <div>
          <div className={"Round-label"}>BC</div>
          <CustomInputField
            className={"Round-ammo-input"}
            value={bc}
            onChange={(newValue) => {
              setBc(newValue);
            }}
          />
        </div>
        <div>
          <div className={"Round-label"}>V0</div>
          <CustomInputField
            className={"Round-ammo-input"}
            value={v0}
            onChange={(newValue) => {
              setV0(newValue);
            }}
          />
        </div>
        <div>
          <div className={"Round-label"}>Blank</div>
          <input
            type="checkbox"
            checked={blank}
            onChange={(e) => setBlank(!blank)}
          />
        </div>
      </div>
      <div className={"Round-button"}>
        Unavailable because of bug
      </div>
    </div>
  );
};

export default AmmoOverlay;
function defaultDescription(
  observation: GunshotObservation
): string | (() => string) {
  return observation.shot.ammunition
    ? observation.shot.ammunition.description
    : "Add description";
}

function defaultUnit(observation: GunshotObservation): string | (() => string) {
  return observation.shot.ammunition ? observation.shot.ammunition.unit : "mm";
}

function defaultBlank(observation: GunshotObservation): boolean {
  return observation.shot.ammunition && observation.shot.ammunition.blank
    ? observation.shot.ammunition.blank
    : false;
}

function defaultSize(observation: GunshotObservation): string {
  return observation.shot.ammunition
    ? `${observation.shot.ammunition.size}`
    : "0";
}

function defaultWeight(observation: GunshotObservation): string {
  return observation.shot.ammunition
    ? `${observation.shot.ammunition.weight}`
    : "0";
}

function defaultId(observation: GunshotObservation): string | (() => string) {
  return observation.shot.ammunition
    ? observation.shot.ammunition.id
    : `${observation.round.name}-${observation.shot.index}`;
}
function defaultUrl(observation: GunshotObservation): string | (() => string) {
  return observation.shot.ammunition && observation.shot.ammunition.url
    ? observation.shot.ammunition.url
    : "";
}

function defaultV0(observation: GunshotObservation): string {
  const ammo = observation.shot.ammunition;
  return ammo ? (ammo.v0 ? `${ammo.v0}` : `${ammo.v0_doc}`) : "0";
}

function defaultBc(observation: GunshotObservation): string {
  const ammo = observation.shot.ammunition;
  return ammo ? (ammo.bc ? `${ammo.bc}` : `${ammo.bc_doc}`) : "0";
}
