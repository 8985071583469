import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import { toggleNoiseReductionAction } from "./roundReducer";
import { bindActionCreators, Dispatch } from "redux";

const NoiseReduction = ({
  noiseReduction,
  toggleNoiseReductionAction
}: Props) => {
  return (
    <div
      className={noiseReduction ? "Incident-tag-filtered" : "Incident-tag"}
      onClick={() => toggleNoiseReductionAction()}
    >
      Noise red<u>u</u>ction
    </div>
  );
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  noiseReduction: boolean;
}

interface DispatchToProps {
  toggleNoiseReductionAction: () => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  noiseReduction: state.round.noiseReduction
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleNoiseReductionAction: toggleNoiseReductionAction
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(NoiseReduction);
