import axios, { AxiosRequestConfig } from "axios";
import {
  RoundGunshots,
  RoundNames,
  Ammunition,
  GunshotObservation,
  Target,
  RoundStatistics,
  RoundNameWithStatistics,
  RoundOverview,
  RoundOverviewShot,
  RoundOverviewGunshots,
} from "../model/rounds";
import { AudioFile } from "./adminService";

const getAxiosConfig = (jwtToken: string): AxiosRequestConfig => {
  return {
    headers: { Authorization: `Bearer ${jwtToken}` },
    timeout: 120000,
  };
};

export const getRoundInfo = (
  jwtToken: string,
  roundName: string,
  gunshotNumber: number,
  callback: (image: RoundGunshots) => void
) => {
  //TODO REMOVE
  // gunshotNumber === 1 ? callback(data) : callback(data2);
  axios
    .get(
      `https://api.triangula.no/round/${roundName}/gunshot/${gunshotNumber}`,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        const gunshots = response.data as RoundGunshots;
        const observationWithSelectedSample = gunshots.observations.map((o) => {
          return { ...o, selectedSample: o.manual };
        });
        callback({ observations: observationWithSelectedSample });
        // tslint:disable-next-line: no-empty
      } else {
      }
    })
    .catch(() => {
      //TODO REMOVE
    });
};

export interface ManualUpdateSample {
  sampleNumber: number;
  author: string;
  tut: number;
  unpersisted?: boolean;
  unadjustable?: boolean;
  lowAccuracy?: boolean;
}

export const updateManualAligned = (
  jwtToken: string,
  roundName: string,
  gunshotNumber: number,
  device: string,
  manualSampleNumber: number,
  estimatedSampleNumber: number,
  lowAccuracy: boolean,
  callback: (manualUpdated: ManualUpdateSample) => void,
  errorCallback: (msg: string) => void
) => {
  //TODO REMOVE
  // gunshotNumber === 1 ? callback(data) : callback(data2);
  axios
    .post(
      `https://api.triangula.no/round/${roundName}/${device}/${gunshotNumber}`.replace(
        "+",
        "%2B"
      ),
      {
        manualSampleNumber: manualSampleNumber,
        estimatedSampleNumber: estimatedSampleNumber,
        lowAccuracy,
      },
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback({
          ...response.data,
          unpersisted: false,
        } as ManualUpdateSample);
        // tslint:disable-next-line: no-empty
      } else {
        errorCallback(
          "Failed. Be aware that the timing may have been persisted"
        );
      }
    })
    .catch(() => {
      errorCallback("Failed. Be aware that the timing may have been persisted");
    });
};

export const updateManualSonicBoom = (
  jwtToken: string,
  roundName: string,
  gunshotNumber: number,
  device: string,
  manualSampleNumber: number,
  estimatedSampleNumber: number,
  lowAccuracy: boolean,
  callback: (manualUpdated: ManualUpdateSample) => void,
  errorCallback: (msg: string) => void
) => {
  //TODO REMOVE
  // gunshotNumber === 1 ? callback(data) : callback(data2);
  axios
    .post(
      `https://api.triangula.no/round/${roundName}/${device}/${gunshotNumber}/sonicBoom`.replace(
        "+",
        "%2B"
      ),
      {
        manualSampleNumber: manualSampleNumber,
        estimatedSampleNumber: estimatedSampleNumber,
      },
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback({
          ...response.data,
          unpersisted: false,
        } as ManualUpdateSample);
        // tslint:disable-next-line: no-empty
      } else {
        errorCallback(
          "Failed. Be aware that the timing may have been persisted"
        );
      }
    })
    .catch(() => {
      errorCallback("Failed. Be aware that the timing may have been persisted");
    });
};

export const updateAmmoDetails = (
  jwtToken: string,
  roundName: string,
  ammunition: Ammunition,
  callback: () => void,
  errorCallback: (msg: string) => void
) => {
  axios
    .post(
      `https://api.triangula.no/round/${roundName}/ammunitions/${ammunition.id}`.replace(
        "+",
        "%2B"
      ),
      ammunition,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback();
        // tslint:disable-next-line: no-empty
      } else {
        errorCallback("Failed. To update ammo details");
      }
    })
    .catch(() => {
      errorCallback("Failed. To update ammo details");
    });
};

export const updateTarget = (
  jwtToken: string,
  observation: GunshotObservation,
  target: Target,
  callback: () => void,
  errorCallback: (msg: string) => void
) => {
  axios
    .put(
      `https://api.triangula.no/round/${observation.round.name}/gunshot/${observation.shot.index}/target`.replace(
        "+",
        "%2B"
      ),
      target,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback();
        // tslint:disable-next-line: no-empty
      } else {
        errorCallback("Failed. To update ammo details");
      }
    })
    .catch(() => {
      errorCallback("Failed. To update ammo details");
    });
};

export const addAmmoDetails = (
  jwtToken: string,
  observation: GunshotObservation,
  ammunition: Ammunition,
  callback: () => void,
  errorCallback: (msg: string) => void
) => {
  axios
    .post(
      `https://api.triangula.no/round/${observation.round.name}/gunshot/${observation.shot.index}/ammunition`.replace(
        "+",
        "%2B"
      ),
      ammunition,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback();
        // tslint:disable-next-line: no-empty
      } else {
        errorCallback("Failed to add ammo details");
      }
    })
    .catch(() => {
      errorCallback("Failed to add ammo details");
    });
};

export const markAsUnclearPeak = (
  jwtToken: string,
  roundName: string,
  gunshotNumber: number,
  device: string,
  callback: () => void,
  errorCallback: (msg: string) => void
) => {
  //TODO REMOVE
  // gunshotNumber === 1 ? callback(data) : callback(data2);
  axios
    .post(
      `https://api.triangula.no/round/${roundName}/${device}/${gunshotNumber}/unadjustable`.replace(
        "+",
        "%2B"
      ),
      {},
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback();
        // tslint:disable-next-line: no-empty
      } else {
        errorCallback("Failed. Couldn't mark as unadjustable");
      }
    })
    .catch(() => {
      errorCallback("Failed. Couldn't mark as unadjustable");
    });
};

export const getRoundNames = (
  jwtToken: string,
  callback: (roundNames: RoundNames) => void
) => {
  axios
    .get(
      `https://api.triangula.no/round/names`,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data as RoundNames);
        // tslint:disable-next-line: no-empty
      } else {
      }
    })
    .catch(() => {});
};

export const shiftTut  = (
  jwtToken: string,
  observation: GunshotObservation,
  newTut: number
): Promise<void> => {
  return axios
    .put(
      `https://api.triangula.no/round/${observation.round.name}/${observation.device.name}/${observation.shot.index}/adjustAudioTut`,
      { "newAudioTut": newTut },
      { ...getAxiosConfig(jwtToken), responseType: "blob" }
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
        throw new Error("Couldn't change tut")
      }
    })
};

export const getRoundStatistics = (
  jwtToken: string,
  roundName: string,
  callback: (_: RoundStatistics) => void
) => {
  axios
    .get(
      `https://api.triangula.no/round/${roundName}/statistics`,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data as RoundStatistics);
        // tslint:disable-next-line: no-empty
      } else {
      }
    })
    .catch(() => {});
};

export const getStatisticsForRounds = (
  jwtToken: string,
  callback: (_: RoundNameWithStatistics[]) => void
) => {
  axios
    .get(
      `https://api.triangula.no/round/statistics`,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data.statistics as RoundNameWithStatistics[]);
        // tslint:disable-next-line: no-empty
      } else {
      }
    })
    .catch(() => {});
};

export const getMatrixForRound = (
  jwtToken: string,
  roundName: string
): Promise<RoundOverview> => {
  return axios
    .get(
      `https://api.triangula.no/round/${roundName}/matrix`,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        const sortedDevices = response.data.devices.sort(
          (a: any, b: any) => a.gunshots[0].distance - b.gunshots[0].distance
        );
        const devices = sortedDevices.map((d: any) => {
          const gunshots: RoundOverviewGunshots = {};
          d.gunshots.forEach(
            (g: RoundOverviewShot) => (gunshots[g.shotNumber] = g)
          );
          return { ...d, gunshots };
        });

        return { ...response.data, devices };

        // tslint:disable-next-line: no-empty
      } else {
        throw new Error("Couldn't fetch round");
      }
    });
};

export const getImage = (
  url: string,
  jwtToken: string,
  callback: (img: string) => void
) => {
  axios
    .get(url, { ...getAxiosConfig(jwtToken), responseType: "blob" })
    .then((response) => {
      if (response.status === 200) {
        let imgUrl = URL.createObjectURL(response.data);
        callback(imgUrl);
        // tslint:disable-next-line: no-empty
      } else {
      }
    })
    .catch(() => {});
};

export const getKrazyGale = (
  url: string,
  jwtToken: string,
  callback: (timings: number[]) => void
) => {
  axios
    .get(url, getAxiosConfig(jwtToken))
    .then((response) => {
      console.log("RESPONSE", response.status, response.data)
      if (response.status === 200) {
        callback(response.data.predictions);
        // tslint:disable-next-line: no-empty
      } else {
      }
    })
    .catch(() => {});
};

export const getClassActivationMap = (
  jwtToken: string,
  file: AudioFile,
  callback: (img: string) => void
) => {
  axios
    .post(
      "https://api.triangula.no/classifiers/gunshot/classActivationMap",
      { "audio-file": file },
      { ...getAxiosConfig(jwtToken), responseType: "blob" }
    )
    .then((response) => {
      if (response.status === 200) {
        let imgUrl = URL.createObjectURL(response.data);
        callback(imgUrl);
        // tslint:disable-next-line: no-empty
      } else {
      }
    })
    .catch(() => {});
};

export interface NetworkLayer {
  name: string;
  number: number;
  shape: number[];
}

export const getNetworkDescription = (
  jwtToken: string,
  callback: (layer: NetworkLayer[]) => void
) => {
  axios
    .get("https://api.triangula.no/classifiers/gunshot/analyze", {
      ...getAxiosConfig(jwtToken),
    })
    .then((response) => {
      if (response.status === 200) {
        callback(response.data as NetworkLayer[]);
        // tslint:disable-next-line: no-empty
      } else {
      }
    })
    .catch(() => {});
};

export const analyzeModel = (
  jwtToken: string,
  file: AudioFile,
  layer: number,
  callback: (img: string) => void,
  setFailed: () => void
) => {
  axios
    .post(
      "https://api.triangula.no/classifiers/gunshot/analyze",
      { "audio-file": file, layer },
      { ...getAxiosConfig(jwtToken), responseType: "blob" }
    )
    .then((response) => {
      if (response.status === 200) {
        let imgUrl = URL.createObjectURL(response.data);
        callback(imgUrl);
        // tslint:disable-next-line: no-empty
      } else {
        setFailed();
      }
    })
    .catch(() => {
      setFailed();
    });
};

export const getDistinctAmmos = (
  jwtToken: string,
  callback: (ammunitions: Ammunition[]) => void
) => {
  axios
    .get(
      `https://api.triangula.no/round/ammunition`,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data.ammunition as Ammunition[]);
        // tslint:disable-next-line: no-empty
      } else {
      }
    })
    .catch(() => {});
};
