import React from "react";
import "./Player.css";
import Player from "./Player";
import { Incident } from "../model/incident";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  createAudioFileRequest,
  listenForPotentialIncidents,
} from "../firebase/potentialIncidents";
import { UserData } from "../model/user";
import { createUrl } from "../audio/audioUtil";
import MarkAsNotGunshotOverlay from "../incident/MarkAsNotGunshotOverlay";
import PeakCategoryOverlay from "../round/PeakCategoryOverlay";
import { store } from "..";
import {
  addClassificationsAction,
  incidentForOverlayAction,
} from "./incidentReducer";
import { AudioFile, getClassifications } from "../services/adminService";

const decideIfFilter = (incident: Incident, filter: string | undefined) => {
  return (
    filter === undefined ||
    filter === `organisation_${incident.organisationId}` ||
    filter === `device_${incident.deviceId}`
  );
};

const getAndSetClassifications = (incident: Incident) => {
  const request: AudioFile = createAudioFileRequest(incident);
  getClassifications(
    request,
    store.getState().login.jwtToken!,
    (classifications) => {
      store.dispatch(addClassificationsAction(incident.id, classifications));
    }
  );
};

const IncidentItem = ({
  incident,
  jwtToken,
}: {
  incident: Incident;
  jwtToken: string;
}) => {
  return (
    <div className="Widget-item">
      <div className={"Incident-action-button-group"}>
        <p
          style={{margin: 0, marginBottom: "-1rem"}}
          className={"Dashboard-clickable-text"}
          onClick={() => {
            // Need to wait until audiofiles are available in python if new event
            getAndSetClassifications(incident);
          }}
        >
          LOAD CLASSIFICATIONS
        </p>
        </div>
        <Player
          incident={incident}
          jwtToken={jwtToken}
          audioFilenameForPeakCategory={incident.objectPath}
          url={createUrl(incident.objectPath, "triangula-scout-audio")}
        />
    </div>
  );
};

const IncidentPage = ({
  jwtToken,
  incidents,
  filter,
  userData,
  incidentForOverlay,
}: Props) => {
  if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 200);
  }

  listenForPotentialIncidents(userData);
  if (jwtToken) {
    return (
      <div className={"Incident-page"}>
        <div style={{ position: "relative" }}>
          <MarkAsNotGunshotOverlay />
          {incidentForOverlay !== undefined && (
            <PeakCategoryOverlay
              file={{
                "bucket-name": "triangula-scout-audio",
                filename: incidentForOverlay.objectPath,
              }}
              jwtToken={jwtToken!}
              closeCallback={() => {
                store.dispatch(incidentForOverlayAction(undefined));
              }}
            />
          )}
        </div>
        {incidents
          .filter((i) => decideIfFilter(i, filter))
          .slice(
            incidents.length - ((incidents.length % 30) + 10),
            incidents.length
          )
          .map((d) => (
            <IncidentItem incident={d} jwtToken={jwtToken} />
          ))}
      </div>
    );
  } else {
    return <div />;
  }
};

interface OwnProps {}

interface StateToProps {
  filter: string | undefined;
  incidents: Incident[];
  jwtToken: string | undefined;
  userData: UserData | undefined;
  incidentForOverlay: Incident | undefined;
}

interface DispatchFromProps {}

interface Props extends StateToProps, DispatchFromProps, OwnProps {}

const mapStateToProps = (state: ApplicationState) => ({
  filter: state.incident.filter,
  incidents: state.incident.incidents.sort((a, b) => a.tut - b.tut),
  jwtToken: state.login.jwtToken,
  userData: state.login.userData,
  incidentForOverlay: state.incident.incidentForOverlay,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

const ConnectedIncidentPage = connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(IncidentPage);

export default () => (
  <div>
    <MarkAsNotGunshotOverlay />
    <ConnectedIncidentPage />
  </div>
);
