import React, { useEffect, useRef, useState } from "react";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import "./Dropdown.scss"

interface Props<T> {
  items: T[];
  onSelect: (_: T) => void;
  selectedItem: T | undefined;
  icon?: JSX.Element;
  itemDescription: (_: T) => string;
}


function Dropdown<T>({
  items,
  onSelect,
  selectedItem,
  itemDescription,
  icon
}: Props<T>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event:any) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div ref={ref} className={"relativeContainer"}>
      <div className={["selectedDrop", "styleTwo"].join(" ")} onClick={() => setOpen(!open)}>
        {icon !== undefined && icon}
        <p>{selectedItem ? itemDescription(selectedItem) : "Not selected"}</p>
        <ArrowDownIcon
          width={24}
          height={24}
          backgroundColor={"hsl(207, 12%, 43%)"}
        />
      </div>
      {open && (
        <div className={"items"}>
          {items.map((item, i) => (
            <div
              key={`range_${i}`}
              className={"item"}
              style={{
                fontWeight: selectedItem === item ? "bold" : "normal",
              }}
              onClick={() => {
                onSelect(item);
                setOpen(false);
              }}
            >
              {itemDescription(item)}
            </div>
          ))}
          
        </div>
      )}
    </div>
  );
}

export default Dropdown;
