import React from "react";
import styled from "styled-components";
import { FlexStartRow, SpaceBetweenRow } from "../../layout/Row";
import { Description } from "../../layout/Text";
import { AmbiguousBox, CorrectedBox, HighlightBox, LowAccuracyBox, NotCorrectedBox } from "./MatrixBoxes";


const DescriptionItem = styled(SpaceBetweenRow)`
 p {
    margin-left: 1rem;
    margin-right: 0.25rem;
}
`

const BoxDetails = ({ description, children }: { description: string, children: JSX.Element[] | JSX.Element }) => {
    return (
        <DescriptionItem>
            <Description>{description}</Description>
            {children}
        </DescriptionItem>
    )
}

const Content = styled.div`
margin-bottom: 3rem;
`

const MatrixDoc = () => {
    return (
        <Content>

            <FlexStartRow>
                <BoxDetails description={"Corrected"}>
                    <CorrectedBox />
                </BoxDetails>
                <BoxDetails description={"Corrected but sample > 48000"}>
                    <CorrectedBox>!</CorrectedBox>
                </BoxDetails>
                <BoxDetails description={"Low accuracy"}>
                    <LowAccuracyBox />
                </BoxDetails>
                <BoxDetails description={"Ambiguous"}>
                    <AmbiguousBox />
                </BoxDetails>
                <BoxDetails description={"Last modified"}>
                    <HighlightBox />
                </BoxDetails>
                <BoxDetails description={"Not corrected"}>
                    <NotCorrectedBox />
                </BoxDetails>
            </FlexStartRow>
        </Content>
    )
}

export default MatrixDoc;