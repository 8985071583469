import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";
import { ApplicationState } from "../reducers";
import { getAppVersion } from "../services/adminService";
import "./Player.css";
import { FlexStartRow, FlexStartRowTop } from "../layout/Row";

export interface AppVersion {
  appVersion: String;
  count: number;
  platform: String;
}

const VersionOverview = ({ title, versions }: { title: string; versions: AppVersion[] | undefined }) => {
  return (
    <>
      {versions !== undefined && versions.length > 0 && (
        <div className="Widget-item" style={{ width: "20rem" }}>
          <h3 className={"Widget-title"}>{title}</h3>
          <div>
            <div className={"Organisation-key-value"}>
              <p style={{ width: "7rem" }}>Version</p>
              <p>Count</p>
            </div>
            {versions.map((v) => (
              <div className={"Organisation-key-value"}>
                <p style={{ width: "7rem" }}>{v.appVersion}</p>
                <p>{v.count}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const ScoutVersionPage = (props: Props) => {
  const [appleVersions, setAppleVersions] = useState<AppVersion[] | undefined>(undefined);
  const [androidVersions, setAndroidVersions] = useState<AppVersion[] | undefined>(undefined);
  const [organisationId, setOrganisationId] = useState<string | undefined>(undefined);
  useEffect(() => {
    //@ts-ignore
    const urlParams = new URLSearchParams(props.location.search);
    if (!organisationId) {
      setOrganisationId(urlParams.get("organisation-id") || undefined);
    }
    if (appleVersions === undefined && props.jwtToken !== undefined) {
      getAppVersion(props.jwtToken, organisationId, (d) => {
        setAppleVersions(d.filter((v) => v.platform === "Apple"));
        setAndroidVersions(d.filter((v) => v.platform === "Android"));
      });
    }
    // eslint-disable-next-line
  }, [props.jwtToken]);

  return (
    <div className={"Dashboard"}>
      <div className={"Dashboard-header"}>
        <SmallLogoWithDashboard fontSizeRem={1.625} />
      </div>
      <div className={"Incident-page"} style={{ width: "1hv" }}>
        <FlexStartRowTop>
          <VersionOverview title={"Android"} versions={androidVersions} />
          <VersionOverview title={"Apple"} versions={appleVersions} />
        </FlexStartRowTop>
      </div>
    </div>
  );
};

interface OwnProps {}

interface StateToProps {
  jwtToken: string | undefined;
}

interface DispatchFromProps {}

interface Props extends StateToProps, DispatchFromProps, OwnProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(ScoutVersionPage);
