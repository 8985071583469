

export const TUT_BIRTHDAY_MILLIS = 1500854400000;

export const tutToDate = (tut: number) =>
  new Date(tut / 1000 + TUT_BIRTHDAY_MILLIS);

  export const dateToTut = (date: Date) => {
    return (date.getTime() - TUT_BIRTHDAY_MILLIS) * 1000;
  };
