import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { setUserAction, setUserDataAction } from "../loginReducer";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";
import LogoutWidget from "../logout/LogoutWidget";
import { UserData } from "../model/user";
import { deleteClockAnalyticsMeasurements, listenForClockAnalytics, queryForClockAnalytics, queryScouts } from "../organisation/firestore/scouts";
import ClockSyncChart from "../plot/ClockSyncChart";
import { ApplicationState } from "../reducers";
import NotificationOverlay from "../status/NotificationOverlay";
import { CenteredRow, FlexSpaceBetweenRow } from "../layout/Row";
import { ColumnCentered } from "../layout/Column";
import BorderButton from "../button/BorderButton";
import { Scout, ScoutStatus } from "../organisation/model/scout";
import ButtonStyle from "../button/ButtonStyle";

export enum ClockAnalyticsGraphType {
  NETWORK_DIFF = 0,
  RATE_OF_CHANGE = 1,
}

const ClockAnalyticsPage = (props: Props) => {
  //@ts-ignore
  const deviceId: string | undefined = props.match.params.deviceId;
  //@ts-ignore
  const organisationId: string | undefined = props.match.params.organisationId;
  const [graphType, setGraphType] = useState<ClockAnalyticsGraphType>(ClockAnalyticsGraphType.NETWORK_DIFF);
  const [timeRange, setTimeRange] = useState<ClockTimeRange>(ClockTimeRange.twelve_hours);
  const [masterClock, setMasterClock] = useState<string>("NETWORK");
  const [intervalInMin, setIntervalInMin] = useState<number>(0);
  const [availableClocks, setAvailableClocks] = useState<string[]>([]);
  const [excludedClocks, setExcludedClocks] = useState<string[]>([]);
  return (
    <div className="Dashboard">
      <div className={"Dashboard-header"}>
        <SmallLogoWithDashboard fontSizeRem={1.625} />
        <LogoutWidget {...props} />
      </div>
      <NotificationOverlay />
      <CenteredRow style={{ paddingBottom: "2.5rem" }}>
        <h1>Clock analytics</h1>
      </CenteredRow>
      <GraphTypeSelection setGraphType={setGraphType} graphType={graphType} />
      <IntervalSelection setIntervalInMin={setIntervalInMin} intervalInMin={intervalInMin} />
      <TimeRangeSelection setTimeRange={setTimeRange} timeRange={timeRange} />
      {availableClocks.length === 0 ? (
        <></>
      ) : (
        <>
          <ClockSelection
          availableClocks={availableClocks}
          excludedClocks={excludedClocks}
          setExcludedClocks={setExcludedClocks}
          />
          <MasterClockSelection
          availableClocks={availableClocks}
          masterClock={masterClock}
          setMasterClock={setMasterClock}
        />
          </>
      )}
      <main className={"Graph-content"} style={{ maxWidth: "100vw", paddingLeft: "100" }}>
        {deviceId !== undefined ? (
          <DeviceClockAnalyticsPage
            id={deviceId}
            graphType={graphType}
            timeRange={timeRange}
            intervalInMinutes={intervalInMin}
            setAvailableClocks={setAvailableClocks}
            excludedClocks={excludedClocks}
            masterClock={masterClock}
            {...props}
          />
        ) : organisationId !== undefined ? (
          <OrganisationClockAnalyticsPage
            id={organisationId}
            graphType={graphType}
            timeRange={timeRange}
            intervalInMinutes={intervalInMin}
            setAvailableClocks={setAvailableClocks}
            excludedClocks={excludedClocks}
            masterClock={masterClock}
            {...props}
          />
        ) : (
          <div>Missing device or organisation id</div>
        )}
      </main>
    </div>
  );
};

interface ClockAnalyticsProps extends Props {
  id: string;
  graphType: ClockAnalyticsGraphType;
  intervalInMinutes: number;
  setAvailableClocks: (_: string[]) => void;
  excludedClocks: string[];
  timeRange: ClockTimeRange;
  scout?: ScoutStatus;
  masterClock: string;
}

const OrganisationClockAnalyticsPage = (props: ClockAnalyticsProps) => {
  const organisationId: string = props.id;
  const [scouts, setScouts] = useState<Scout[]>([]);

  useEffect(() => {
    if (props.jwtToken) {
      queryScouts(organisationId, (s) => {
        s.sort((a, b) => a.status.displayName.localeCompare(b.status.displayName));
        setScouts(s);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.jwtToken]);
  return (
    <>
      {scouts.map((scout) => (
        <DeviceClockAnalyticsPage {...props} id={scout.deviceId} scout={scout.status} />
      ))}
    </>
  );
};

const DeviceClockAnalyticsPage = (props: ClockAnalyticsProps) => {
  const deviceId: string = props.id;
  const [measurements, setMeasurements] = useState<{ [type: string]: number }[]>([]);
  const [firstMeasurementsLoaded, setFirstMeasurementsLoaded] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(true);

  useEffect(() => {
    if (props.jwtToken) {
      console.log(`Query measurements for ${deviceId}`);
      queryForClockAnalytics(deviceId, props.timeRange, (newMeasurement) => {
        console.log(`Received ${newMeasurement.length} for ${deviceId}`);
        setMeasurements([...newMeasurement]);
        if (newMeasurement.length > 0) {
          props.setAvailableClocks(Object.keys(newMeasurement[0]));
        }
      });

      if (!firstMeasurementsLoaded) {
        console.log("Subscribing to clock analytics");
        setFirstMeasurementsLoaded(true);
        setTimeout(() => {
          listenForClockAnalytics(deviceId, (m) => {
            setMeasurements((measurements) => [...measurements, m]);
          });
        }, 1000);
      }

      return () => {};
    } else {
      return () => {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.jwtToken, props.timeRange]);
  if (measurements.length === 0) {
    return <div></div>;
  }
  return (
    <>
      <ColumnCentered>
        <h2>Device {props.scout?.displayName ?? deviceId} • {props.scout?.deviceInfo.model}</h2>
        {finished && <BorderButton 
        onClick={() => {
          setFinished(false);
          deleteClockAnalyticsMeasurements(deviceId, () => {setFinished(true); setMeasurements([]);})
          }}
          text={"Delete measurements"}
          selected={false}/>}
        <ClockSyncChart
          graphData={measurements}
          graphType={props.graphType}
          intervalInMinutes={props.intervalInMinutes}
          height={300}
          width={1000}
          excludeClocks={props.excludedClocks}
          masterClock={props.masterClock}
        />
      </ColumnCentered>
    </>
  );
};

interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
  user: state.login.user,
  userData: state.login.userData,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
      setUserData: setUserDataAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(ClockAnalyticsPage);

export enum ClockTimeRange {
  // noinspection JSUnusedGlobalSymbols
  all = 24*30,
  three_hours = 3,
  twelve_hours = 12,
  twentyfour_hours = 24,
}
function TimeRangeSelection({ setTimeRange, timeRange }: { setTimeRange; timeRange: ClockTimeRange }) {
  return (
    <CenteredRow style={{ paddingBottom: "2.5rem" }}>
      <BorderButton
        key={0}
        text="All measurements"
        onClick={() => {
          setTimeRange(ClockTimeRange.all);
        }}
        selected={timeRange === ClockTimeRange.all}
      />
      <BorderButton
        key={1}
        text="3 hours"
        onClick={() => {
          setTimeRange(ClockTimeRange.three_hours);
        }}
        selected={timeRange === ClockTimeRange.three_hours}
      />
      <BorderButton
        key={2}
        text="12 hours"
        onClick={() => {
          setTimeRange(ClockTimeRange.twelve_hours);
        }}
        selected={timeRange === ClockTimeRange.twelve_hours}
      />
      <BorderButton
        key={3}
        text="24 hours"
        onClick={() => {
          setTimeRange(ClockTimeRange.twentyfour_hours);
        }}
        selected={timeRange === ClockTimeRange.twentyfour_hours}
      />
    </CenteredRow>
  );
}

function IntervalSelection({ setIntervalInMin, intervalInMin }: { setIntervalInMin; intervalInMin: number }) {
  return (
    <CenteredRow style={{ paddingBottom: "2.5rem" }}>
      <BorderButton
        key={0}
        text="All measurements"
        onClick={() => {
          setIntervalInMin(0);
        }}
        selected={intervalInMin === 0}
      />
      <BorderButton
        key={1}
        text="5 min interval"
        onClick={() => {
          setIntervalInMin(5);
        }}
        selected={intervalInMin === 5}
      />
      <BorderButton
        key={15}
        text="15 min interval"
        onClick={() => {
          setIntervalInMin(15);
        }}
        selected={intervalInMin === 15}
      />
    </CenteredRow>
  );
}

function ClockSelection({
  availableClocks,
  excludedClocks,
  setExcludedClocks,
}: {
  availableClocks: string[];
  excludedClocks: string[];
  setExcludedClocks: (_: string[]) => void;
}) {
  availableClocks.sort();
  return (
    <ColumnCentered>
    <h3>Exclude clocks</h3>
    <CenteredRow style={{ paddingBottom: "2.5rem" }}>
      {availableClocks
        .filter((v) => v !== "hostTime")
        .map((clock) => (
          <BorderButton
          key={clock}
          text={clock}
          onClick={() => {
            setExcludedClocks(
              excludedClocks.includes(clock) ? excludedClocks.filter((v) => v !== clock) : [...excludedClocks, clock]
              );
            }}
            selected={!excludedClocks.includes(clock)}
            />
            ))}
    </CenteredRow>
            </ColumnCentered>
  );
}

function MasterClockSelection({
  availableClocks,
  masterClock,
  setMasterClock
}: {
  availableClocks: string[];
  masterClock: string;
  setMasterClock: (_: string) => void;
}) {
  availableClocks.sort();
  return (<ColumnCentered>
    <h3>Master clock</h3>
    <CenteredRow style={{ paddingBottom: "2.5rem" }}>
      {availableClocks
        .filter((v) => v !== "hostTime")
        .map((clock) => (
          <BorderButton
            key={clock}
            text={clock}
            onClick={() => {
              setMasterClock(clock);
            }}
            selected={masterClock === clock}
          />
        ))}
    </CenteredRow>
    </ColumnCentered>
  );
}

function GraphTypeSelection({ setGraphType, graphType }: { setGraphType; graphType: ClockAnalyticsGraphType }) {
  return (
    <CenteredRow style={{ paddingBottom: "2.5rem" }}>
      <BorderButton
        key={ClockAnalyticsGraphType.NETWORK_DIFF}
        text="Diff from network"
        onClick={() => {
          setGraphType(ClockAnalyticsGraphType.NETWORK_DIFF);
        }}
        selected={graphType === ClockAnalyticsGraphType.NETWORK_DIFF}
      />
      <BorderButton
        key={ClockAnalyticsGraphType.RATE_OF_CHANGE}
        text="Rate of change ms/s"
        onClick={() => {
          setGraphType(ClockAnalyticsGraphType.RATE_OF_CHANGE);
        }}
        selected={graphType === ClockAnalyticsGraphType.RATE_OF_CHANGE}
      />
    </CenteredRow>
  );
}
