import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import { CalculatedTiming } from "../model/timing";
import along from "@turf/along";
//@ts-ignore
import distance from "@turf/distance";
import { MapViewType } from "./MapViewType";

export interface MapboxProps {
  calculatedTiming?: CalculatedTiming;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  mapType?: MapViewType;
}

export interface Props extends OwnProps, MapboxProps {}

function addTimingLayer(map: Map) {
  map.addLayer({
    id: "bullet",
    paint: {
      "line-color": "red",
      "line-width": 5
      // 'line-gradient' must be specified using an expression
      // with the special 'line-progress' property
    },
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    source: "bullet",
    type: "line"
  });
}

function addTimingSource(map: Map, calculatedTiming?: CalculatedTiming) {
  map.addSource("bullet", {
    lineMetrics: true,
    data: timingLines(calculatedTiming),
    type: "geojson"
  });
}

export const timingLines = (
  calculatedTiming?: CalculatedTiming
): GeoJSON.FeatureCollection => {
  return {
    features: createFeatures(calculatedTiming),
    type: "FeatureCollection"
  };
};

const createFeatures = (timing?: CalculatedTiming): any => {
  const features = [];

  if (timing) {
    const gunshot2impactDistance = distance(
      [timing._location.gunshot.lon, timing._location.gunshot.lat],
      [timing._location.impact.lon, timing._location.impact.lat],
      { units: "meters" }
    );
    const isSubsonicInFlight =
      timing.bullet.sub_sonic &&
      gunshot2impactDistance > timing.bullet.sub_sonic!.x &&
      timing.bullet.sub_sonic!.x > 0;
    const toCoordinates = isSubsonicInFlight
      ? findSubSonicCoordinates(timing)
      : [timing._location.impact.lon, timing._location.impact.lat];

      // @ts-ignore
    features.push({
      geometry: {
        coordinates: [
          [timing._location.gunshot.lon, timing._location.gunshot.lat],
          toCoordinates
        ],
        type: "LineString"
      },
      id: `gunshot2impact`,
      properties: {
        id: `gunshot2impact`
      },
      type: "Feature"
    });
  }
  return features;
};

function findSubSonicCoordinates(timing: CalculatedTiming) {
  const point = along(
    {
      coordinates: [
        [timing._location.gunshot.lon, timing._location.gunshot.lat],
        [timing._location.impact.lon, timing._location.impact.lat]
      ],
      type: "LineString"
    },
    timing.bullet.sub_sonic!.x,
    { units: "meters" }
  );

  return point.geometry.coordinates;
}

function updateTimingSource(map: Map, calculatedTiming?: CalculatedTiming) {
  map
    .getSource("bullet")
    // @ts-ignore
    .setData(timingLines(calculatedTiming));
}

const BulletLayer = ({ map, loaded, calculatedTiming }: Props) => {
  if (map && loaded) {
    if (!map.getSource("bullet")) {
      addTimingSource(map, calculatedTiming);
      addTimingLayer(map);
    } else {
      updateTimingSource(map, calculatedTiming);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedRound = state.round.selectedRound;
  const observation =
    selectedRound &&
    selectedRound.observations.find(
      o => o.device.name === state.round.selectedDevice
    );

  return {
    calculatedTiming: observation ? observation.calculatedTiming : undefined
  };
};

// @ts-ignore
export default connect<MapboxProps, {}, {}>(mapStateToProps)(BulletLayer);
