import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { OutlierData, GunshotObservation } from "../model/rounds";
import "./Outlier.css";
import { updateUrl } from "../round/urlUtil";
import { getRoundInfo } from "../services/roundsService";
import { store } from "..";
import { map } from "../map/AlignmentMap";
import { selectRoundAction } from "../round/roundReducer";

export const COLORS = [
  "red",
  "blue",
  "green",
  "pink",
  "orange",
  "purple",
  "grey",
  "black",
];

const OutlierOverlay = ({
  round,
  apiRequest,
  closeCallback,
}: {
  round: GunshotObservation;
  jwtToken: string;
  apiRequest: () => Promise<OutlierData>;
  closeCallback: () => void;
}) => {
  const [currentDevice, setCurrentDevice] = useState("");
  const [graphData, setGraphData] = useState<OutlierData | undefined>(
    undefined
  );

  const [error, setError] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (round.device.name !== currentDevice) {
      setCurrentDevice(round.device.name);
      setGraphData(undefined);
      setError(undefined)
      apiRequest().then((i) => setGraphData(i), (err:Error) => setError(err.message));
    }

    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
  }, [round, currentDevice, apiRequest, closeCallback]);
  return (
    <div
      style={{     maxHeight: "50%",
        overflowY: "scroll", marginLeft: "-200px", width: `${window.innerWidth - 500}px` }}
      className={"Round-image-overlay"}
    >
      {error !== undefined && (
        <div style={{ color: "black" }}>{error}</div>
      )}
      {(!graphData && !error) && <div style={{ color: "black" }}>LOADING</div>}
      {graphData !== undefined && (
        <Plot
          data={[
            {
              name: "R",
              x: graphData.r.map((_, i) => i + 1),
              y: graphData.r,
              type: "scatter",
              mode: "lines",
              marker: { color: COLORS[0] },
            },
            {
              name: "Max",
              x: graphData.r.map((_, i) => i + 1),
              y: graphData.r.map((_) => graphData.median + graphData.sd),
              type: "scatter",
              mode: "lines",
              marker: { color: COLORS[1], opacity: 0.5 },
            },
            {
              name: "Min",
              x: graphData.r.map((_, i) => i + 1),
              y: graphData.r.map((_) => graphData.median - graphData.sd),
              type: "scatter",
              mode: "lines",
              marker: { color: COLORS[2], opacity: 0.5 },
            },
            {
              name: "Median",
              x: graphData.r.map((_, i) => i + 1),
              y: graphData.r.map((_) => graphData.median),
              type: "scatter",
              mode: "lines",
              marker: { color: COLORS[4], opacity: 0.5 },
            },
          ]}
          layout={{ width: window.innerWidth - 500, title: `Outliers for  ${round.device.name}` }}
        />
      )}
      {graphData && (
        <div>
          <h3 className={"Outlier-title"}>Outliers</h3>
          <div className={"Outlier-items"}>
            {graphData.outliers.map((o) => (
              <div
                onClick={() => {
                  getRoundInfo(
                    store.getState().login.jwtToken!,
                    round.round.name,
                    o,
                    (round) => {
                      map.setCenter({
                        lat: round.observations[0].device.position.master.lat,
                        lng: round.observations[0].device.position.master.lon,
                      });
                      store.dispatch(selectRoundAction(round));
                    }
                  );
                  updateUrl(round.device.name, o);
                }}
                className={["Outlier-item"].join(" ")}
              >
                {o}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default OutlierOverlay;
