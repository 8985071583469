import React from "react";
import "./App.css";
import firebase from "firebase/compat/app";
// These imports load individual services into the firebase namespace.
import "firebase/compat/auth";
import 'firebase/compat/firestore';
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "./reducers";
import { UserData } from "./model/user";
import { setUserAction, setUserDataAction } from "./loginReducer";
import { listenForUserInfo } from "./firebase/user";
import LoginPage from "./login/LoginPage";
import RouterComponent from "./router/Router";
import { createJwtToken } from "./jwtUtil";
import { listenForConfiguration, Configuration } from "./firebase/configuration";
import { updateConfigurationAction } from "./player/systemReducer";

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      loadingUserData: true,
      unregisterAuthObserver: undefined
    };
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  public componentWillUnmount() {
    if (this.state.unregisterAuthObserver) {
      this.state.unregisterAuthObserver();
    }
  }

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    if (process.env.TOKEN) {
      firebase.auth().signInWithCustomToken(process.env.TOKEN);
    }
    this.setState({
      unregisterAuthObserver: firebase.auth().onAuthStateChanged(user => {
        if (user != null) {
          this.props.setUser(user);
          
          createJwtToken(user);
          listenForUserInfo(user, this.props.setUserData, () =>
            this.setState(prevState => ({
              ...prevState,
              loadingUserData: false
            }))
          );
          listenForConfiguration(this.props.updateConfiguration);
        }
        this.setState(prevState => ({ ...prevState, loading: false }));
      })
    });
  }

  render() {
    const { user } = this.props;
    if (!user) {
      return <LoginPage />;
    } else {
      return <RouterComponent />;
    }
  }
}

interface State {
  loading: boolean;
  loadingUserData: boolean;
  unregisterAuthObserver: firebase.Unsubscribe | undefined;
}

interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
  updateConfiguration: (_: Configuration) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
  user: state.login.user,
  userData: state.login.userData
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
      setUserData: setUserDataAction,
      updateConfiguration: updateConfigurationAction
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(App);
