import * as React from "react";
import { useRef, useState, useEffect, CSSProperties } from "react";
import styled from "styled-components";
import { store } from "..";
import { inputFieldInFocusAction } from "../round/roundReducer";

interface InputProps {
  id?: string;
  placeholder?: string;
  className?: string;
  maxLength?: number;
  value: string | undefined;
  onConfirm?: (_: string) => void;
  onBlur?: (_: string) => void;
  cssProps?: CSSProperties;
}

export const StyledInput = styled.input`
  padding: 0.25rem;
  border-radius: 5px;
  border: $colors-primary solid 2px;
  width: 8rem;
`;

export const BigStyledInput = styled.input`
  padding: 0.5rem;
  border-radius: 5px;
  border: $colors-primary solid 2px;
  font-size: 1rem;
  margin: 1rem 0;
`;

const Input = ({
  id,
  placeholder,
  value,
  onConfirm,
  maxLength,
  onBlur,
  cssProps,
}: InputProps) => {
  const [currentValue, setCurrentValue] = useState("");
  const [currentId, setCurrentId] = useState("");
  useEffect(() => {
    if (currentId === id) {
      return;
    }
    setCurrentId(id ? id : "");
    setCurrentValue(value ? value : "");
  }, [currentId, id, value]);
  const inputEl = useRef<HTMLInputElement | null>(null);
  return (
    <StyledInput
      id={id}
      key={id}
      ref={inputEl}
      style={cssProps}
      placeholder={placeholder}
      maxLength={maxLength}
      value={currentValue}
      onFocus={() => {
        setTimeout(() => store.dispatch(inputFieldInFocusAction(true)), 200);
      }}
      onBlur={() => {
        store.dispatch(inputFieldInFocusAction(false));
        if (onBlur) {
          onBlur(currentValue);
        }
      }}
      onChange={(t) => setCurrentValue(t.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter" && inputEl.current !== null) {
          if (onConfirm) {
            onConfirm(currentValue);
          }
          inputEl.current.blur();
        }
      }}
    />
  );
};

export default Input;
