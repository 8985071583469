import React from "react";
import Plot from "react-plotly.js";

export const COLORS = [
  "red",
  "blue",
  "green",
  "orange",
  "purple",
  "pink",
  "grey",
  "black",
];

const PlotGraph = ({
  title,
  graphData,
  width,
}: {
  jwtToken: string;
  title: string;
  width: number;
  graphData: { [id: string]: number[] };
}) => {
  return (
    <Plot
      data={Object.keys(graphData)
        .filter((key) => key !== "x")
        .map((key, i) => ({
          name: key,
          x: graphData.x,
          y: graphData[key],
          type: "scatter",
          mode: "lines",
          marker: { color: COLORS[i] },
        }))}
      layout={{
        width: width,
        title: title,
        paper_bgcolor: "rgba(0,0,0,0.2)",
        plot_bgcolor: "rgba(0,0,0,0.2)",
      }}
    />
  );
};

export default PlotGraph;
