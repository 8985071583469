import axios, { AxiosRequestConfig } from "axios";

const getAxiosConfig = (jwtToken: string): AxiosRequestConfig => {
  return {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
};

export const getFastTrackDevices = (
  jwtToken: string,
  callback: (scouts: string[]) => void,
  onError?: (errorMsg: string) => void
) => {
  axios
    .get(
      `https://api.triangula.no/backoffice/fast-track/scouts`,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data.scouts);
      } else {
        if (onError) {
          onError("Failed to fetch overview fast tack");
        }
      }
    })
    .catch(() => {
      if (onError) {
        onError("Failed to fetch overview fast tack");
      }
    });
};

export const addToFastTrack = (
  jwtToken: string,
  deviceId: string,
  callback: (scouts: string[]) => void,
  onError?: (errorMsg: string) => void
) => {
  axios
    .put(
      `https://api.triangula.no/backoffice/fast-track/scouts/${deviceId}`,
      "",
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data.scouts);
      } else {
        if (onError) {
          onError("Failed to fetch overview fast tack");
        }
      }
    })
    .catch(() => {
      if (onError) {
        onError("Failed to fetch overview fast tack");
      }
    });
};

export const removeFromFastTrack = (
  jwtToken: string,
  deviceId: string,
  callback: (scouts: string[]) => void,
  onError?: (errorMsg: string) => void
) => {
  axios
    .delete(
      `https://api.triangula.no/backoffice/fast-track/scouts/${deviceId}`,
      getAxiosConfig(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        callback(response.data.scouts);
      } else {
        if (onError) {
          onError("Failed to fetch overview fast tack");
        }
      }
    })
    .catch(() => {
      if (onError) {
        onError("Failed to fetch overview fast tack");
      }
    });
};
