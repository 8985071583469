import { Action, Reducer } from "redux";
import {
  RoundGunshots,
  Ammunition,
  Target,
  RoundStatistics,
} from "../model/rounds";
import { ManualUpdateSample } from "../services/roundsService";
import { CalculatedTiming } from "../model/timing";
import { WeaponDetails } from "../services/adminService";

const SELECT_ROUND = "SELECT_ROUND";
const SELECT_DEVICE = "SELECT_DEVICE";
const SELECT_ROUND_NAME = "SELECT_ROUND_NAME";
const MANUAL_UPDATE_SAMPLE = "MANUAL_UPDATE_SAMPLE";
const SELECTED_SAMPLE = "SELECTED_SAMPLE";
const INPUT_FIELD_IN_FOCUS = "INPUT_FIELD_IN_FOCUS";
const UPDATE_AMMUNTION = "UPDATE_AMMUNITION";
const UPDATE_TARGET = "UPDATE_TARGET";
const ADD_TIMING = "ADD_TIMING";
const ADD_DIRECTION_PREDICTION = "ADD_DIRECTION_PREDICTION";
const SUGGEST_TARGET = "SUGGEST_TARGET";
const NEXT_SHOT_ON_SAVE = "NEXT_SHOT_ON_SAVE";
const ESTIMATED_TO_MANUAL_DIFF = "ESTIMATED_TO_MANUAL_DIFF";
const SET_DECIBEL = "SET_DECIBEL";
const TOGGLE_NOISE_REDUCTION = "TOGGLE_NOISE_REDUCTION";
const SHOW_TIMING_PREDICTIONS = "SHOW_TIMING_PREDICTIONS";
const TOGGLE_SONIC_BOOM_MODE = "TOGGLE_SONIC_BOOM_MODE";
const FILTER_DEVICE = "FILTER_DEVICE";
const ROUND_STATISTICS = "ROUND_STATISTICS";
const ADD_OR_REMOVE_GUNSHOT_DIRECTION_SCOUT =
  "ADD_OR_REMOVE_GUNSHOT_DIRECTION_SCOUT";

  const ADD_WEAPON_DETAILS = "ADD_WEAPON_DETAILS";

export interface SelectRoundAction extends Action {
  type: "SELECT_ROUND";
  selected: RoundGunshots | undefined;
}

export const selectRoundAction = (
  selected: RoundGunshots | undefined
): SelectRoundAction => {
  return { type: SELECT_ROUND, selected };
};

export interface FilterDeviceAction extends Action {
  type: "FILTER_DEVICE";
  filter: DeviceFilter;
}

export const filterDeviceAction = (
  filter: DeviceFilter
): FilterDeviceAction => {
  return { type: FILTER_DEVICE, filter };
};

export interface ManualUpdateSampleAction extends Action {
  type: "MANUAL_UPDATE_SAMPLE";
  update: ManualUpdateSample;
}

export const manualUpdateSampleAction = (
  update: ManualUpdateSample
): ManualUpdateSampleAction => {
  return { type: MANUAL_UPDATE_SAMPLE, update };
};

export interface SelectedSampleAction extends Action {
  type: "SELECTED_SAMPLE";
  update: ManualUpdateSample;
}

export const selectedSampleAction = (
  update: ManualUpdateSample
): SelectedSampleAction => {
  return { type: SELECTED_SAMPLE, update };
};

export interface SelectDeviceAction extends Action {
  type: "SELECT_DEVICE";
  selected: string | undefined;
}

export const selectDeviceAction = (
  selected: string | undefined
): SelectDeviceAction => {
  return { type: SELECT_DEVICE, selected };
};

export interface SelectRoundNameAction extends Action {
  type: "SELECT_ROUND_NAME";
  selected: string;
}

export const selectRoundNameAction = (
  selected: string
): SelectRoundNameAction => {
  return { type: SELECT_ROUND_NAME, selected };
};

export interface InputFieldInFocus extends Action {
  type: "INPUT_FIELD_IN_FOCUS";
  inFocus: boolean;
}

export const inputFieldInFocusAction = (
  inFocus: boolean
): InputFieldInFocus => {
  return { type: INPUT_FIELD_IN_FOCUS, inFocus };
};

export interface UpdateAmmunition extends Action {
  type: "UPDATE_AMMUNITION";
  ammunition: Ammunition;
}

export const updateAmmunitionAction = (
  ammunition: Ammunition
): UpdateAmmunition => {
  return { type: UPDATE_AMMUNTION, ammunition };
};

export const updateTargetAction = (target: Target): UpdateTarget => {
  return { type: UPDATE_TARGET, target };
};

export interface UpdateTarget extends Action {
  type: "UPDATE_TARGET";
  target: Target;
}

export interface AddTimingAction extends Action {
  type: "ADD_TIMING";
  calculatedTiming: CalculatedTiming;
}

export const addTimingAction = (
  calculatedTiming: CalculatedTiming
): AddTimingAction => {
  return { type: ADD_TIMING, calculatedTiming };
};

export interface AddDirectionPredictionAction extends Action {
  type: "ADD_DIRECTION_PREDICTION";
  predictions: number[];
  scoutsUsed: string[];
}

export const addDirectionPredictionAction = (
  predictions: number[],
  scoutsUsed: string[]
): AddDirectionPredictionAction => {
  return { type: ADD_DIRECTION_PREDICTION, predictions, scoutsUsed };
};

export interface SuggestedTargetAction extends Action {
  type: "SUGGEST_TARGET";
  suggestedTarget: Target | undefined;
}

export const suggestTargetAction = (
  suggestedTarget: Target | undefined
): SuggestedTargetAction => {
  return { type: SUGGEST_TARGET, suggestedTarget };
};

export interface NextShotOnSaveAction extends Action {
  type: "NEXT_SHOT_ON_SAVE";
  nextShotOnSave: boolean;
}

export const nextShotOnSaveAction = (
  nextShotOnSave: boolean
): NextShotOnSaveAction => {
  return { type: NEXT_SHOT_ON_SAVE, nextShotOnSave };
};

export interface EstimatedToManualDiffAction extends Action {
  type: "ESTIMATED_TO_MANUAL_DIFF";
  estimatedToManualDiff: number;
}

export const estimatedToManualDiffAction = (
  estimatedToManualDiff: number
): EstimatedToManualDiffAction => {
  return { type: ESTIMATED_TO_MANUAL_DIFF, estimatedToManualDiff };
};

export interface SetDecibelAction extends Action {
  type: "SET_DECIBEL";
  decibel: string;
}

export interface ToggleNoiseReductionAction extends Action {
  type: "TOGGLE_NOISE_REDUCTION";
}

export const toggleNoiseReductionAction = (): ToggleNoiseReductionAction => {
  return { type: TOGGLE_NOISE_REDUCTION };
};

export interface ShowTimingPredictionsAction extends Action {
  type: "SHOW_TIMING_PREDICTIONS";
  show: boolean;
}

export const showTimingPredictionsAction = (
  show: boolean
): ShowTimingPredictionsAction => {
  return { type: SHOW_TIMING_PREDICTIONS, show: show };
};

export const setDecibelAction = (decibel: string): SetDecibelAction => {
  return { type: SET_DECIBEL, decibel };
};

export interface ToggleSonicBoomModeAction extends Action {
  type: "TOGGLE_SONIC_BOOM_MODE";
}

export const toggleSonicBoomModeAction = (): ToggleSonicBoomModeAction => {
  return { type: TOGGLE_SONIC_BOOM_MODE };
};

export interface RoundStatisticsAction extends Action {
  type: "ROUND_STATISTICS";
  roundStatistics: RoundStatistics;
}

export const roundStatisticsAction = (
  roundStatistics: RoundStatistics
): RoundStatisticsAction => {
  return { type: ROUND_STATISTICS, roundStatistics };
};

export interface AddOrRemoveGunshotDirectionScoutAction extends Action {
  type: "ADD_OR_REMOVE_GUNSHOT_DIRECTION_SCOUT";
  deviceId: String;
}

export const addOrRemoveGunshotDirectionScoutAction = (
  deviceId: String
): AddOrRemoveGunshotDirectionScoutAction => {
  return { type: ADD_OR_REMOVE_GUNSHOT_DIRECTION_SCOUT, deviceId };
};

export interface WeaponDetailsAction extends Action {
  type: "ADD_WEAPON_DETAILS";
  weaponDetails: WeaponDetails | undefined;
}

export const addWeaponDetailsAction = (
  weaponDetails: WeaponDetails | undefined
): WeaponDetailsAction => {
  return { type: ADD_WEAPON_DETAILS, weaponDetails };
};

export enum DeviceFilter {
  ALL = 0,
  NOT_CORRECTED = 1,
  AMBIGUOUS = 2,
}

export interface DirectionPrediction {
  predictions: number[];
  scoutsUsed: string[];
}

export interface RoundReducerState {
  selectedRound: RoundGunshots | undefined;
  selectedDevice: string | undefined;
  selectedRoundName: string | undefined;
  inputFieldInFocus: boolean;
  suggestedTarget: Target | undefined;
  nextShotOnSave: boolean;
  estimatedToManualDiff: number;
  decibel: string;
  noiseReduction: boolean;
  sonicBoomMode: boolean;
  deviceFilter: DeviceFilter;
  roundStatistics?: RoundStatistics;
  showTimingPredictions: boolean;
  gunshotDirectionScouts: string[];
  directionPrediction: DirectionPrediction;
  weaponDetails: WeaponDetails | undefined;
}

const initialState: RoundReducerState = {
  selectedRound: undefined,
  selectedDevice: undefined,
  selectedRoundName: undefined,
  inputFieldInFocus: false,
  suggestedTarget: undefined,
  nextShotOnSave: false,
  estimatedToManualDiff: 0,
  decibel: "",
  noiseReduction: false,
  sonicBoomMode: false,
  deviceFilter: DeviceFilter.ALL,
  roundStatistics: undefined,
  showTimingPredictions: false,
  gunshotDirectionScouts: [],
  directionPrediction: { predictions: [], scoutsUsed: [] },
  weaponDetails: undefined,
};

const reducer: Reducer<RoundReducerState> = (
  state: RoundReducerState = initialState,
  action
) => {
  switch ((action as Action).type) {
    case SELECT_ROUND: {
      return {
        ...state,
        selectedRound: action.selected,
      };
    }
    case ROUND_STATISTICS: {
      return {
        ...state,
        roundStatistics: action.roundStatistics,
      };
    }

    case SHOW_TIMING_PREDICTIONS: {
      return {
        ...state,
        showTimingPredictions: action.show,
      };
    }
    case TOGGLE_SONIC_BOOM_MODE: {
      return {
        ...state,
        sonicBoomMode: !state.sonicBoomMode,
      };
    }
    case SELECT_DEVICE: {
      return {
        ...state,
        selectedDevice:
          state.selectedDevice === action.selected
            ? undefined
            : action.selected,
      };
    }
    case FILTER_DEVICE: {
      return {
        ...state,
        deviceFilter: action.filter,
      };
    }
    case ESTIMATED_TO_MANUAL_DIFF: {
      return {
        ...state,
        estimatedToManualDiff: action.estimatedToManualDiff,
      };
    }
    case SELECT_ROUND_NAME: {
      return {
        ...state,
        selectedRoundName: action.selected,
      };
    }
    case NEXT_SHOT_ON_SAVE: {
      return {
        ...state,
        nextShotOnSave: action.nextShotOnSave,
      };
    }
    case UPDATE_AMMUNTION: {
      return {
        ...state,
        selectedRound: state.selectedRound
          ? {
              observations: state.selectedRound.observations.map((o) => ({
                ...o,
                shot: {
                  ...o.shot,
                  ammunition: action.ammunition,
                },
              })),
            }
          : state.selectedRound,
      };
    }
    case SET_DECIBEL: {
      return {
        ...state,
        decibel: action.decibel,
      };
    }
    case ADD_OR_REMOVE_GUNSHOT_DIRECTION_SCOUT: {
      return {
        ...state,
        gunshotDirectionScouts:
          state.gunshotDirectionScouts.findIndex(
            (s) => s === action.deviceId
          ) === -1
            ? [...state.gunshotDirectionScouts, action.deviceId]
            : state.gunshotDirectionScouts.filter((s) => s !== action.deviceId),
      };
    }

    case ADD_WEAPON_DETAILS: {
      return {
        ...state,
        weaponDetails: action.weaponDetails
      };
    }
    case TOGGLE_NOISE_REDUCTION: {
      return {
        ...state,
        noiseReduction: !state.noiseReduction,
      };
    }
    case UPDATE_TARGET: {
      return {
        ...state,
        selectedRound: state.selectedRound
          ? {
              observations: state.selectedRound.observations.map((o) => ({
                ...o,
                target: action.target,
              })),
            }
          : state.selectedRound,
      };
    }
    case MANUAL_UPDATE_SAMPLE: {
      return {
        ...state,
        selectedRound: {
          observations: state.selectedRound!.observations.map((o) =>
            o.device.name === state.selectedDevice
              ? {
                  ...o,
                  manual: {
                    sample_number: action.update.sampleNumber,
                    author: action.update.author,
                    tut: action.update.tut,
                    unpersisted: action.update.unpersisted,
                    unadjustable: action.update.unadjustable,
                    low_accuracy: action.update.lowAccuracy,
                  },
                }
              : o
          ),
        },
      };
    }
    case SELECTED_SAMPLE: {
      return {
        ...state,
        selectedRound: {
          observations: state.selectedRound!.observations.map((o) =>
            o.device.name === state.selectedDevice
              ? {
                  ...o,
                  selectedSample: {
                    sample_number: action.update.sampleNumber,
                    author: action.update.author,
                    tut: action.update.tut,
                    unpersisted: action.update.unpersisted,
                    unadjustable: action.update.unadjustable,
                    low_accuracy: action.update.lowAccuracy,
                  },
                }
              : o
          ),
        },
      };
    }
    case ADD_TIMING: {
      return {
        ...state,
        selectedRound: {
          observations: state.selectedRound!.observations.map((o) =>
            o.device.name === state.selectedDevice
              ? {
                  ...o,
                  calculatedTiming: action.calculatedTiming,
                }
              : o
          ),
        },
      };
    }
    case ADD_DIRECTION_PREDICTION: {
      return {
        ...state,
        directionPrediction: {
          predictions: action.predictions,
          scoutsUsed: action.scoutsUsed,
        },
      };
    }
    case INPUT_FIELD_IN_FOCUS: {
      return {
        ...state,
        inputFieldInFocus: action.inFocus,
      };
    }
    case SUGGEST_TARGET: {
      return {
        ...state,
        suggestedTarget: action.suggestedTarget,
      };
    }
    default:
      return state;
  }
};

export default reducer;
