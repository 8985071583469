import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import { toggleSonicBoomModeAction } from "./roundReducer";
import { bindActionCreators, Dispatch } from "redux";

const SonicBoomMode = ({ sonicBoomMode, toggleSonicBoom }: Props) => {
  return (
    <div
      className={sonicBoomMode ? "Incident-tag-filtered" : "Incident-tag"}
      onClick={() => toggleSonicBoom()}
    >
      SonicBoomMode
    </div>
  );
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  sonicBoomMode: boolean;
}

interface DispatchToProps {
  toggleSonicBoom: () => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  sonicBoomMode: state.round.sonicBoomMode
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      toggleSonicBoom: toggleSonicBoomModeAction
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(SonicBoomMode);
