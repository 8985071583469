import React from "react"
import Dropdown from "../../dropdown/Dropdown"
import { RoundNameWithStatistics } from "../../model/rounds"



interface Props {
    selected: RoundNameWithStatistics | undefined;
    onSelect: (_: RoundNameWithStatistics) => void
    rounds: RoundNameWithStatistics[]
}


const RoundDropdown = ({ selected, onSelect, rounds }: Props) => {
    console.log("ROUND", selected)
    return (
        <div>
            <Dropdown<RoundNameWithStatistics>
                items={rounds}
                onSelect={onSelect}
                selectedItem={selected}
                itemDescription={(s) => `${s.roundName} • Corrected ${s.corrected}/${s.ambiguous + s.corrected + s.notCorrected}`}
            />
        </div>
    )
}

export default RoundDropdown