import * as React from "react";
import styled from "styled-components";
import WarningIcon from "../icons/WarningIcon";
import { PairKey } from "../layout/Pair";
import { VerCenteredRow } from "../layout/Row";
const CustomRow = styled(VerCenteredRow)`
margin: 0;
margin-left: 2rem;
`
const FastTrackWarning = () => {
    return (
      <CustomRow>
        <WarningIcon width={20} height={20} backgroundColor={"#f59e0b"} />
        <PairKey style={{ marginLeft: "0.5rem" }}>
          Device registered for fast track
        </PairKey>
      </CustomRow>
    );
  };

  export default FastTrackWarning;