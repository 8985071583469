import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { store } from "..";
import { Scout } from "../organisation/model/scout";
import { addPopupAction, setFastTrackDevicesAction } from "../organisation/organisationReducer";
import { getFastTrackDevices } from "../services/fastTrackService";
import { Status } from "../status/status";


export const listenForGlobal = (jwtToken: string) => {
    return firebase
      .firestore()
      .collection("env")
      .doc("prod")
      .collection("app")
      .doc("global")
      .onSnapshot((snapshot) => {
        getFastTrackDevices(jwtToken, (scouts) => {


          const oldFastTrack = store.getState().organisation.fastTrack;
          store.dispatch(setFastTrackDevicesAction(scouts));
          const scoutsInFastTrack = Object.values(store
          .getState()
          .organisation.scouts).filter(
            (s: Scout) => scouts.indexOf(s.status.deviceId) > -1
            );
          store.dispatch(
            addPopupAction({
              id: "fast_track_devices",
              message: scoutsInFastTrack.length
                ? `${scoutsInFastTrack
                    .map((s: Scout) => s.status.displayName!)
                    .join(", ")} used in fast track`
                : "No devices in fast track",
              component: "Fast track",
              status: scoutsInFastTrack.length ? Status.Warning : Status.Ok,
              useInPopup: scoutsInFastTrack.length || oldFastTrack.length ? true  : false,
            })
          );

        });
      });
};
