import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { UserData } from "../model/user";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { setUserAction, setUserDataAction } from "../loginReducer";
import { connect } from "react-redux";
import { RoundNameWithStatistics } from "../model/rounds";
import { getStatisticsForRounds } from "../services/roundsService";
import { Route } from "react-router-dom";
import {
  selectDeviceAction,
  selectRoundAction,
  selectRoundNameAction,
} from "../round/roundReducer";
import { store } from "../index";
import { cssColorFromBasedOnPercentage } from "../player/Player";
import LoadingAnimation from "../animation/LoadingAnimation";
import { SpaceBetweenRow } from "../layout/Row";

const RoundTotalStats = ({ rounds }: { rounds: RoundNameWithStatistics[] }) => {
  const corrected = rounds.reduce((acc, v) => {
    return acc + v.corrected + v.ambiguous;
  }, 0);
  const ambiguous = rounds.reduce((acc, v) => {
    return acc + v.ambiguous;
  }, 0);
  const sampleSet = rounds.reduce((acc, v) => {
    return acc + v.corrected;
  }, 0);
  const lowAccuracy = rounds.reduce((acc, v) => {
    return acc + v.lowAccuracy;
  }, 0);
  const total = rounds.reduce((acc, v) => {
    return acc + v.corrected + v.ambiguous + v.notCorrected;
  }, 0);

  const correctedPercentage = (corrected / total) * 100;

  const sampleSetPercentage = (sampleSet / total) * 100;
  const ambiguousPercentage = (ambiguous / total) * 100;
  const lowAccuracyPercentage = (lowAccuracy / total) * 100;

  const color = cssColorFromBasedOnPercentage(correctedPercentage);
  return (
    <div
      style={{ backgroundColor: "white", marginTop: "0px" }}
      className={"Dashboard-analyzer-round-content"}
    >
      <div>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginTop: "0px" }}>
              <p
                style={{
                  marginTop: "-3px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: color,
                  margin: 0,
                }}
              >
                {correctedPercentage.toFixed(0)}%
              </p>
              <p
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: color,
                  margin: 0,
                }}
              >
                Corrected
              </p>
            </div>
            <div style={{ marginLeft: "20px", marginTop: "0px" }}>
              <p className={"Dashboard-analyzer-round-small-desc"}>
                {sampleSetPercentage.toFixed(0)}% ({sampleSet}) sample set
              </p>
              <p className={"Dashboard-analyzer-round-small-desc"}>
                {ambiguousPercentage.toFixed(0)}% ({ambiguous}) ambiguous
              </p>
              <p className={"Dashboard-analyzer-round-small-desc"}>
                {lowAccuracyPercentage.toFixed(0)}% ({lowAccuracy}) low accuracy
              </p>
            </div>
            <div style={{ marginLeft: "20px", marginTop: "0px" }}>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "grey",
                  margin: 0,
                }}
              >
                {corrected} / {total}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RoundWidget = ({
  userData,
  user,
  jwtToken,
  selectRoundName,
  selectedRoundName,
}: Props) => {
  const [rounds, setRounds] = useState<RoundNameWithStatistics[]>([]);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  useEffect(() => {
    if (jwtToken && !rounds.length && !loading) {
      setLoading(true);
      getStatisticsForRounds(jwtToken, (r: RoundNameWithStatistics[]) => {
        setLoading(false);

        setRounds(r);
        selectRoundName(r[0].roundName);
        store.dispatch(selectRoundAction(undefined));
        store.dispatch(selectDeviceAction(undefined));
      });
    }
  });
  return (
    <div style={{ maxWidth: "1000px" }} className={"Widget-item"}>
      {/*https://triangula-admin.firebaseapp.com*/}
      {user && jwtToken && userData ? (
        <>
          {user && (
            <SpaceBetweenRow>

              <h3
                style={{ marginBottom: "0", paddingLeft: "1rem" }}
                className={"Widget-title"}
              >
                Round analyzer
              </h3>

              <a
                href={"/#/round/overview"}
                className={"Dashboard-clickable-text Dashboard-no-decoration"}
              >
                ROUND OVERVIEW
              </a>
            </SpaceBetweenRow>
          )}
          <div>
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "2rem",
                }}
              >
                <LoadingAnimation height={30} width={30} />
              </div>
            )}
            {!loading && rounds.length && <RoundTotalStats rounds={rounds} />}
            {!loading && rounds.length && (
              <div className={"Dashboard-analyzer-row"}>
                {rounds.map(
                  ({
                    ambiguous,
                    corrected,
                    notCorrected,
                    roundName,
                    lowAccuracy,
                  }) => {
                    const totalObservations =
                      corrected + ambiguous + notCorrected;
                    const correctedObservations = corrected + ambiguous;
                    const correctedPercentage =
                      (correctedObservations / totalObservations) * 100;
                    const sampleSetPercentage =
                      (corrected / totalObservations) * 100;
                    const lowAccuracyPercentage =
                      (lowAccuracy / totalObservations) * 100;
                    const ambiguousPercentage =
                      (ambiguous / totalObservations) * 100;
                    const color = cssColorFromBasedOnPercentage(
                      correctedPercentage
                    );
                    return (
                      <Route
                        render={({ history }) => (
                          <div
                            onClick={() => {
                              history.push(`/round?round-name=${roundName}`);
                            }}
                            className={"Dashboard-analyzer-round-content"}
                          >
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <p
                                    style={{
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      color: color,
                                      margin: 0,
                                    }}
                                  >
                                    {correctedPercentage.toFixed(0)}%
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "bold",
                                      color: color,
                                      margin: 0,
                                    }}
                                  >
                                    Corrected
                                  </p>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "bold",
                                      color: "grey",
                                      margin: 0,
                                    }}
                                  >
                                    {correctedObservations} /{" "}
                                    {totalObservations}
                                  </p>
                                </div>
                              </div>
                              <div style={{ marginTop: "3px" }}>
                                <p
                                  className={
                                    "Dashboard-analyzer-round-small-desc"
                                  }
                                >
                                  {sampleSetPercentage.toFixed(0)}% ({corrected}
                                  ) sample set
                                </p>
                                <p
                                  className={
                                    "Dashboard-analyzer-round-small-desc"
                                  }
                                >
                                  {ambiguousPercentage.toFixed(0)}% ({ambiguous}
                                  ) ambiguous
                                </p>
                                <p
                                  className={
                                    "Dashboard-analyzer-round-small-desc"
                                  }
                                >
                                  {lowAccuracyPercentage.toFixed(0)}% (
                                  {lowAccuracy}) low accuracy
                                </p>
                              </div>
                            </div>
                            <p className={"Dashboard-analyzer-round-title"}>
                              {roundName}
                            </p>
                          </div>
                        )}
                      />
                    );
                  }
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};


interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
  selectedRoundName: string | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
  selectRoundName: (_: string) => void;
}

interface Props extends StateToProps, DispatchFromProps { }

const mapStateToProps = (state: ApplicationState): StateToProps => ({
  jwtToken: state.login.jwtToken,
  user: state.login.user,
  userData: state.login.userData,
  selectedRoundName: state.round.selectedRoundName,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
      setUserData: setUserDataAction,
      selectRoundName: selectRoundNameAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(RoundWidget);
