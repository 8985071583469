import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import { CalculatedTiming } from "../model/timing";
import midpoint from "@turf/midpoint";
import { MapViewType } from "./MapViewType";

export interface MapboxProps {
  timing?: CalculatedTiming;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  mapType?: MapViewType;
}

export interface Props extends OwnProps, MapboxProps {}

function addTimingLayer(map: Map) {
  map.addLayer({
    id: "timings",
    interactive: false,
    layout: {
      "icon-allow-overlap": true,
      "text-field": ["get", "distance"],
      "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Regular"],
      "text-allow-overlap": true,

      "text-size": 14
    },
    paint: {
      "text-color": ["get", "color"]
    },
    source: "timings",
    type: "symbol"
  });
}

function addTimingSource(map: Map, timing?: CalculatedTiming) {
  map.addSource("timings", {
    data: timingLines(timing),
    type: "geojson"
  });
}

export const timingLines = (
  timing?: CalculatedTiming
): GeoJSON.FeatureCollection => {
  return {
    features: createFeatures(timing),
    type: "FeatureCollection"
  };
};

const createFeatures = (timing?: CalculatedTiming): any => {
  const features = [];
  if (timing) {
    const midG2I = midpoint(
      [timing._location.gunshot.lon, timing._location.gunshot.lat],
      [timing._location.impact.lon, timing._location.impact.lat]
    );
    const timingG2I = timing.bullet.impact * 1000;
    const isSubSonic =
      timing.bullet.sub_sonic && timing.bullet.sub_sonic.Tx < timingG2I / 1000;
      // @ts-ignore
    features.push({
      geometry: midG2I.geometry,
      id: "g2i",
      properties: {
        distance: `Bullet[gun to impact: ${timingG2I.toFixed(2)}ms] ${
          isSubSonic
            ? `subsonic at ${(timing.bullet.sub_sonic!.Tx * 1000).toFixed()}ms`
            : ""
        }`,
        id: `g2i`,
        color: `white`
      },
      type: "Feature"
    });

    const midG2O = midpoint(
      [timing._location.gunshot.lon, timing._location.gunshot.lat],
      [timing._location.device.lon, timing._location.device.lat]
    );
    const timingG2O = timing.observation.gunshot * 1000;
    // @ts-ignore
    features.push({
      geometry: midG2O.geometry,
      id: "g2o",
      properties: {
        distance: `
        Sound[Gun to device: ${timingG2O.toFixed(2)}ms]`,
        id: `g2o`,
        color: `white`
      },
      type: "Feature"
    });

    const midI2O = midpoint(
      [timing._location.impact.lon, timing._location.impact.lat],
      [timing._location.device.lon, timing._location.device.lat]
    );
    const timingI2O = timing.observation.impact * 1000;
    // @ts-ignore
    features.push({
      geometry: midI2O.geometry,
      id: "i2o",
      properties: {
        distance: `
        Sound[Impact to device: ${timingI2O.toFixed(2)}ms]`,
        id: `i2o`,
        color: `white`
      },
      type: "Feature"
    });

    const { _location, observation, bullet } = timing;

    if (_location.intersection && bullet.sonic_boom && observation.sonicBoom) {
      const midG2intersection = midpoint(
        [_location.gunshot.lon, _location.gunshot.lat],
        [_location.intersection.lon, _location.intersection.lat]
      );
      const timingG2sonicBoom = bullet.sonic_boom * 1000;
      // @ts-ignore
      features.push({
        geometry: midG2intersection.geometry,
        id: "g2sonic",
        properties: {
          distance: `Bullet[gun to intersection: ${timingG2sonicBoom.toFixed(
            2
          )}ms] | Bullet[bearing: ${bullet.bearing.toFixed(0)}°]`,
          id: `g2sonic`,
          color: `white`
        },
        type: "Feature"
      });

      const midS2O = midpoint(
        [_location.intersection.lon, _location.intersection.lat],
        [_location.device.lon, _location.device.lat]
      );
      const timingS2O = observation.sonicBoom! * 1000;
      // @ts-ignore
      features.push({
        geometry: midS2O.geometry,
        id: "S2o",
        properties: {
          distance: `
        Sound[SonicBoom to device: ${timingS2O.toFixed(2)}ms]`,
          id: `S2o`,
          color: `white`
        },
        type: "Feature"
      });
    }
  }
  return features;
};

function updateTimingSource(map: Map, timing?: CalculatedTiming) {
  map
    .getSource("timings")
    // @ts-ignore
    .setData(timingLines(timing));
}

const TimingLayer = ({ map, loaded, timing }: Props) => {
  if (map && loaded) {
    if (!map.getSource("timings")) {
      addTimingSource(map, timing);
      addTimingLayer(map);
    } else {
      updateTimingSource(map, timing);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedRound = state.round.selectedRound;
  const observation =
    selectedRound &&
    selectedRound.observations.find(
      o => o.device.name === state.round.selectedDevice
    );

  return {
    timing: observation ? observation.calculatedTiming : undefined
  };
};

// @ts-ignore
export default connect<MapboxProps, {}, {}>(mapStateToProps)(TimingLayer);
