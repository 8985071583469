import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { BasePosition } from "../model/rounds";
import { ApplicationState } from "../reducers";
import { MapViewType } from "./MapViewType";

export interface MapboxProps {
  positions: BasePosition[];
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  mapType?: MapViewType;
}

export interface Props extends OwnProps, MapboxProps {}

function addLocalLocationLayer(map: Map) {
  // @ts-ignore
  map.loadImage("images/target.png", (error3, image) => {
    if (!map.hasImage("target_img")) {
      map.addImage("target_img", image);
    }
    if (!map.getLayer("target")) {
      map.addLayer({
        id: "target",
        layout: {
          "icon-allow-overlap": true,
          "icon-anchor": "center",
          "icon-image": "target_img",
          "text-allow-overlap": true,
          "text-anchor": "center",
        },
        source: "target",
        type: "symbol",
      });
    }
  });
}

function addLocalLocationSource(localLocation: BasePosition[], map: Map) {
  map.addSource("target", {
    data: devicePoints(localLocation),
    type: "geojson",
  });
}

export const devicePoints = (
  positions: BasePosition[]
): GeoJSON.FeatureCollection => {
  return {
    features: createFeatures(positions),
    type: "FeatureCollection",
  };
};

const createFeatures = (positions: BasePosition[]): any => {
  return positions.map((position) => {
    return {
      geometry: {
        coordinates: [position.lon, position.lat],
        type: "Point",
      },
      properties: {
        id: `${position.lon}_${position.lat}`,
      },
      type: "Feature",
    };
  });
};

function updateLocalLocationSource(positions: BasePosition[], map: Map) {
  map
    .getSource("target")
    // @ts-ignore
    .setData(devicePoints(positions));
}

const TargetLayer = ({ map, loaded, positions }: Props) => {
  if (map && loaded) {
    if (!map.getSource("target")) {
      addLocalLocationSource(positions, map);
      addLocalLocationLayer(map);
    } else {
      updateLocalLocationSource(positions, map);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedRound = state.round.selectedRound;

  return {
    positions:
      selectedRound && selectedRound.observations[0].target
        ? [selectedRound.observations[0].target.position]
        : [],
  };
};

// @ts-ignore
export default connect<MapboxProps, {}, {}>(mapStateToProps)(TargetLayer);
