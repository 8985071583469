import styled from "styled-components";
import { COLORS_GREY_40, COLORS_PRIMARY } from "../style/variables";

export const PairValue = styled.p`
  color: ${COLORS_GREY_40};
  font-size: 0.75rem;
  font-weight: bold;
  cursor: pointer;
`

export const PairKey = styled.p`
  color: ${COLORS_PRIMARY};
    font-size: 0.75rem;
    font-weight: bold;
    margin: 0;
`
