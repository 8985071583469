import React, { useEffect, useState } from "react";
import { getRoundInfo, shiftTut } from "../services/roundsService";
import styled from "styled-components";
import { SpaceBetweenRow } from "../layout/Row";
import BorderButton from "../button/BorderButton";
import { H2 } from "../layout/Headers";
import { CenterDescription, CenterError } from "../layout/Text";
import { BottomOverlay } from "../layout/Overlay";
import { GunshotObservation, RoundGunshots } from "../model/rounds";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { selectRoundAction } from "./roundReducer";

const Overlay = styled(BottomOverlay)`
  width: 15rem;
`;

const ShiftAudioOverlay = ({
  gunshotObservation,
  jwtToken,
  closeCallback,
  selectRound,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  useEffect(() => {
    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, []);

  const updateAudioTut = (newTut: number) => {
    if (!loading) {
      setLoading(true);
      setError(undefined);
      shiftTut(jwtToken, gunshotObservation, newTut)
        .then(() => {
          getRoundInfo(jwtToken, gunshotObservation.round.name, gunshotObservation.shot.index, round => {
            selectRound(round);
            setLoading(false);
          });
        })
        .catch((e) => {
          setLoading(false);
          setError(e.toString());
        });
    }
  };

  return (
    <Overlay>
      <H2 className={"Round-subheader"}>Shift tut</H2>
      <CenterDescription>
        Shift audio tut backward or forward in time
      </CenterDescription>
      {error && <CenterError>{error}</CenterError>}
      <SpaceBetweenRow style={{ width: "100%", opacity: loading ? 0.5 : 1 }}>
        <BorderButton
          text={"Shift backward"}
          onClick={() => {
            updateAudioTut(gunshotObservation.audio.tut - 1);
          }}
        />
        <BorderButton
          text={"Shift forward"}
          onClick={() => {
            updateAudioTut(gunshotObservation.audio.tut + 1);
          }}
        />
      </SpaceBetweenRow>
    </Overlay>
  );
};



interface Props extends StateToProps, DispatchToProps, OwnProps {}

interface OwnProps {
    gunshotObservation: GunshotObservation;
    closeCallback: () => void;
}

interface StateToProps {
  jwtToken: string;
}

interface DispatchToProps {
  selectRound: (_: RoundGunshots) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken!,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      selectRound: selectRoundAction
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, OwnProps>(
  //@ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(ShiftAudioOverlay);
