import {Action, Reducer} from "redux";
import {UserData} from "./model/user";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const SET_JWT_TOKEN = "SET_JWT_TOKEN";
const SET_USER_DATA = "SET_USER_DATA";
const SET_USER = "SET_USER";


export interface SetJwtToken extends Action {
    type: "SET_JWT_TOKEN";
    jwtToken: string | undefined;
}

export interface SetUserAction extends Action {
    type: "SET_USER";
    user: firebase.User | undefined;
}

export const setUserAction = (user: firebase.User | undefined): SetUserAction => {
    return { type: SET_USER, user };
};

export interface SetUserDataAction extends Action {
    type: "SET_USER_DATA";
    userData: UserData | undefined;
}

export const setJwtTokenAction = (jwtToken: string | undefined): SetJwtToken => {
    return {type: SET_JWT_TOKEN, jwtToken};
};

export interface SetUserData extends Action {
    type: "SET_USER_DATA";
    userData?: UserData;
}

export const setUserDataAction = (userData: UserData | undefined): SetUserData => {
    return {type: SET_USER_DATA, userData};
};

export interface LoginReducerState {
    jwtToken: string | undefined;
    user: firebase.User | undefined;
    userData: UserData | undefined;
}

const initialState: LoginReducerState = {
    jwtToken: undefined,
    user: undefined,
    userData: undefined,
};

const reducer: Reducer<LoginReducerState> = (
    state: LoginReducerState = initialState,
    action
) => {
    switch ((action as Action).type) {
        case SET_JWT_TOKEN: {
            return {
                ...state,
                jwtToken: action.jwtToken,
            };
        }
        case SET_USER_DATA: {
            return {
                ...state,
                userData: action.userData,
            };
        }
        case SET_USER: {
            return { ...state, user: action.user };
        }
        default:
            return state;
    }
};

export default reducer;
