export enum ScoutState {
  Offline = 1, // Not heard from since a tut - ideally, no connections
  Initializing = 3, // Ge tting tut and position
  Ready = 4, // Ready to record or sync
  Listening = 6,
  InaccurateLocation = 7,
  OfflineIgnore = 8, // Not heard from in such a long time so it should be ignored
}

export interface ScoutLocation {
  accuracy: number;
  altitude: number;
  latitude: number;
  longitude: number;
}

export interface DeviceInfo {
  manufacturer: string;
  model: string;
  sdk: string;
}

export interface SampleAverage {
  sampleAverage: number;
  sampleCount: number;
}

export interface AudioStatistics {
  sampleAverages: SampleAverage[];
}

export interface ScoutStatus {
  altitude: number;
  appVersion: string;
  batteryPercent: number;
  clockAccuracy: number;
  created: string;
  dateTime: number;
  gain: number;
  timeServiceName?: string;
  deviceInfo: DeviceInfo;
  deviceId: string;
  id: string;
  latitude: number;
  longitude: number;
  listening: boolean;
  referenceMode: boolean;
  searchMode: boolean;
  threshold: number;
  predictionThreshold: number;
  model: string;
  timeOffset: number;
  timeState: number;
  displayName: string;
  usbCharge?: boolean;
  acCharge?: boolean;
  receivedTut?: number;
  scoutLocation?: ScoutLocation;
  audioStatistics?: AudioStatistics;
  moving?: boolean;
  fixedLocation?: boolean;
  detectorId?: number;
}
export interface Scout {
  deviceId: string;
  state: ScoutState;
  status: ScoutStatus;
  gain?: number;
  timeServiceName?: string;
  predictionThreshold?: number;
  threshold?: number;
  model?: number;
  detectorId?: number;
}
