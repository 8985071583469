import * as React from "react";

interface Props {
  backgroundColor: string;
  width: number;
  height: number;
}

const WarningIcon = ({ width, height, backgroundColor }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={`${height}px`} viewBox="0 0 24 24" width={`${width}px`} fill={backgroundColor}><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>
);

export default WarningIcon;