import { store } from "..";

export const updateUrl = (selectedDevice: string | undefined, shotNumber?: number) => {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set("round-name", store.getState().round.selectedRoundName!);
  searchParams.set(
    "shot-nr",
    !shotNumber ? `${store.getState().round.selectedRound!.observations[0].shot.index}` : `${shotNumber}`
  );
  if (selectedDevice) {
    searchParams.set("device-id", selectedDevice);
  }
  let newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    "/#/round" +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
};
