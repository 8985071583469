
import styled from "styled-components";

const Box = styled.div`
  width: 0.5rem;
  height: 1rem;
  border-radius: 3px;
  border: 1px solid #f3f4f6;
  color: #4B5563;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CorrectedBox = styled(Box)`
  background-color: #34d399;
`;

export const SampleOverASecondBox = styled(Box)`
  background-color: #34d399;
`;

export const NotCorrectedBox = styled(Box)`
  background-color: #E5E7EB//#cdd5db;
`;

export const LowAccuracyBox = styled(Box)`
  background-color: #60a5fa;
`;

export const AmbiguousBox = styled(Box)`
  background-color: #f87171;
`;

export const HighlightBox = styled(Box)`
  background-color: #fbbf24;
`;

export const EmptyBox = styled(Box)`
  opacity: 0;
`;
