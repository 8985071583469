// These imports load individual services into the firebase namespace.
import "firebase/auth";
import { UserData } from "../model/user";
import { db, store } from "../index";
import { FirestoreIncident, Incident } from "../model/incident";
import {
  addIncidentAction,
  addOrganisationAction,
} from "../player/incidentReducer";
import {
  getOrganisationDetails,
} from "../services/adminService";
import { dateToTut } from "../util";

let ref: () => void;

const createObjectPath = (deviceId: string, incidentId: string) => {
  return `inbox/scout/${deviceId}/${incidentId}.wav`;
};

function getAndSetOrganisationDetailsIfNeeded(parsedIncident: Incident) {
  if (
    !store
      .getState()
      .incident.organisations.find(
        (o) => o.organisationId === parsedIncident.organisationId
      )
  ) {
    getOrganisationDetails(
      parsedIncident.organisationId,
      store.getState().login.jwtToken!,
      (organisationDetails) => {
        store.dispatch(addOrganisationAction(organisationDetails));
      }
    );
  }
}

export function createAudioFileRequest(parsedIncident: Incident) {
  return {
    "bucket-name": "triangula-scout-audio",
    filename: `inbox/scout/${parsedIncident.deviceId}/${parsedIncident.id}.wav`,
  };
}

export const listenForPotentialIncidents = (user: UserData | undefined) => {
  if (user && !ref) {
    ref = db
      .doc("admin")
      .collection("potentialIncidents")
      .orderBy("tut", "desc")
      .limit(2)
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change: any) => {
          if (change.type === "added") {
            const incident = mapDocToIncident(change.doc);
            store.dispatch(addIncidentAction(incident));

            getAndSetOrganisationDetailsIfNeeded(incident);
          }
        });
      });
  }
};

const mapDocToIncident = (doc: any) => {
  const data = doc.data() as FirestoreIncident;
  return {
    id: doc.id,
    ...data,
    objectPath: createObjectPath(data.deviceId, doc.id),
    spectrogram: doc.data().spectrogram ? JSON.parse(doc.data().spectrogram) : undefined,
    message: doc.data().message
  } as Incident;
};

export const queryForPotentialIncidents = (
  organisationId: string,
  deviceId: string | undefined,
  incidentId: string | undefined,
  timeLimit?: Date,
) => {
  console.log("Query for incidents", organisationId, deviceId, incidentId)
  if (incidentId) {
    return db.doc(`admin/potentialIncidents/${incidentId}`).get().then((doc) => {
      return [mapDocToIncident(doc)];
    });
  } else {
    let query = db
      .doc("admin")
      .collection("potentialIncidents")
      .where("organisationId", "==", organisationId);
    if (deviceId) {
      query = query.where("deviceId", "==", deviceId);
    }
    if(timeLimit) {
      query = query.where("tut", "<", dateToTut(timeLimit));
    }

    return query
      .orderBy("tut", "desc")
      .limit(50)
      .get()
      .then(({ docs }) => {
        return docs.map((doc) => mapDocToIncident(doc));
      });
  }
};
