import { db } from "..";
import { docToScout } from "../organisation/firestore/scouts";
import { Scout } from "../organisation/model/scout";
import { dateToTut } from "../util";

export const queryForOrganisationStatistics = (organisationId: string): Promise<Scout[]> => {
  return db
    .doc(`commander/organisations/${organisationId}`)
    .collection("scouts")
    .where("status.dateTime", ">", dateToTut(new Date(Date.now() - 60000 * 5)))
    .get()
    .then((snapshot) => {
        return snapshot.docs.map((d) => {
            return docToScout(d);
        })
    });
};
