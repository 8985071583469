import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { RoundChange, RoundNameWithStatistics, RoundOverview, RoundOverviewDevice, RoundOverviewShot } from "../../model/rounds";
import { getMatrixForRound } from "../../services/roundsService";
import LastCorrected from "./LastCorrected";
import { HighlightBox, AmbiguousBox, CorrectedBox, LowAccuracyBox, EmptyBox, NotCorrectedBox } from "./MatrixBoxes";
import { createRoundAnalyzerUrl } from "./util";



const Shots = styled.div`
display: flex;
`

const OverviewWrapper = styled.div`
width: 100%;
overflow-x: scroll;
margin-left: 2rem;
`

interface Props {
    round: RoundNameWithStatistics;
    jwtToken: string;
}

const DeviceAndMatrix = styled.div`
display: flex;
align-items: center;
    
`

const DeviceName = styled.p`
        min-width: 7.5rem;
        text-align: right;
        margin: 0rem;
        padding-right: 1rem;
        font-weight: bold;
        font-size: 0.8rem;
        opacity: .8;
    `

const Anchor = styled.a`
    text-decoration: none;
`

const shot2Component = (shot: RoundOverviewShot, highlight: RoundChange | undefined): JSX.Element => {
    if (highlight) {
        return <HighlightBox />
    }
    if (shot.ambiguous) {
        return <AmbiguousBox />
    }
    if (shot.lowAccuracy) {
        return <LowAccuracyBox />
    }
    if (shot.corrected) {
        return <CorrectedBox>{shot.sampleNumber !== null && shot.sampleNumber > 48000 ? "!" : ""}</CorrectedBox>
    }

    return <NotCorrectedBox />
}

const Device = ({ device, overview }: { device: RoundOverviewDevice, overview: RoundOverview }) => {

    const changesForDevice = Object.entries(overview.changes)
        .filter(([_, change]) => change.deviceId === device.deviceId)


    return (
        <>
            <DeviceAndMatrix>
                <DeviceName>{device.deviceId.substr(0, 6)}</DeviceName>
                <Shots>
                    {
                        Array.from(Array(overview.gunshots).keys()).map((i) => {
                            const gunshot = device.gunshots[i];


                            // if no gunshot 
                            // return "empty box"
                            if (!gunshot) {
                                return <EmptyBox />
                            }

                            const firstChangeForShot = changesForDevice.find(([_, device]) => device.shotNumber === gunshot.shotNumber)
                            const highlight = firstChangeForShot ? firstChangeForShot[1] : undefined

                            return (
                                <Anchor
                                    href={createRoundAnalyzerUrl(overview.roundName, gunshot.shotNumber, device.deviceId)}
                                    target={"_blank"}
                                >
                                    {shot2Component(gunshot, highlight)}
                                </Anchor>
                            )
                        }
                        )}
                </Shots>
            </DeviceAndMatrix>
        </>
    )
}

const RoundMatrix = ({ round, jwtToken }: Props) => {
    const [roundOverview, setRoundOverview] = useState<RoundOverview | undefined>(undefined);
    useEffect(() => {
        getMatrixForRound(jwtToken, round.roundName)
            .then((response) => {
                setRoundOverview(response);
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [round.roundName])

    if (!roundOverview) {
        return <p>Loading</p>
    } else {
        return (
            <>
                <LastCorrected overview={roundOverview}/>
                <OverviewWrapper>{roundOverview.devices.map((d) => {
                    return <Device device={d} overview={roundOverview} />
                })}</OverviewWrapper>
            </>
        );
    }

}

export default RoundMatrix;


