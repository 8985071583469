import * as React from "react";

const PlayIcon = () => (

    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-3">
                <circle id="Oval" fill="#26CD7A" cx="20" cy="20" r="20"></circle>
                <path d="M22.8050333,14.3029999 L28.7309393,24.7604811 C29.1393643,25.4812311 28.8861749,26.3966083 28.1654249,26.8050333 C27.9399052,26.9328278 27.6851175,27 27.425906,27 L15.574094,27 C14.7456668,27 14.074094,26.3284271 14.074094,25.5 C14.074094,25.2407886 14.1412662,24.9860008 14.2690607,24.7604811 L20.1949667,14.3029999 C20.6033917,13.5822499 21.5187689,13.3290605 22.2395189,13.7374855 C22.4757287,13.8713377 22.671181,14.06679 22.8050333,14.3029999 Z" id="Triangle" fill="#FFFFFF" transform="translate(21.500000, 19.500000) rotate(90.000000) translate(-21.500000, -19.500000) "></path>
            </g>
        </g>
    </svg>
);

export default PlayIcon;
