import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { ApplicationState } from "../reducers";
import { OrganisationInfo } from "../services/adminService";
//@ts-ignore
import { useLocation } from "react-router-dom";
import { cleanupOrganisationReducer } from "./organisationReducer";
import { store } from "..";
import { getOrganisationInfo } from "./firestore/organisationInfo";

const Details = ({ organisationInfo }: Props) => {
  const location = useLocation();
  //@ts-ignore
  const username = location && location.state && location.state.username;
  if (organisationInfo) {
    return (
      <div className={"Organisation-details"}>
        <div className={"Organisation-name"}>{organisationInfo.name}</div>
        {username ? <div>{username}</div> : <></>}
        <div>One code: {organisationInfo.accessCode}</div>
        <div>Units: {organisationInfo.units}</div>
        {organisationInfo.sales && <div>Sales account</div>}
      </div>
    );
  } else {
    return <></>;
  }
};

const OrganisationSummary = (props: Props) => {
  useEffect(() => {
    if (props.jwtToken) {
      store.dispatch(cleanupOrganisationReducer())
      getOrganisationInfo(props.organisationId)
      
      return () => {
    };
  } else {
    return () => {}
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.jwtToken]);
  return (
    <section>
      <Details {...props} />
    </section>
  );
};

interface StateToProps {
  jwtToken: string | undefined;
  organisationInfo: OrganisationInfo | undefined;
}

interface DispatchFromProps {}

interface Props extends StateToProps, DispatchFromProps, OwnProps {}

interface OwnProps {
  organisationId: string;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => ({
  organisationInfo: state.organisation.organisationInfo,
  jwtToken: state.login.jwtToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default connect<StateToProps, DispatchFromProps, OwnProps, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationSummary);
