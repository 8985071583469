import * as React from "react";
import { cssColorFromBasedOnPercentage } from "../../player/Player";
import { ScoutStatus } from "../model/scout";
import { BatteryIcon } from "./BatteryIcon";
import { ChargingBatteryIcon } from "./ChargingBatteryIcon";

const ScoutBattery = ({ status }: { status: ScoutStatus }) => {
  const color = cssColorFromBasedOnPercentage(status.batteryPercent);

  return (
    <div>
      <h3 style={{ color, margin: 0 }}>
        {status.batteryPercent}%
        <span style={{marginLeft: "5px"}}>
          {status.usbCharge || status.acCharge ? (
            <ChargingBatteryIcon color={color} />
          ) : (
            <BatteryIcon color={color} />
          )}
        </span>
      </h3>
    </div>
  );
};

export default ScoutBattery;
