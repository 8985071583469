import { useEffect, useRef, useState } from "react";
import LoadingAnimation from "../animation/LoadingAnimation";
import { CenteredRow, FlexSpaceBetweenRow, FlexStartRow, FlexStartRowBottomColumn } from "../layout/Row";
import { WideSection } from "../layout/WideSection";
import { getGunshotStatistics } from "../services/adminService";
import { goToAdminCommander, IsLiveWidget, SubSubSection } from "./Dashboard";
import { Link } from "react-router-dom";
import BorderButton from "../button/BorderButton";

export interface GunshotStatisticsForTimeRanges {
  organisationId: string;
  organisationName: string;
  userId: string;
  lastHourCount: number;
  last24HoursCount: number;
  lastWeekCount: number;
  activeScouts: number;
}

const DashboardButton = ({ organisationId }: { organisationId: string }) => {
  return (
    <Link
      className={"Dashboard-no-decoration"}
      to={{
        pathname: `/organisation/${organisationId}`,
      }}>
      <BorderButton text={"Dashboard"} />
    </Link>
  );
};

const GunshotStatistics = ({ jwtToken }: { jwtToken: string }) => {
  const [statistics, setStatistics] = useState<GunshotStatisticsForTimeRanges[] | undefined>(undefined);
  const jwtTokenRef = useRef(jwtToken);

  const [intervalTimeout, setIntervalTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  useEffect(() => {
    jwtTokenRef.current = jwtToken;
  }, [jwtToken]);

  useEffect(() => {
    if (!intervalTimeout) {
      setIntervalTimeout(
        setInterval(() => {
          getGunshotStatistics(jwtTokenRef.current).then((stats) => {
            setStatistics(stats);
          });
        }, 1000 * 60)
      );
      getGunshotStatistics(jwtToken).then((stats) => {
        setStatistics(stats);
      });
    }
  }, [jwtToken]);

  if (statistics?.length === 0) {
    return (
      <WideSection>
        <h3 className={"Widget-section-title"}>No gunshots the last week</h3>
      </WideSection>
    );
  } else if (statistics === undefined) {
    return (
      <WideSection>
        <h3 className={"Widget-section-title"}>Gunshot activity</h3>
        <CenteredRow>
          <LoadingAnimation height={30} width={30} />
        </CenteredRow>
      </WideSection>
    );
  } else {
    statistics.sort((a, b) => {
      if (a.lastHourCount !== b.lastHourCount) {
        return b.lastHourCount - a.lastHourCount;
      } else if (a.last24HoursCount !== b.last24HoursCount) {
        return b.last24HoursCount - a.last24HoursCount;
      } else if (a.lastWeekCount !== b.lastWeekCount) {
        return b.lastWeekCount - a.lastWeekCount;
      } else {
        return b.activeScouts - a.activeScouts;
      }
    });

    return (
      <WideSection>
        <div>
          <h3 className={"Widget-section-title"}>Gunshot activity</h3>
          <SubSubSection style={{ flexWrap: "wrap" }}>
            {statistics.map((data, index) => (
              <GunshotStatisticsForOrganisation key={index} statistics={data} jwtToken={jwtToken} />
            ))}
          </SubSubSection>
        </div>
      </WideSection>
    );
  }
};

const GunshotStatisticsForOrganisation = ({
  statistics,
  jwtToken,
}: {
  statistics: GunshotStatisticsForTimeRanges;
  jwtToken: string;
}) => {
  return (
    <div style={{ flexBasis: "30rem", maxWidth: "40rem" }} className={"Widget-small-item"}>
      <FlexSpaceBetweenRow>
        <FlexStartRow>
          <h3
            style={{ marginLeft: "1rem", marginBottom: "0", cursor: "pointer" }}
            className={"Widget-section-title"}
            onClick={() => goToAdminCommander(statistics.userId, jwtToken)}>
            {statistics.organisationName}
          </h3>
          <div style={{ marginTop: "1rem", marginLeft: "0.5rem" }}>
            <IsLiveWidget activeScouts={statistics.activeScouts} />
          </div>
        </FlexStartRow>
        <div style={{ marginTop: "1.25rem", marginLeft: "0.5rem" }}>
          <DashboardButton organisationId={statistics.organisationId} />
        </div>
      </FlexSpaceBetweenRow>
      <FlexStartRowBottomColumn>
        <Statistics
          count={statistics.lastHourCount}
          label={`gunshot${statistics.lastHourCount === 1 ? "" : "s"} last hour`}
        />
        <Statistics
          count={statistics.last24HoursCount}
          label={`gunshot${statistics.last24HoursCount === 1 ? "" : "s"} last 24h`}
        />
        <Statistics
          count={statistics.lastWeekCount}
          label={`gunshot${statistics.lastWeekCount === 1 ? "" : "s"} last week`}
        />
      </FlexStartRowBottomColumn>
    </div>
  );
};

const Statistics = ({ label, count }: { label: any; count: number }) => {
  return (
    <div className={"GunshotStatistics-item"}>
      <FlexStartRow>
        <p className={"GunshotStatistics-count"}>{count}</p>
        <div>
          <p className={"GunshotStatistics-label"}>{label}</p>
        </div>
      </FlexStartRow>
    </div>
  );
};

export default GunshotStatistics;
