import * as React from "react";
/* tslint:disable */
export const ChargingBatteryIcon = ({color}:{color: string}) => (
    <svg width="11px" height="14px" viewBox="0 0 11 14" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Device" transform="translate(-391.000000, -50.000000)"  fill={color}>
                <g id="ic_battery_charging_full" transform="translate(384.000000, 48.000000)">
                    <g id="Icon-24px">
                        <path d="M15.67,3.4 L14,3.4 L14,2 L10,2 L10,3.4 L8.33,3.4 C7.6,3.4 7,3.82 7,4.331 L7,15.062 C7,15.58 7.6,16 8.33,16 L15.66,16 C16.4,16 17,15.58 17,15.069 L17,4.331 C17,3.82 16.4,3.4 15.67,3.4 L15.67,3.4 Z M11,14.6 L11,10.75 L9,10.75 L13,5.5 L13,9.35 L15,9.35 L11,14.6 L11,14.6 Z" id="Shape"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);