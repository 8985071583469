import { degreesToRadians, radiansToDegrees } from "@turf/helpers";
import { BasePosition, GunshotObservation } from "./model/rounds";

export const TUT_BIRTHDAY_MILLIS = 1500854400000;
export const tutToDate = (tut: number) =>
  new Date(tut / 1000 + TUT_BIRTHDAY_MILLIS);

export const dateToTut = (newDate?: Date) => {
  return ((newDate ? newDate : new Date()).getTime() - TUT_BIRTHDAY_MILLIS) * 1000;
};

export const coordLengthBearingToCoord = (
  coord: BasePosition,
  bearing: number,
  distance: number
): BasePosition => {
  const R = 6378.1; //Radius of the Earth
  const brng = degreesToRadians(bearing); // Bearing is converted to radians.
  const d = distance; //Distance in km

  const lat1 = degreesToRadians(coord.lat); //Current lat point converted to radians
  const lon1 = degreesToRadians(coord.lon);

  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(d / R) +
      Math.cos(lat1) * Math.sin(d / R) * Math.cos(brng)
  );

  const lon2 =
    lon1 +
    Math.atan2(
      Math.sin(brng) * Math.sin(d / R) * Math.cos(lat1),
      Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2)
    );

  return { lat: radiansToDegrees(lat2), lon: radiansToDegrees(lon2) };
};

export const createAudioObjectPath = (selectedRound: GunshotObservation) => {
  return `${selectedRound.audio.file_location.rel_path}/${selectedRound.audio.file_location.filename}`;
};
