import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";
import BorderButton from "../../button/BorderButton";
import TuneIcon from "../../icons/TuneIcon";
import { FlexStartRow, VerCenteredRow } from "../../layout/Row";
import { ApplicationState } from "../../reducers";
import { OrganisationInfo } from "../../services/adminService";
import { COLORS_GREY_30, COLORS_ORANGE } from "../../style/variables";
import { tutToDate } from "../../util";
import { getScoutStateDescription } from "../../util/scoutUtil";
import FastTrack from "../FastTrack";
import FastTrackWarning from "../FastTrackWarning";
import { Observation } from "../model/observation";
import { Scout, ScoutState } from "../model/scout";
import AmbientNoiseWidget from "../scout/AmbientNoiseWidget";
import ScoutBattery from "../scout/ScoutBattery";
import OverrideDeviceConfigurations from "./OverrideDeviceConfigurations";
import {Clickable} from "../../layout/Clickable"
import ActionButton from "../../button/ActionButton";

const EventsPrMinute = ({
  count,
  scout,
  organisationInfo,
}: {
  count: number;
  organisationInfo: OrganisationInfo;
  scout: Scout;
}) => {
  return (
    <Link
      className={"Dashboard-no-decoration"}
      to={`/incidents?organisation-id=${organisationInfo.organisationId}&device-id=${scout.status.deviceId}`}
    >
      <BorderButton text={`${count} Events pr min`} />
    </Link>
  );
};

const NameAndDeviceDetailsAndBattery = ({ scout }: { scout: Scout }) => {
  return (
    <div className={"Widget-row"}>
      <div className={["Organisation-name-and-details"].join(" ")}>
        <p>
          {scout.status.displayName}{" "}
          <span style={{ opacity: "0.5" }}>
            {`${scout.status.deviceId.substr(0,4)} • ${getScoutStateDescription(scout)}`}
          </span>
        </p>
        <p>
          {scout.status.deviceInfo.model} • Sdk {scout.status.deviceInfo.sdk} •
          App {scout.status.appVersion}
        </p>
      </div>
      <ScoutBattery status={scout.status} />
    </div>
  );
};

const SummarySection = styled(VerCenteredRow)`
  margin-top: 2rem;
`;

const Summary = ({
  inFastTrack,
  observations,
  scout,
  organisationInfo,
}: {
  inFastTrack: boolean;
  observations: Observation[] | undefined;
  scout: Scout;
  organisationInfo: OrganisationInfo;
}) => {
  return (
    <SummarySection>
      <EventsPrMinute
        count={observations ? observations.length : 0}
        scout={scout}
        organisationInfo={organisationInfo}
      />
      {inFastTrack && <FastTrackWarning />}
    </SummarySection>
  );
};

const OverrideButton = styled.p`
  color: ${COLORS_ORANGE};
`;

const KeyValue = ({
  name,
  value,
  potentialOverrideValue,
}: {
  name: string;
  value: string | number;
  potentialOverrideValue?: any;
}) => {
  return (
    <div className={"Organisation-key-value"}>
      <p>{name}:</p>
      <p>{value}</p>
      {potentialOverrideValue !== undefined &&
        potentialOverrideValue !== null && (
          <OverrideButton>OVERRIDDEN</OverrideButton>
        )}
    </div>
  );
};


const Status = ({ scout }: { scout: Scout }) => {
  return (
    <div className={"Organisation-device-status"}>
      <p>Status</p>

      <KeyValue
        name={"Last update"}
        value={tutToDate(scout.status.dateTime).toISOString()}
      />
      <KeyValue
        name={"Gain"}
        value={scout.status.gain}
        potentialOverrideValue={scout.gain}
      />
      <KeyValue
        name={"Gunshot detector"}
        value={scout.status.model}
        potentialOverrideValue={scout.model}
      />
      <KeyValue
        name={"Prediction threshold"}
        value={scout.status.predictionThreshold}
        potentialOverrideValue={scout.predictionThreshold}
      />
      <KeyValue
        name={"Threshold"}
        value={scout.status.threshold}
        potentialOverrideValue={scout.threshold}
      />
      
    </div>
  );
};

export const OrganisationDevice = ({
  scout,
  observations,
  inFastTrack,
  organisationInfo,
  override,
  userJwtToken,
}: {
  scout: Scout;
  observations: Observation[] | undefined;
  inFastTrack: boolean;
  organisationInfo: OrganisationInfo;
  override: boolean;
  userJwtToken: string | undefined;
}) => {
  return (
    <div
      style={{ maxWidth: "30rem" }}
      className={[
        "Widget-item",
        scout.state === ScoutState.Listening ? "Device-online" : "",
      ].join(" ")}
    >
      <NameAndDeviceDetailsAndBattery scout={scout} />
      <Summary
        inFastTrack={inFastTrack}
        observations={observations}
        scout={scout}
        organisationInfo={organisationInfo}
      />
      <Status scout={scout} />
      {override && <OverrideDeviceConfigurations scout={scout} jwtToken={userJwtToken!} />}
      {scout.status.audioStatistics && (
        <AmbientNoiseWidget statistics={scout.status.audioStatistics} />
      )}
      <FastTrack deviceId={scout.status.deviceId} />
    </div>
  );
};

const orderScouts = (
  value: string,
  scouts: Scout[],
  observations: { [deviceId: string]: Observation[] } | undefined
) => {
  const newList = [...scouts];
  const orderFunctions: {
    [sortingFunctions: string]: (s1: Scout, s2: Scout) => number;
  } = {
    name: (a: Scout, b: Scout) =>
      a.status.displayName < b.status.displayName ? -1 : 1,
    noise: (a: Scout, b: Scout) =>
      b.status.audioStatistics!.sampleAverages[0].sampleAverage -
      a.status.audioStatistics!.sampleAverages[0].sampleAverage,
    event: (a: Scout, b: Scout) =>
      (observations && observations[b.deviceId]
        ? observations[b.deviceId].length
        : 0) -
      (observations && observations[a.deviceId]
        ? observations[a.deviceId].length
        : 0),
  };
  newList.sort(orderFunctions[value]);
  return newList;
};

const OrderBy = ({ callback }: { callback: (_: string) => void }) => {
  return (
    <div className={"Organisation-orderby"}>
      <p>Order by</p>
      <select
        name="orderBy"
        id="orderBy"
        className={"Widget-dropdown"}
        onChange={(e) => callback(e.target.value)}
      >
        <option value="name">Name</option>
        <option value="noise">Noise level</option>
        <option value="event">Events pr min</option>
      </select>
    </div>
  );
};

const DeviceTitle = styled.h2`
  margin-right: 1rem;
`;



const TitleAndButtons = ({override, setOverride}: {override: boolean, setOverride:(_:boolean) => void}) => {
  return (
    <FlexStartRow>
      <DeviceTitle>Devices</DeviceTitle>
      <Clickable onClick={() => setOverride(!override)}>

      <TuneIcon width={30} height={30} backgroundColor={COLORS_GREY_30} />
      </Clickable>
    </FlexStartRow>
  );
};

const OrganisationDevices = ({
  scouts,
  observations,
  fastTrack,
  organisationInfo,
  userJwtToken
}: Props) => {
  const [order, setOrder] = useState("name");
  const [override, setOverride] = useState(false);
  const ordered = orderScouts(
    order,
    Object.values(scouts).filter(
      (s: Scout) =>
        s.state !== ScoutState.Offline && s.state !== ScoutState.OfflineIgnore
    ),
    observations
  );
  return (
    <section className={"Organisation-section"}>
      <div className={["Widget-row", "Organisation-devices-header"].join(" ")}>
        <TitleAndButtons override={override} setOverride={setOverride}/>
        <OrderBy callback={setOrder} />
      </div>
      <div className={"Organisation-devices"}>
        {organisationInfo && 
        (ordered.map((s: Scout) => {
          return (
            <OrganisationDevice
              scout={s}
              observations={observations[s.deviceId]}
              inFastTrack={fastTrack.indexOf(s.status.deviceId) > -1}
              organisationInfo={organisationInfo}
              override={override}
              userJwtToken={userJwtToken}
            />
          );
        }))}
      </div>
    </section>
  );
};

interface StateToProps {
  scouts: { [deviceId: string]: Scout };
  observations: { [deviceId: string]: Observation[] };
  fastTrack: string[];
  organisationInfo?: OrganisationInfo;
  userJwtToken: string | undefined;
}

interface DispatchFromProps {}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  scouts: state.organisation.scouts,
  fastTrack: state.organisation.fastTrack,
  observations: state.organisation.observations,
  organisationInfo: state.organisation.organisationInfo,
  userJwtToken: state.organisation.userJwtToken
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);
export default connect<StateToProps, DispatchFromProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(OrganisationDevices);
