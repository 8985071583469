import React from "react";
import "./SmallLogo.css";

export const SmallLogo = ({
  fontSizeRem,
}: {
  fontSizeRem: number;
}) => (
  <h1 className={"smallLogo"} style={{ fontSize: `${fontSizeRem}rem` }}>
    Triangula
  </h1>
);

export const SmallLogoWithDashboard = ({
  fontSizeRem,
}: {
  fontSizeRem: number;
}) => (
  <a className={"Dashboard-no-decoration"}
      href='/'>
    <div className={"logoContent"}>
      <p className={"smallLogo"} style={{ fontSize: `${fontSizeRem}rem` }}>
        Triangula
      </p>
      <p
        className={["smallLogo", "planningStyle"].join(" ")}
        style={{ fontSize: `${fontSizeRem * 0.7}rem` }}
      >
        Dashboard
      </p>
    </div>
  </a>
);