import React, { useEffect } from "react";
import "./Rounds.css";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import MainMap, { map } from "../map/AlignmentMap";
import RoundSummary from "./RoundSummary";
import RoundOverlay from "./RoundOverlay";
import SelectedGunshotSection from "./SelectedGunshotSection";
import { getRoundInfo } from "../services/roundsService";
import {
  selectRoundAction,
  selectDeviceAction,
  selectRoundNameAction,
} from "./roundReducer";
import { store } from "..";
import TimingCategory from "../timingCategory/TimingCategory";

const RoundPage = (props: Props) => {
  useEffect(() => {
    if (props.jwtToken) {
      //@ts-ignore
      const urlParams = new URLSearchParams(props.location.search);
      const roundName = urlParams.get("round-name")!;
      const deviceId = urlParams.get("device-id")!;
      const shotNr =
        urlParams.get("shot-nr") != null ? +urlParams.get("shot-nr")! : 1;
      getRoundInfo(props.jwtToken, roundName, shotNr, (round) => {
        map.setCenter({
          lat: round.observations[0].device.position.master.lat,
          lng: round.observations[0].device.position.master.lon,
        });
        store.dispatch(selectRoundAction(round));
        store.dispatch(selectRoundNameAction(roundName));
        if (deviceId != null) {
          store.dispatch(selectDeviceAction(deviceId));
        }
      });
    }
  });

  if (props.jwtToken) {
    return (
      <div>
        
        <TimingCategory />
        <MainMap />
        <RoundOverlay>
          <RoundSummary />
        </RoundOverlay>
        <SelectedGunshotSection />
      </div>
    );
  } else {
    return <div />;
  }
};

interface OwnProps {}

interface StateToProps {
  jwtToken: string | undefined;
}

interface DispatchFromProps {}

interface Props extends StateToProps, DispatchFromProps, OwnProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({}, dispatch);

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(RoundPage);
