import React, { useEffect, useState } from "react";
import { updateTarget } from "../services/roundsService";
import { GunshotObservation, Target } from "../model/rounds";
import { store } from "../index";
import { updateTargetAction, suggestTargetAction } from "./roundReducer";
import LoadingAnimation from "../animation/LoadingAnimation";

const TargetOverlay = ({
  jwtToken,
  observation,
  target
}: {
  jwtToken: string;
  target: Target;
  observation: GunshotObservation;
}) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        store.dispatch(suggestTargetAction(undefined));
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
  });
  return (
    <div
      className={"Round-image-overlay"}
      style={{
        textAlign: "center",
        padding: "70px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <div style={{ display: "flex", marginBottom: "30px" }}>
        <div className={"Round-subheader"}>Do you want to set the target?</div>
      </div>

      <div
        className={"Round-button"}
        onClick={() => {
          setLoading(true);
          updateTarget(
            jwtToken,
            observation,
            target,
            () => {
              store.dispatch(updateTargetAction(target));
              store.dispatch(suggestTargetAction(undefined));
              setLoading(false);
            },
            msg => {
              alert(msg);
              setLoading(false);
            }
          );
        }}
      >
        {loading ? (
          <LoadingAnimation height={15} width={15} color={"white"} />
        ) : (
          "SET TARGET"
        )}
      </div>
    </div>
  );
};

export default TargetOverlay;
