import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../reducers";
import {
  addOrRemoveGunshotDirectionScoutAction,
  addWeaponDetailsAction,
  DirectionPrediction,
} from "./roundReducer";
import { bindActionCreators, Dispatch } from "redux";
import { GunshotObservation, RoundDevice } from "../model/rounds";
import { getWeaponDetailsPrediction, WeaponDetails } from "../services/adminService";
import { FlexStartRow } from "../layout/Row";
import styled from "styled-components";
import { cssColorFromBasedOnPercentage } from "../player/Player";

const StyledClassification = styled.div`
  font-size: .8rem;
  font-weight: 500;
  margin-right: 0.5rem;
  border-radius: 36px;
  padding: 3px 7px;
  border: 1px solid #dae1e7;
  flex-wrap: nowrap;
    display: flex;
  p {
    margin: 0;
    margin-left: 0.2rem;
  }
`

const Classification = ({label, probability}: {label: string, probability: number}) => {
  return (
  <StyledClassification>
    <p>{label}</p>
    <p style={{color: cssColorFromBasedOnPercentage(probability)}}>{probability.toFixed(2)}%</p>
  </StyledClassification>
  )
}

const AddToGunshotDirection = ({
  device,
  addOrRemoveGunshotDirectionScout,
  gunshotDirectionScouts,
  scouts,
  directionPrediction,
  jwtToken,
  weaponDetails,
  addWeaponDetails,
  sampleNumber
}: Props) => {
  useEffect(() => {
    console.log("RELOAD",1)
    addWeaponDetails(undefined)
    getWeaponDetails(gunshotDirectionScouts, scouts, jwtToken, (t) => {
      console.log("WEAPON_DETAILS",1, t)
      addWeaponDetails(t)
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gunshotDirectionScouts, device, sampleNumber]);
  return (
    <FlexStartRow
      style={{
        marginLeft: "10px",
      }}
    >
      <input
        type="checkbox"
        name="gunshot_direction_scout"
        checked={
          gunshotDirectionScouts.find((s) => s === device.name) !== undefined
        }
        onChange={() => addOrRemoveGunshotDirectionScout(device.name)}
      />
      <label className={"Round-title"} style={{margin: 0, marginRight: "0.5rem"}}>
        Include in gunshot direction ({directionPrediction.scoutsUsed.length}{" "}
        scouts)
      </label>
      {weaponDetails && (
        <>
        <p className={"Round-title"} style={{margin: 0, marginRight: "0.5rem"}}>Weapon details: </p>
          <Classification label={weaponDetails.type.name} probability={weaponDetails.type.probability}/>
          <Classification label={weaponDetails.silencer.attached ? "Silencer" : "No silencer"} probability={weaponDetails.silencer.probability}/>
          <Classification label={`Caliber: ${weaponDetails.caliber.size}`} probability={weaponDetails.caliber.probability}/>
        </>
      )}
    </FlexStartRow>
  );
};

function getWeaponDetails(
  scoutsToUse: string[],
  devices: GunshotObservation[],
  jwtToken: string, 
  callback: (details: WeaponDetails) => void
) {
  console.log("INSIDE")
  const devicesToUse = devices.filter(
    (s) =>
    scoutsToUse.findIndex((s2) => s.device.name === s2) > -1 &&
    ((s.selectedSample != null &&s.selectedSample.sample_number) || (s.manual &&
    s.manual !== null &&
    s.manual.sample_number &&
    s.manual.sample_number !== null))
    );
  if (devicesToUse.length > 2) {
    getWeaponDetailsPrediction(devicesToUse, jwtToken, callback);
  }
}

interface Props extends StateToProps, DispatchToProps, OwnProps {}

interface OwnProps {
  device: RoundDevice;
  sampleNumber: number | undefined;
}

interface StateToProps {
  gunshotDirectionScouts: string[];
  directionPrediction: DirectionPrediction;
  scouts: GunshotObservation[];
  jwtToken: string;
  weaponDetails: WeaponDetails | undefined;
}

interface DispatchToProps {
  addOrRemoveGunshotDirectionScout: (_: string) => void;
  addWeaponDetails: (_: WeaponDetails | undefined) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  gunshotDirectionScouts: state.round.gunshotDirectionScouts,
  directionPrediction: state.round.directionPrediction,
  scouts: state.round.selectedRound!.observations,
  jwtToken: state.login.jwtToken!,
  weaponDetails: state.round.weaponDetails,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addOrRemoveGunshotDirectionScout: addOrRemoveGunshotDirectionScoutAction,
      addWeaponDetails: addWeaponDetailsAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, OwnProps, ApplicationState>(
  //@ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(AddToGunshotDirection);

