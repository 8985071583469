export const COLORS_BLUE = "#4A90E2"
export const COLORS_PRIMARY= "#0B1525";
export const COLORS_GREEN="#53c68c";
export const COLORS_RED="#fb5c5c";
export const COLORS_ORANGE="#cb7428";

export const COLORS_GREY_03= "hsla(200, 50%, 98%, 1)";
export const COLORS_GREY_05= "#eef4f7";
export const COLORS_GREY_10= "hsl(208, 21%, 88%)";
export const COLORS_GREY_20= "hsl(210, 16%, 76%)";
export const COLORS_GREY_30= "hsl(208, 12%, 58%)";
export const COLORS_GREY_40= "hsl(207, 12%, 43%)";
export const COLORS_GREY_50= "hsl(209, 15%, 28%)";
export const COLORS_GREY_60= "hsl(207, 29%, 21%)";
export const COLORS_GREY_90= "hsl(207, 76%, 3%)";