import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import LogoutWidget from "../logout/LogoutWidget";
import { UserData } from "../model/user";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { setUserAction, setUserDataAction } from "../loginReducer";
import { connect } from "react-redux";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";
import OrganisationSummary from "./OrganisationSummary";
import "./Organisation.scss";
import OrganisationStatus from "./OrganisationStatus";
import { listenForScouts } from "./firestore/scouts";
import OrganisationDevices from "./device/OrganisationDevices";
import { listenForObservations } from "./firestore/observations";
import { store } from "..";
import {
  addOrganisationInfoAction,
  addPopupAction,
  cleanupOrganisationReducer,
} from "./organisationReducer";
import { listenForGunshots } from "./firestore/triangulations";
import { listenForGlobal } from "../firebase/global";
import NotificationOverlay from "../status/NotificationOverlay";
import { FlexEndRow, SpaceBetweenRow } from "../layout/Row";
import { Link } from "react-router-dom";
import BorderButton from "../button/BorderButton";
import { getUserForOrganisation } from "./firestore/userForOrganisation";
import { Status, StatusMessage } from "../status/status";

const IncidentLink = ({ organisationId }: { organisationId: string }) => {
  return (
    <Link
      className={"Dashboard-no-decoration"}
      to={`/incidents?organisation-id=${organisationId}`}
    >
      <BorderButton text={"Event log"} />
    </Link>
  );
};

const ClockAnalytics = ({ organisationId }: { organisationId: string }) => {
  return (
    <Link
      className={"Dashboard-no-decoration"}
      to={`/analytics/organisation/${organisationId}/clock`}
    >
      <BorderButton text={"Clock analytics"} />
    </Link>
  );
};

const AppVersionLink = ({ organisationId }: { organisationId: string }) => {
  return (
    <Link
      className={"Dashboard-no-decoration"}
      to={`/scoutVersions?organisation-id=${organisationId}`}
    >
      <BorderButton text={"App versions"} />
    </Link>
  );
};

const LogCommand = ({ organisationId, addPopup }: { organisationId: string, addPopup: (_:StatusMessage) => void  }) => {
  return (

      <BorderButton text={"Terminal log"} onClick={() => {
        navigator.clipboard
          .writeText(String.raw`kubectl logs -f deployment/kernel-service -c kernel-service | awk -v svc="\033[34m[kernel-service]\033[0m" '/"organisation": "${organisationId}"/ {gsub(/.*message": "/, "", $0); gsub(/".*/, "", $0); gsub(/\\n/, "\n"svc" ", $0); print svc, $0}' & \
          kubectl logs -f deployment/triangulation-service -c triangulation-service | awk -v svc="\033[31m[triangulation-service]\033[0m" '/"organisation": "${organisationId}"/ {gsub(/.*message": "/, "", $0); gsub(/".*/, "", $0); gsub(/\\n/, "\n"svc" ", $0); print svc, $0}' & \
          kubectl logs -f deployment/superlab-service -c superlab-service | awk -v svc="\033[32m[superlab-service]\033[0m" '/"organisation": "${organisationId}"/ {gsub(/.*message": "/, "", $0); gsub(/".*/, "", $0); gsub(/\\n/, "\n"svc" ", $0); print svc, $0}'`)
          addPopup({component: "Clipboard", message: "Copied command to clipboard", status: Status.Ok, useInPopup: true, id: "copy_log_cmd_to_clipboard"})
      }}/>
    
  );
};

const Content = ({ organisationId, addPopup }: { organisationId: string, addPopup: (_:StatusMessage) => void }) => {
  return (
    <main className={"Organisation-content"}>
      <SpaceBetweenRow className={"Organisation-section"}>
        <OrganisationSummary organisationId={organisationId} />
        <FlexEndRow>
        <ClockAnalytics organisationId={organisationId} />
        <AppVersionLink organisationId={organisationId} />
        <IncidentLink organisationId={organisationId} />
        <LogCommand organisationId={organisationId} addPopup={addPopup}/>
        </FlexEndRow>
      </SpaceBetweenRow>
      <OrganisationStatus />
      <OrganisationDevices />
    </main>
  );
};

const OrgansiationPage = (props: Props) => {
  //@ts-ignore
  const organisationId: string = props.match.params.id;

  useEffect(() => {
    if (props.jwtToken) {
      store.dispatch(cleanupOrganisationReducer());
      const unsubscribeCallback = listenForScouts(organisationId);
      const unsubscribeObservations = listenForObservations(organisationId);
      const unsubscribeGunshots = listenForGunshots(organisationId);
      const unsubscribeGlobal = listenForGlobal(props.jwtToken!);
      getUserForOrganisation(organisationId, props.jwtToken);

      return () => {
        console.log("unsubscribe");
        unsubscribeCallback();
        unsubscribeObservations();
        unsubscribeGunshots();
        unsubscribeGlobal();
        store.dispatch(addOrganisationInfoAction(undefined));
        console.log("unsubscribe finished");
      };
    } else {
      return () => {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.jwtToken]);
  return (
    <div className="Dashboard">
      <div className={"Dashboard-header"}>
        <SmallLogoWithDashboard fontSizeRem={1.625} />
        <LogoutWidget {...props} />
      </div>
      <NotificationOverlay />

      <Content organisationId={organisationId} addPopup={props.addPopup}/>
    </div>
  );
};

interface State {
  loading: boolean;
  loadingUserData: boolean;
  unregisterAuthObserver: firebase.Unsubscribe | undefined;
}

interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
  addPopup: (_: StatusMessage) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
  user: state.login.user,
  userData: state.login.userData,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
      setUserData: setUserDataAction,
      addPopup: addPopupAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(OrgansiationPage);
