import React from "react";
import "./LoadingAnimation.css";

const LoadingAnimation = ({height, width, color}: {height: number, width: number, color?: string}) => {
    return (
        <svg style={{height: `${height}px`, width: `${width}px`}} className={"LoadingAnimation-svg"} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle style={{stroke: color ? color : "#2f3d4c"}} className={"LoadingAnimation-svg-circle"} cx="50" cy="50" r="45"/>
        </svg>
    );
};

export default LoadingAnimation;