import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import styled from "styled-components";
import { setUserAction, setUserDataAction } from "../loginReducer";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";
import LogoutWidget from "../logout/LogoutWidget";
import { RoundNameWithStatistics } from "../model/rounds";
import { UserData } from "../model/user";
import { ApplicationState } from "../reducers";
import { getStatisticsForRounds } from "../services/roundsService";
import { useHistory } from "react-router-dom";
import RoundDropdown from "../round/overview/RoundDropdown";
import RoundMatrix from "../round/overview/RoundMatrix";
import MatrixDoc from "../round/overview/MatrixDoc";




const ContentWrapper = styled.div`
margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Content = (props: Props) => {
    const { jwtToken } = props;
    const [round, setRound] = useState<RoundNameWithStatistics | undefined>(undefined);
    const [rounds, setRounds] = useState<RoundNameWithStatistics[]>([]);
    useEffect(() => {
        if (jwtToken) {
            getStatisticsForRounds(jwtToken, (r) => {
                setRounds(r);
                // @ts-ignore
                const roundNameInUrl = new URLSearchParams(props.location.search).get("roundName")
                setRound(roundNameInUrl ? r.find((r2) => r2.roundName === roundNameInUrl) : r[0]);
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jwtToken])
    let history = useHistory();

    if (!rounds.length) {
        return <p>Loading</p>
    } else {
        return (
            <>
                <RoundDropdown
                    selected={round}
                    onSelect={(r) => {
                        setRound(r);
                        history.push(`/round/overview?roundName=${r.roundName}`)
                    }}
                    rounds={rounds}
                />
                <MatrixDoc />
                {round && jwtToken !== undefined && <RoundMatrix round={round} jwtToken={jwtToken} />}
            </>
        )
    }
}


const RoundOverviewPage = (props: Props) => {
    return (
        <div className="Dashboard" style={{backgroundColor: "#F3F4F6"}}>
            <div className={"Dashboard-header"}>
                <SmallLogoWithDashboard fontSizeRem={1.625} />
                <LogoutWidget {...props} />
            </div>
            <ContentWrapper>
                <Content {...props} />
            </ContentWrapper>
        </div>
    )
}


interface State {
    loading: boolean;
    loadingUserData: boolean;
    unregisterAuthObserver: firebase.Unsubscribe | undefined;
}

interface StateToProps {
    jwtToken: string | undefined;
    user: firebase.User | undefined;
    userData: UserData | undefined;
}

interface DispatchFromProps {
    setUser: (_: firebase.User | undefined) => void;
    setUserData: (_: UserData | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps { }

const mapStateToProps = (state: ApplicationState) => ({
    jwtToken: state.login.jwtToken,
    user: state.login.user,
    userData: state.login.userData,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setUser: setUserAction,
            setUserData: setUserDataAction,
        },
        dispatch
    );

export default connect<StateToProps, DispatchFromProps>(
    // @ts-ignore
    mapStateToProps,
    mapDispatchToProps
)(RoundOverviewPage);

