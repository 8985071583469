import { Scout, ScoutState } from "../organisation/model/scout";

export const getScoutStateDescription = (scout: Scout) => {
    switch (scout.state) {
      case ScoutState.Offline:
        return "Offline";
      case ScoutState.Ready:
        return "Muted";
      case ScoutState.Listening:
        return "Listening";
      case ScoutState.Initializing:
        return "Initializing";
      case ScoutState.InaccurateLocation:
        return "No location";
      default:
        return "Unknown state";
    }
  };