import React, { useState } from "react";
// These imports load individual services into the firebase namespace.
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import LogoutWidget from "../logout/LogoutWidget";
import { UserData } from "../model/user";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { setUserAction, setUserDataAction } from "../loginReducer";
import { connect } from "react-redux";
import "./SystemSettings.css";
import "../widget.scss";
import {
  updateTimingScores,
  updateDetection,
  updateTemperature,
} from "../services/adminService";
import { Configuration } from "../firebase/configuration";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";

const Detection = ({ jwtToken, configuration }: TimingProps) => {
  return (
    <section className={"Widget-section"}>
      <div className={"Widget-content"}>
        <h3 className={"Widget-section-title"}>Detection</h3>
        <div className="Widget-widgets">
          <DetectionAlgorithm
            jwtToken={jwtToken}
            configuration={configuration}
          />
          <DetectionAmbientThreshold
            jwtToken={jwtToken}
            configuration={configuration}
          />
          <Gain jwtToken={jwtToken} configuration={configuration} />
          <Temperature jwtToken={jwtToken} configuration={configuration} />
        </div>
      </div>
    </section>
  );
};

interface StringFormProps {
  label: string;
  value: string;
  onChange: (_: string) => void;
}

const Form = ({ label, onChange, value }: StringFormProps) => {
  return (
    <div>
      <div className={"Widget-form"}>
        <p>{label}</p>
        <input value={value} onChange={(e) => onChange(e.target.value)} />
      </div>
    </div>
  );
};

const TimingScoreForms = ({ configuration, jwtToken }: TimingProps) => {
  const [gale, setGale] = useState(`${configuration.timingScores.gale}`);
  const [average, setAverage] = useState(
    `${configuration.timingScores.average}`
  );
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <div className={"Widget-forms"}>
        <Form label="Gale" onChange={(s) => setGale(s)} value={gale} />
        <Form label="Average" onChange={(s) => setAverage(s)} value={average} />
      </div>
      {!loading ? (
        <p
          className={"Dashboard-clickable-text"}
          onClick={() => {
            setLoading(true);
            updateTimingScores(
              jwtToken,
              {
                gale: +gale,
                average: +average,
              },
              () => setLoading(false)
            );
          }}
        >
          UPDATE
        </p>
      ) : (
        <p className={"Dashboard-clickable-text"}>SAVING...</p>
      )}
    </div>
  );
};

const DetectionAlgorithmForms = ({ configuration, jwtToken }: TimingProps) => {
  const [model, setModel] = useState(configuration.detection.model);
  const [predictionThreshold, setPredictionThreshold] = useState(
    `${configuration.detection.predictionThreshold}`
  );
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <div className={"Widget-forms"}>
        <Form label="Model" onChange={(s) => setModel(s)} value={model} />
        <Form
          label="Prediction threshold"
          onChange={(s) => setPredictionThreshold(s)}
          value={predictionThreshold}
        />
      </div>
      {!loading ? (
        <p
          className={"Dashboard-clickable-text"}
          onClick={() => {
            setLoading(true);
            updateDetection(
              jwtToken,
              {
                model,
                predictionThreshold: +predictionThreshold,
                ambientThreshold: configuration.detection.ambientThreshold,
                gain: configuration.detection.gain,
              },
              () => setLoading(false)
            );
          }}
        >
          UPDATE
        </p>
      ) : (
        <p className={"Dashboard-clickable-text"}>SAVING...</p>
      )}
    </div>
  );
};

const AmbientForms = ({ configuration, jwtToken }: TimingProps) => {
  const [ambientThreshold, setAmbientThreshold] = useState(
    `${configuration.detection.ambientThreshold}`
  );
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <div className={"Widget-forms"}>
        <Form
          label="Threshold (dBfs)"
          onChange={(s) => setAmbientThreshold(s)}
          value={ambientThreshold}
        />
      </div>
      {!loading ? (
        <p
          className={"Dashboard-clickable-text"}
          onClick={() => {
            setLoading(true);
            updateDetection(
              jwtToken,
              {
                model: configuration.detection.model,
                predictionThreshold:
                  configuration.detection.predictionThreshold,
                ambientThreshold: +ambientThreshold + 0.0,
                gain: configuration.detection.gain,
              },
              () => setLoading(false)
            );
          }}
        >
          UPDATE
        </p>
      ) : (
        <p className={"Dashboard-clickable-text"}>SAVING...</p>
      )}
    </div>
  );
};

const GainForm = ({ configuration, jwtToken }: TimingProps) => {
  const [gain, setGain] = useState(`${configuration.detection.gain}`);
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <div className={"Widget-forms"}>
        <Form label="Gain" onChange={(s) => setGain(s)} value={gain} />
      </div>
      {!loading ? (
        <p
          className={"Dashboard-clickable-text"}
          onClick={() => {
            setLoading(true);
            updateDetection(
              jwtToken,
              {
                model: configuration.detection.model,
                predictionThreshold:
                  configuration.detection.predictionThreshold,
                ambientThreshold: configuration.detection.ambientThreshold,
                gain: +gain + 0.0,
              },
              () => setLoading(false)
            );
          }}
        >
          UPDATE
        </p>
      ) : (
        <p className={"Dashboard-clickable-text"}>SAVING...</p>
      )}
    </div>
  );
};

const TemperatureForm = ({ configuration, jwtToken }: TimingProps) => {
  const [temperature, setTemperature] = useState(
    `${configuration.temperature}`
  );
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <div className={"Widget-forms"}>
        <Form
          label="Temperature"
          onChange={(s) => setTemperature(s)}
          value={temperature}
        />
      </div>
      {!loading ? (
        <p
          className={"Dashboard-clickable-text"}
          onClick={() => {
            setLoading(true);
            updateTemperature(jwtToken, +temperature + 0.0, () =>
              setLoading(false)
            );
          }}
        >
          UPDATE
        </p>
      ) : (
        <p className={"Dashboard-clickable-text"}>SAVING...</p>
      )}
    </div>
  );
};

const TimingScoring = ({ jwtToken, configuration }: TimingProps) => {
  return (
    <div className={"Widget-item"}>
      <h3 className={"Widget-title"}>Scoring</h3>
      <TimingScoreForms jwtToken={jwtToken} configuration={configuration} />
    </div>
  );
};

const DetectionAlgorithm = ({ jwtToken, configuration }: TimingProps) => {
  return (
    <div className={"Widget-item"}>
      <h3 className={"Widget-title"}>Model</h3>
      <p>
        Model used for detection and prediction threshold for being accepted as
        gunshot
      </p>
      <DetectionAlgorithmForms
        jwtToken={jwtToken}
        configuration={configuration}
      />
    </div>
  );
};

const DetectionAmbientThreshold = ({
  jwtToken,
  configuration,
}: TimingProps) => {
  return (
    <div className={"Widget-item"}>
      <h3 className={"Widget-title"}>Ambient threshold</h3>
      <p>The required audio signal level for running detection</p>
      <AmbientForms jwtToken={jwtToken} configuration={configuration} />
    </div>
  );
};

const Gain = ({ jwtToken, configuration }: TimingProps) => {
  return (
    <div className={"Widget-item"}>
      <h3 className={"Widget-title"}>Gain</h3>
      <p>
        How much to increase the amplitude of the audio signal before processing
      </p>
      <GainForm jwtToken={jwtToken} configuration={configuration} />
    </div>
  );
};

const Temperature = ({ jwtToken, configuration }: TimingProps) => {
  return (
    <div className={"Widget-item"}>
      <h3 className={"Widget-title"}>Temperature</h3>
      <p>
        Adjust global default temperature
      </p>
      <TemperatureForm jwtToken={jwtToken} configuration={configuration} />
    </div>
  );
};

interface TimingProps {
  jwtToken: string;
  configuration: Configuration;
}

const Timing = ({ jwtToken, configuration }: TimingProps) => {
  return (
    <section className={"Widget-section"}>
      <div className={"Widget-content"}>
        <h3 className={"Widget-section-title"}>Timing</h3>
        <div className="Widget-widgets">
          <TimingScoring jwtToken={jwtToken} configuration={configuration} />
        </div>
      </div>
    </section>
  );
};

const SystemSettingsPage = (props: Props) => {
  return (
    <div className="Dashboard">
      <div className={"Dashboard-header"}>
        <SmallLogoWithDashboard fontSizeRem={1.625} />
        <LogoutWidget {...props} />
      </div>
      {props.configuration !== undefined && (
        <>
          <Detection
            configuration={props.configuration!}
            jwtToken={props.jwtToken!}
          />
          <Timing
            configuration={props.configuration!}
            jwtToken={props.jwtToken!}
          />
        </>
      )}
    </div>
  );
};

interface State {}

interface StateToProps {
  jwtToken: string | undefined;
  configuration: Configuration | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
  user: state.login.user,
  userData: state.login.userData,
  configuration: state.configuration.configuration,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
      setUserData: setUserDataAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(SystemSettingsPage);
