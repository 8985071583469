export type DebounceFunction = (value: () => void) => void;

export function debounce(delay: number): DebounceFunction {
  let timeoutId: ReturnType<typeof setTimeout>;
  function debounced(value: () => void) {
    console.log(timeoutId, "Registering debounce")
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      console.log("Executing debounce")
      value();
    }, delay);
  };
  return debounced
};

