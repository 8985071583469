import styled from "styled-components";

const ButtonStyle = styled.div`
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  display: inline-block;
  font-weight: bold;
  margin: 0.125rem;
`;


export default ButtonStyle;
