import React, { useEffect, useState } from "react";
import { GunshotObservation } from "../model/rounds";
import "./Json.css";

export const COLORS = [
  "red",
  "blue",
  "green",
  "pink",
  "orange",
  "purple",
  "grey",
  "black",
];

const RawJsonOverlay = ({
  round,
  closeCallback,
}: {
  round: GunshotObservation;
  jwtToken: string;
  closeCallback: () => void;
}) => {
  const [currentDevice, setCurrentDevice] = useState("");

  useEffect(() => {
    if (round.device.name !== currentDevice) {
      setCurrentDevice(round.device.name);
    }

    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
  }, [round, currentDevice, closeCallback]);
  return (
    <div
      style={{
        maxHeight: "50%",
        overflowY: "scroll",
        marginLeft: "-200px",
        width: `${window.innerWidth - 500}px`,
      }}
      className={"Round-image-overlay"}
    >
      <textarea style={{ width: "90vw", height: "50vh" }}>
        {JSON.stringify(round, null, 4)}
      </textarea>
    </div>
  );
};

export default RawJsonOverlay;
