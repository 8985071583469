import { LngLat, Map } from "mapbox-gl";
import * as React from "react";
import { CSSProperties } from "react";
import { MapViewType } from "./MapViewType";
import MapboxContainer from "./MapboxContainer";
import DeviceLayer from "./DeviceLayer";
import StandplassLayer from "./StandplassLayer";
import TargetLayer from "./TargetLayer";
import TimingLinesLayer from "./TimingLinesLayer";
import TimingsLayer from "./TimingsLayer";
import SuggestedTargetLayer from "./SuggestedTargetLayer";
import BulletLayer from "./BulletLayer";
import GunshotDirectionLayer from "./GunshotDirectionLayer";

export interface Props {
  map: Map | undefined;
  mapRef: React.RefObject<HTMLDivElement>;
  styling: CSSProperties;
  focused: LngLat | undefined;
  loaded: boolean;
  currentMapType: MapViewType;
  mapType?: string | undefined;
}

const Mapbox = (props: Props) => {
  const map = props.map;
  return (
    <>
      {map !== undefined && (
        <>
          <DeviceLayer
            map={map}
            loaded={props.loaded}
            mapType={props.currentMapType}
          />
          <StandplassLayer
            map={map}
            loaded={props.loaded}
            mapType={props.currentMapType}
          />
          <TargetLayer
            map={map}
            loaded={props.loaded}
            mapType={props.currentMapType}
          />
          <SuggestedTargetLayer
            map={map}
            loaded={props.loaded}
            mapType={props.currentMapType}
          />
          <TimingLinesLayer
            map={map}
            loaded={props.loaded}
            mapType={props.currentMapType}
          />
          <BulletLayer
            map={map}
            loaded={props.loaded}
            mapType={props.currentMapType}
          />
          <TimingsLayer
            map={map}
            loaded={props.loaded}
            mapType={props.currentMapType}
          />
          <GunshotDirectionLayer
            map={map}
            loaded={props.loaded}
            mapType={props.currentMapType}
          />
        </>
      )}
      <MapboxContainer {...props} />
    </>
  );
};

export default Mapbox;
