import { db, store } from "../..";
import { getLoginInfo } from "../../services/adminService";
import { addUserJwtAction } from "../organisationReducer";


export const getUserForOrganisation = (
  organisationId: string,
  jwtToken: string
) => {
   db
    .doc("commander")
    .collection("users")
    .where("organisation", "==", organisationId)
    .limit(1)
    .get()
    .then((snapshot) => {
        if ( snapshot.docs.length) {
            getLoginInfo(snapshot.docs[0].id, jwtToken)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data;
        
        store.dispatch(addUserJwtAction(data.jwtToken));
      } else {
        alert("Couldn't get login credentials");
      }
    })
    .catch((response) => alert("Couldn't get login credentials"))
            
        }
    });
};
