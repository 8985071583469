import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { GunshotObservation } from "../model/rounds";
import { ApplicationState } from "../reducers";
import { MapViewType } from "./MapViewType";

export interface MapboxProps {
  roundGunshot: GunshotObservation[];
  selectedDevice: string | undefined;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  mapType?: MapViewType;
}

export interface Props extends OwnProps, MapboxProps {}

function addLocalLocationLayer(map: Map) {
  map.addLayer({
    id: "local-location",
    paint: {
      "circle-color": ["get", "color"],
      "circle-opacity": 1,
      "circle-radius": 7,
      "circle-stroke-color": ["get", "stroke_color"],
      "circle-stroke-width": ["get", "stroke_width"]
    },
    source: "local-location",
    type: "circle"
  });
}

function addLocalLocationSource(
  localLocation: GunshotObservation[],
  selectedDevice: string | undefined,
  map: Map
) {
  map.addSource("local-location", {
    data: devicePoints(localLocation, selectedDevice),
    type: "geojson"
  });
}

export const devicePoints = (
  gunshots: GunshotObservation[],
  selectedDevice: string | undefined
): GeoJSON.FeatureCollection => {
  return {
    features: createFeatures(gunshots, selectedDevice),
    type: "FeatureCollection"
  };
};

const createFeatures = (
  gunshots: GunshotObservation[],
  selectedDevice: string | undefined
): any => {
  return gunshots.map(gunshot => {
    return {
      geometry: {
        coordinates: [gunshot.device.position.lon, gunshot.device.position.lat],
        type: "Point"
      },
      properties: {
        id: `${gunshot.device.name}`,
        color: selectedDevice === gunshot.device.name ? "red" : (gunshot.manual !== undefined && gunshot.manual !== null) ? gunshot.manual.unadjustable ? "red" : gunshot.manual.low_accuracy ? "blue" : "rgb(72, 187, 120)" : "white",
        stroke_color: selectedDevice === gunshot.device.name ? "red" : "white",
        stroke_width: selectedDevice === gunshot.device.name ? 10 : 5
      },
      type: "Feature"
    };
  });
};

function updateLocalLocationSource(
  gunshots: GunshotObservation[],
  selectedDevice: string | undefined,
  map: Map
) {
  map
    .getSource("local-location")
    // @ts-ignore
    .setData(devicePoints(gunshots, selectedDevice));
}

const DeviceLayer = ({ map, loaded, roundGunshot, selectedDevice }: Props) => {
  if (map && loaded) {
    if (!map.getSource("local-location")) {
      addLocalLocationSource(roundGunshot, selectedDevice, map);
      addLocalLocationLayer(map);
    } else {
      updateLocalLocationSource(roundGunshot, selectedDevice, map);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedRound = state.round.selectedRound;

  return {
    roundGunshot: selectedRound ? selectedRound.observations : [],
    selectedDevice: state.round.selectedDevice
  };
};
export default connect<MapboxProps, {}, {}, ApplicationState>(mapStateToProps)(DeviceLayer);
