import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ActionButton from "../button/ActionButton";
import SecondaryButton from "../button/SecondaryButton";
import { BigStyledInput } from "../input/Input";
import { FlexStartRow, SpaceBetweenRow } from "../layout/Row";
import {
  goToAdminCommanderByEmail,
  goToAdminCommanderByUserId,
  goToCommanderByEmail,
  goToCommanderByUserId,
} from "../login/LoginByEmail";
import {
  SearchEntity,
  searchForUserAndOrganisation,
  UserSearchResult,
} from "../services/adminService";
import { debounce, DebounceFunction } from "../util/debouncer";
import { CustomLink, getInviteLink } from "./Dashboard";
import "./Dashboard.css";

export const StyledListItem = styled.div`
  padding: 0.5rem;
  border-radius: 10px;
`;

const SearchRow = ({
  item,
  jwtToken,
  selected,
  onClick,
}: {
  selected: boolean;
  item: UserSearchResult;
  jwtToken: string;
  onClick: () => void;
}) => {
  const [copied, setCopied] = useState(false);

  const getInviteLinkAndCopy = () => {
    getInviteLink(item.ownerId, jwtToken, "https://commander.triangula.no")
      .then((url) => {
        navigator.clipboard
          .writeText(url)
          .then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
          })
          .catch(() => {
            alert(
              `Copy to clipboard not supported by your device. When clicking OK, you'll be sent to the invite link, then copy url from address bar`
            );
            const win = window.open(`${url}`, "_self");
            // @ts-ignore
            win.focus();
          });
      })
      .catch((response) => alert("Could not get token for invite link"));
  };

  return (
    <StyledListItem
      style={selected ? { backgroundColor: "#f5f5f5" } : {}}
      onClick={() => onClick()}
    >
      <SpaceBetweenRow>
        <FlexStartRow>
          <div
            style={{ whiteSpace: "nowrap", cursor: "pointer" }}
            className="GunshotStatistics-label"
          >
            {item.name}
          </div>
        </FlexStartRow>
        <SecondaryButton text={item.entity} />
      </SpaceBetweenRow>
      {selected && (
        <FlexStartRow>
          <ActionButton
            text={"PUBLIC LOGIN"}
            onClick={() => goToCommanderByUserId(item.ownerId, jwtToken)}
          />
          <ActionButton
            text={"ADMIN LOGIN"}
            onClick={() => goToAdminCommanderByUserId(item.ownerId, jwtToken)}
          />
          <ActionButton
            text={copied ? "COPIED!" : "INVITE LINK"}
            onClick={getInviteLinkAndCopy}
          />

          {item.entity === SearchEntity.organisation && (
            <Link
              className={"Dashboard-no-decoration"}
              to={{
                pathname: `/organisation/${item.id}`,
                state: { username: item.name },
              }}
            >
              <ActionButton
                text={"DASHBOARD"}
                onClick={() =>
                  goToAdminCommanderByEmail(item.ownerId, jwtToken)
                }
              />
            </Link>
          )}
        </FlexStartRow>
      )}
    </StyledListItem>
  );
};

const UserSearch = ({ jwtToken }: { jwtToken: string }) => {
  const [currentValue, setCurrentValue] = useState("");
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchResult, setSeachResult] = useState<UserSearchResult[]>([]);
  const debouncer = useRef<DebounceFunction>(debounce(1000));

  useEffect(() => {
    if (currentValue.length) {
      setLoading(true);
    }
    debouncer.current(() => {
      if (currentValue === "") {
        setSeachResult([]);
      } else {
        searchForUserAndOrganisation(jwtToken, currentValue)
          .then((result) => {
            setLoading(false);
            setSeachResult(result);
          })
          .catch((error) => {
            setLoading(false);
            alert(
              `Failed to search for user ${currentValue}: ${error.message}}`
            );
          });
      }
    });
  }, [currentValue]);
  return (
    <div className={"Dashboard-search-section Widget-small-item"}>
      <BigStyledInput
        placeholder="Enter user or organisation"
        value={currentValue}
        onChange={(t) => setCurrentValue(t.target.value)}
      ></BigStyledInput>
      {searchResult.map((result) => (
        <SearchRow
          item={result}
          jwtToken={jwtToken}
          selected={selected === result.id}
          onClick={() => setSelected(result.id)}
        />
      ))}
      {currentValue.length > 0 && !searchResult.length && !loading && (
        <h3>No search result for "{currentValue}"</h3>
      )}
      {!currentValue.length && (
        <>
          <CustomLink
            uid={"AKPJWlO43FPofxhX84jPugNmwKf2"}
            organisationId={"9"}
            username={"triangulascout@gmail.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"AVlPPcW0eHb8FEPeHt4eAdLcZTp1"}
            organisationId={"18"}
            username={"triangulashowcase@gmail.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"mZpPqQblFWXeiu5cIpjM4ncLjsH2"}
            organisationId={"7"}
            username={"sales@triangula.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"mLr09uUG7ZaJxJ1DgaBoUouCu0K2"}
            organisationId={"19"}
            username={"kit1@triangula.no"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"mMAUd5sE5RVd4Lkq40e0DTwLnuW2"}
            organisationId={"20"}
            username={"kit2@triangula.no"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"4AHZfMDBfzYvywjdzBrbi7R3pmF3"}
            organisationId={"12"}
            username={"triangulademo@gmail.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"zuZGT6LgSqh4swwXfJtHE9db1J23"}
            organisationId={"52"}
            username={"ios@triangula.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"2jXahmQYbhM7f662tKIozj4OkIz2"}
            organisationId={"75"}
            username={"triangula.test@icloud.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"DxmMLDS48pQLBWdcvbTs3EsoCyv1"}
            organisationId={"84"}
            username={"festningen@triangula.com"}
            jwtToken={jwtToken}
          />
          <CustomLink
            uid={"kYBSBEgrWNYwH9V3FIx5M3ddERx2"}
            organisationId={"85"}
            username={"rena@triangula.com"}
            jwtToken={jwtToken}
          />
        </>
      )}
    </div>
  );
};

export default UserSearch;
