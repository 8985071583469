import styled from "styled-components";


export const Text = styled.p`
  margin: 2.5px 0 2.5px 0;
  font-size: 13px;
  font-weight: bold;
`

export const Description = styled(Text)`
  color: hsl(207, 12%, 43%);
`;

export const CenterDescription = styled(Description)`
    text-align:center;
`



export const ErrorText = styled(Text)`
  color: #EF4444;
  font-weight: bold;
`;

export const CenterError = styled(ErrorText)`
    text-align:center;
`

