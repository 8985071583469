import React, { useEffect, useState } from "react";
import {
  analyzeModel,
  getClassActivationMap,
  getNetworkDescription,
  NetworkLayer,
} from "../services/roundsService";
import { AudioFile } from "../services/adminService";
import styled from "styled-components";
import { FlexStartRow, SpaceBetweenRow } from "../layout/Row";

const LayerStyle = styled.div`
  margin: 0.5rem;
  width: 5.5rem;
`;

const Overlay = styled.div`
  width: 900px;
`;

const Layer = ({
  layer,
  setLayer,
  selected,
  failed,
  loading,
}: {
  layer: NetworkLayer;
  setLayer: (_: NetworkLayer) => void;
  selected: NetworkLayer | undefined;
  failed: boolean;
  loading: boolean;
}) => {
  return (
    <FlexStartRow>
      <LayerStyle
        style={failed ? { backgroundColor: "red" } : {}}
        className={
          selected && layer.number === selected.number
            ? "Incident-tag-filtered"
            : "Incident-tag"
        }
        onClick={() => {
          setLayer(layer);
        }}
      >
        {layer.name}
        {failed ? ` [FAILED]` : ""}
      </LayerStyle>
      {loading && <p className={"Round-top-title"}>[LOADING]</p>}
    </FlexStartRow>
  );
};

const ModelAnalyzerOverlay = ({
  file,
  jwtToken,
  closeCallback,
}: {
  file: AudioFile;
  jwtToken: string;
  closeCallback: () => void;
}) => {
  const [img, setImg] = useState<string | undefined>(undefined);
  const [selectedLayer, setLayer] = useState<NetworkLayer | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [layers, setLayers] = useState<NetworkLayer[]>([]);
  useEffect(() => {
    getNetworkDescription(jwtToken, (i) => setLayers(i));
    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!selectedLayer) {
      getClassActivationMap(jwtToken, file, (i) => setImg(i));
    }
    if (selectedLayer) {
      setLoading(true);
      analyzeModel(
        jwtToken,
        file,
        selectedLayer.number - layers.length,
        (i) => {
          setLoading(false);
          setFailed(false);
          setImg(i);
        },
        () => {
          setFailed(true);
        }
      );
    }
    // eslint-disable-next-line
  }, [selectedLayer, file]);

  return (
    <Overlay className={"Overlay-centered-column Round-image-overlay"}>
      <SpaceBetweenRow style={{ width: "100%" }}>
        <div>
          {layers.map((l, i) => (
            <Layer
              layer={l}
              setLayer={setLayer}
              selected={selectedLayer}
              failed={selectedLayer === l && failed}
              loading={selectedLayer === l && loading}
            />
          ))}
        </div>
        {img ? (
          <img
            style={{ maxWidth: "700px" }}
            alt="Model analyzer"
            src={`${img}`}
          />
        ) : (
          <div />
        )}
      </SpaceBetweenRow>
      <p className={"Dashboard-clickable-text"} onClick={closeCallback}>
        Close
      </p>
    </Overlay>
  );
};

export default ModelAnalyzerOverlay;
