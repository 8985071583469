import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {compose, createStore} from "redux";
import reducers from "./reducers";
import {config} from "./firebaseUtil";

const composeEnhancer =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(reducers,   composeEnhancer());

firebase.initializeApp(config);

const fstore = firebase.firestore();
// // Disable deprecated features
// fstore.settings({
//     timestampsInSnapshots: true,
// });

export const db = fstore
    .collection("env")
    .doc("prod")
    .collection("app");


ReactDOM.render(  <Provider store={store}>
    <App/>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
