import styled from "styled-components";

export const H2 = styled.h2`
  color: #0b1525;
`;
export const H3 = styled.h2`
  color: #0b1525;
`;
export const H4 = styled.h2`
  color: #0b1525;
`;


