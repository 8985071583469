import React, { useEffect, useState } from "react";
import { getImage } from "../services/roundsService";

const RoundImageOverlay = ({
  url,
  jwtToken,
  closeCallback,
}: {
  url: string;
  jwtToken: string;
  closeCallback: () => void;
}) => {
  const [img, setImg] = useState<string | undefined>(undefined);
  const [currentImage, setCurrentImage] = useState<string | undefined>(
    undefined
  );
  // eslint-disable-next-line
  useEffect(() => {
    if (currentImage !== url) {
      console.log("Current", currentImage, url);
      setCurrentImage(url);
      getImage(url, jwtToken, (i) => setImg(i));
    }

    const escapeKeyHandler = (e: KeyboardEvent) => {
      const ESCAPE_KEY = 27;
      if (ESCAPE_KEY === e.keyCode) {
        closeCallback();
      }
    };

    document.addEventListener("keydown", escapeKeyHandler);
    return () => document.removeEventListener("keydown", escapeKeyHandler);
  });

  return (
    <div className={"Round-image-overlay"}>
      {img && <img alt="Debug shot" src={`${img}`} />}
      <p className={"Dashboard-clickable-text"} onClick={closeCallback}>
        Close
      </p>
    </div>
  );
};

export default RoundImageOverlay;
