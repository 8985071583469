import * as React from "react";
/* tslint:disable */
export const BatteryIcon = ({color}:{color: string}) => (
    <svg width="11px" height="14px" viewBox="0 0 11 14" version="1.1">
        <desc>Created with Sketch.</desc>
        <g id="Signup-Ipad-landskap" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="First-time-in-commander" transform="translate(-465.000000, -797.000000)" fill={color}>
                <g id="Scouts" transform="translate(13.000000, 658.000000)">
                    <g id="Battery" transform="translate(414.000000, 135.000000)">
                        <path d="M47.5744063,5.4 L45.784375,5.4 L45.784375,4 L41.496875,4 L41.496875,5.4 L39.7068438,5.4 C38.924375,5.4 38.28125,5.82 38.28125,6.331 L38.28125,17.062 C38.28125,17.58 38.924375,18 39.7068438,18 L47.5636875,18 C48.356875,18 49,17.58 49,17.069 L49,6.331 C49,5.82 48.356875,5.4 47.5744063,5.4 L47.5744063,5.4 Z" id="Shape"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
