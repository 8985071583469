import React from "react";
import styled from "styled-components";
import Input from "../../input/Input";
import { PairKey } from "../../layout/Pair";
import { FlexStartRow } from "../../layout/Row";
import {
  resetProperty,
  setDetectorForScout,
  setGainForScout,
  setModelForScout,
  setPredictionThresholdForScout,
  setThresholdForScout,
  setTimeServiceForScout,
} from "../../services/organisationService";
import { Scout } from "../model/scout";
import { Link } from "react-router-dom";
import ActionButton from "../../button/ActionButton";

const FieldAndLabel = styled.div`
  p {
    color: $colors-primary;
    width: 5.5rem;
    margin: 0.05rem;
    margin-right: 0.1rem;
  }
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;
const LabelAndReset = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Reset = styled.div`
  cursor: pointer;
  text-decoration: underline;
  color: $colors-primary;
  font-weight: bold;
  font-size: 0.75rem;
`;

const Field = ({
  value,
  statusValue,
  property,
  displayName,
  deviceId,
  callback,
  jwtToken,
}: {
  value?: number | string;
  statusValue?: number | string;
  property: string;
  displayName: string;
  deviceId: string;
  callback: (value: string) => void;
  jwtToken: string;
}) => {
  return (
    <FieldAndLabel>
      <LabelAndReset>
        <PairKey>{displayName}</PairKey>
        <Reset
          onClick={() => {
              resetProperty(deviceId, property, jwtToken)
          }}
          style={{ cursor: "pointer" }}
        >
          RESET
        </Reset>
      </LabelAndReset>
      <div>
        <Input
          id={`${property}_${deviceId}_${value}`}
          placeholder={"Not specified"}
          value={value !== undefined && value !== null ? `${value}` : ""}
          onConfirm={(t) => {
            if (t && t !== "not specified") {
              if (t) {
                callback(`${t}`);
              } else {
                alert("Cannot store undefined");
              }
            }
          }}
        />
        {value !== undefined && value !== statusValue && value !== null && (
          <p>
            <i>Syncing...</i>
          </p>
        )}
      </div>
    </FieldAndLabel>
  );
};

const ConfigurationSection = styled.section`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const ClockAnalytics = ({deviceId}: {deviceId: String}) => (

  <Link
  className={"Dashboard-no-decoration"}
  to={`/analytics/scout/${deviceId}/clock`}
  >
      <ActionButton
          style={{ marginLeft: "0rem" }}
          text={"CLOCK ANALYTICS"}
          onClick={() => {
          }}
          />
    </Link>
)


const OverrideDeviceConfigurations = ({
  scout,
  jwtToken,
}: {
  scout: Scout;
  jwtToken: string;
}) => {
  return (
    <ConfigurationSection>
      <FlexStartRow>
        <Field
          value={scout.threshold}
          property={"threshold"}
          displayName={"Threshold"}
          statusValue={scout.status.threshold}
          callback={(value) => {
            setThresholdForScout(scout.status.deviceId, value, jwtToken!);
          }}
          deviceId={scout.deviceId}
          jwtToken={jwtToken}
        />
        <Field
          value={scout.predictionThreshold}
          property={"predictionThreshold"}
          displayName={"Pred. threshold"}
          statusValue={scout.status.predictionThreshold}
          callback={(value) => {
            setPredictionThresholdForScout(scout.status.deviceId, value, jwtToken!);
          }}
          deviceId={scout.deviceId}
          jwtToken={jwtToken}
        />
      </FlexStartRow>
      <FlexStartRow>
        <Field
          value={scout.model}
          property={"model"}
          displayName={"Model"}
          statusValue={scout.status.model}
          callback={(value) =>
            setModelForScout(scout.status.deviceId, value, jwtToken!)
          }
          deviceId={scout.deviceId}
          jwtToken={jwtToken}
        />
        <Field
          value={scout.detectorId}
          property={"detector"}
          displayName={"Detector"}
          statusValue={scout.status.detectorId}
          callback={(value) =>
            setDetectorForScout(scout.deviceId, value, jwtToken!)
          }
          deviceId={scout.deviceId}
          jwtToken={jwtToken}
        />
      </FlexStartRow>
      <FlexStartRow>
        <Field
          value={scout.gain}
          property={"gain"}
          displayName={"Gain"}
          statusValue={scout.status.gain}
          callback={(value) =>
            setGainForScout(scout.deviceId, value, jwtToken!)
          }
          deviceId={scout.deviceId}
          jwtToken={jwtToken}
        />
        <Field
          value={scout.timeServiceName}
          property={"timeService"}
          displayName={"Time service"}
          statusValue={scout.status.timeServiceName}
          callback={(value) =>
            setTimeServiceForScout(scout.deviceId, value, jwtToken!)
          }
          deviceId={scout.deviceId}
          jwtToken={jwtToken!}
          />
      </FlexStartRow>
          <h3>Analytics</h3>
      <ClockAnalytics deviceId={scout.status.deviceId}/>
    </ConfigurationSection>
  );
};

export default OverrideDeviceConfigurations;
