export function rawSpectrogramToRgbBytes(rawSpectrogram: number[][]): number[][][] {
    const hsv = rawSpectrogramToRgbFloatTuple(rawSpectrogram);
    const h = hsv[0];
    const s = hsv[1];
    const v = hsv[2];
  
      const r: number[][] = [];
      const g: number[][] = [];
      const b: number[][] = [];
  
      for (let i = 0; i < h.length; i++) {
          const rRow: number[] = [];
          const gRow: number[] = [];
          const bRow: number[] = [];
  
          for (let j = 0; j < h[i].length; j++) {
              const [red, green, blue] = hsvToRgb(h[i][j], s[i][j], v[i][j]);
              rRow.push(Math.round(red * 255));
              gRow.push(Math.round(green * 255));
              bRow.push(Math.round(blue * 255));
          }
  
          r.push(rRow);
          g.push(gRow);
          b.push(bRow);
      }
  
      return combineChannels(r, g, b);
  }
  
  function hsvToRgb(h: number, s: number, v: number): number[] {
    let r: number = 0
    let g: number = 0
    let b: number = 0
  
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);
  
    switch (i % 6) {
        case 0: r = v; g = t; b = p; break;
        case 1: r = q; g = v; b = p; break;
        case 2: r = p; g = v; b = t; break;
        case 3: r = p; g = q; b = v; break;
        case 4: r = t; g = p; b = v; break;
        case 5: r = v; g = p; b = q; break;
    }
  
    return [r!, g!, b!];
  }
  
  function rawSpectrogramToRgbFloatTuple(rawSpectrogram: number[][]): number[][][] {
    const h: number[][] = [];
    const s: number[][] = [];
    const v: number[][] = [];
  
    for (const row of rawSpectrogram) {
        const hRow: number[] = [];
        const sRow: number[] = [];
        const vRow: number[] = [];
  
        for (const value of row) {
            const hValue = 1 - value;
            const sValue = 1;
            const vValue = 0.75;
  
            hRow.push(hValue);
            sRow.push(sValue);
            vRow.push(vValue);
        }
  
        h.push(hRow);
        s.push(sRow);
        v.push(vRow);
    }
  
    return [h, s, v];
  }
  
  
  function combineChannels(r: number[][], g: number[][], b: number[][]): number[][][] {
    const combined = [];
  
    for (let i = 0; i < r.length; i++) {
        const row = [];
        for (let j = 0; j < r[i].length; j++) {
            // @ts-ignore
            row.push([r[i][j], g[i][j], b[i][j]]);
        }
        
        // @ts-ignore
        combined.push(row);
    }
  
    return combined;
  }