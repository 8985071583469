import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import styled, { keyframes } from "styled-components";
import OkIcon from "../icons/OkIcon";
import WarningIcon from "../icons/WarningIcon";
import { clearPopupQueueAction } from "../organisation/organisationReducer";
import { Status, StatusMessage } from "./status";

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; display: none; }
`;

const WarningWrapperStyle = styled.div`
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0.5rem;
  @media only screen and (max-width: 700px) {
    display: block;
    margin-left: 0;
    transform: translateX(0%);
    margin-bottom: 0rem;
  }
`;

const WarningMsgStyle = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.10), 0 2px 2px rgba(0, 0, 0, 0.10), 0 4px 4px rgba(0, 0, 0, 0.10), 0 8px 8px rgba(0, 0, 0, 0.10);
  max-width: 100rem;
  padding: 0.5rem 2rem 0.5rem 1rem;

  p {
    margin: 0;
    margin-top: 0.125rem;
    margin-left: 0.5rem;
    font-size: 1rem;
    color: $colors-primary;
    font-weight: 500;
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
    border-radius: 0;
  }
`;

const WarningMsgStyleShow = styled(WarningMsgStyle)`
  animation: ${fadeIn} 0.5s ease-in-out;
`;
const WarningMsgStyleHide = styled(WarningMsgStyle)`
  animation: ${fadeOut} 0.5s ease-in-out;
  opacity: 0;
`;

const ShowOrHide = ({
  hide,
  children,
}: {
  hide: boolean;
  children: JSX.Element[];
}) => {
  if (hide) {
    return <WarningMsgStyleHide>{children}</WarningMsgStyleHide>;
  } else {
    return <WarningMsgStyleShow>{children}</WarningMsgStyleShow>;
  }
};

const WarningMsg = ({ status }: { status: StatusMessage }) => {
  const [hide, setHide] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHide(true);
    }, 2500);
  }, []);
  return (
    <WarningWrapperStyle>
      <ShowOrHide hide={hide}>
        {status.status === Status.Ok ? (
          <OkIcon width={30} height={30} backgroundColor={"#029959"} />
        ) : (
          <WarningIcon width={30} height={30} backgroundColor={"#f59e0b"} />
        )}
        <p>{status.message}</p>
      </ShowOrHide>
    </WarningWrapperStyle>
  );
};

const FadeInOverlay = styled.div`
  color: black;
  right: auto;
  width: 100%;
  top: 6rem;
  position: absolute;
  z-index: 3;
  @media only screen and (max-width: 700px) {
    top: 3.75rem;
  }
`

const NotificationContainer = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  return <FadeInOverlay>{children}</FadeInOverlay>;
};

const NotificationOverlay = ({ popupQueue, clearPopupQueue }: Props) => {
  const [fadeTimeout, setFadeTimeout] = useState<NodeJS.Timer | undefined>();

  useEffect(() => {
    if (fadeTimeout) {
      clearTimeout(fadeTimeout);
    }
    if (popupQueue.length) {
      setFadeTimeout(
        setTimeout(() => {
          clearPopupQueue();
          setFadeTimeout(undefined);
        }, 3000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearPopupQueue, popupQueue]);
  return (
    <NotificationContainer>
      {[...popupQueue].map((s) => (
        <WarningMsg key={s.id} status={s} />
      ))}
    </NotificationContainer>
  );
};

interface Props extends DispatchFromProps, StateToProps {}

interface StateToProps {
  popupQueue: StatusMessage[];
}

interface DispatchFromProps {
  clearPopupQueue: () => void;
}

const mapStateToProps = (state: any) => ({
  popupQueue: state.organisation.popupQueue,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearPopupQueue: clearPopupQueueAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationOverlay);
