import { Map } from "mapbox-gl";
import { connect } from "react-redux";
import { BasePosition } from "../model/rounds";
import { ApplicationState } from "../reducers";
import { coordLengthBearingToCoord } from "../util";
import { MapViewType } from "./MapViewType";

export interface MapboxProps {
  directionPredictions: number[];
  gunshotPosition: BasePosition | undefined;
}

export interface OwnProps {
  map: Map;
  loaded: boolean;
  mapType?: MapViewType;
}

export interface Props extends OwnProps, MapboxProps {}

function addLayer(map: Map) {
  map.addLayer({
    id: "gunshot-direction-lines",
    paint: {
      "line-color": ["get", "color"],
      "line-width": ["get", "width"],
      "line-opacity": ["get", "opacity"],
    },
    source: "gunshot-direction-lines",
    type: "line",
  });
}

function addSource(
  map: Map,
  directionPredictions: number[],
  gunshotPosition: BasePosition | undefined
) {
  map.addSource("gunshot-direction-lines", {
    data: lines(directionPredictions, gunshotPosition),
    type: "geojson",
  });
}

export const lines = (
  directionPredictions: number[],
  gunshotPosition: BasePosition | undefined
): GeoJSON.FeatureCollection => {
  return {
    features: createFeatures(directionPredictions, gunshotPosition),
    type: "FeatureCollection",
  };
};

const createFeatures = (
  directionPredictions: number[],
  gunshotPosition: BasePosition | undefined
): any => {
  if (!gunshotPosition || directionPredictions.length === 0) {
    return [];
  }

  return directionPredictions.map((d, i) => {
    const direction = coordLengthBearingToCoord(gunshotPosition, i, 0.02 * d);
    return {
      geometry: {
        coordinates: [
          [gunshotPosition.lon, gunshotPosition.lat],
          [direction.lon, direction.lat],
        ],
        type: "LineString",
      },
      id: `gunshotDirection`,
      properties: {
        id: `gunshotDirection`,
        color: d === 1 ? `yellow` : 'orange',
        width: 3,
        opacity: d,
      },
      type: "Feature",
    };
  });
};

function updateSource(
  map: Map,
  directionPredictions: number[],
  gunshotPosition: BasePosition | undefined
) {
  map
    .getSource("gunshot-direction-lines")
    // @ts-ignore
    .setData(lines(directionPredictions, gunshotPosition));
}

const GunshotDirectionlayer = ({
  map,
  loaded,
  directionPredictions,
  gunshotPosition,
}: Props) => {
  if (map && loaded) {
    if (!map.getSource("gunshot-direction-lines")) {
      addSource(map, directionPredictions, gunshotPosition);
      addLayer(map);
    } else {
      updateSource(map, directionPredictions, gunshotPosition);
    }
  }

  return null;
};

const mapStateToProps = (state: ApplicationState) => {
  const selectedRound = state.round.selectedRound;
  return {
    directionPredictions: state.round.directionPrediction.predictions,
    gunshotPosition: selectedRound
      ? selectedRound.observations[0].device.position.master
      : undefined,
  };
};

// @ts-ignore
export default connect<MapboxProps, {}, {}>(mapStateToProps)(
  GunshotDirectionlayer
);
