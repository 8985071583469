import * as React from "react";
import "./Rounds.css";

interface Props {
    children: JSX.Element;
}

const RoundOverlay = ({children}: Props) => {

    return (
        <div id={"round_overlay"} className={"Round-overlay"}>
            {children}
        </div>
    )
};


export default RoundOverlay;