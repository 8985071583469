import { LngLat, Map } from "mapbox-gl";
import * as React from "react";
import { CSSProperties } from "react";

export interface OwnProps {
  map: Map | undefined;
  mapRef: React.RefObject<HTMLDivElement>;
  styling: CSSProperties;
  focused: LngLat | undefined;
  loaded: boolean;
}

export interface Props extends OwnProps {}

class MapboxContainer extends React.Component<Props> {
  public render() {
    const { styling, mapRef } = this.props;

    return (
      <div
        className={
          styling.position && styling.position === "inherit"
            ? "map_inherit"
            : "map_absolute"
        }
        style={styling}
        ref={mapRef}
      />
    );
  }
}

export default MapboxContainer;
