import { Action, Reducer } from "redux";
import { OrganisationInfo } from "../services/adminService";
import { StatusMessage } from "../status/status";
import { Gunshot } from "./firestore/triangulations";
import { Observation } from "./model/observation";
import { Scout } from "./model/scout";

const SET_SCOUT = "SET_SCOUT";

export interface SetScoutAction extends Action {
  type: "SET_SCOUT";
  scout: Scout | undefined;
}

export const setScoutAction = (scout: Scout): SetScoutAction => {
  return { type: SET_SCOUT, scout };
};

const REMOVE_SCOUT = "REMOVE_SCOUT";

export interface RemoveScoutAction extends Action {
  type: "REMOVE_SCOUT";
  scout: Scout | undefined;
}

export const removeScoutAction = (scout: Scout): RemoveScoutAction => {
  return { type: REMOVE_SCOUT, scout };
};

const ADD_OBSERVATION = "ADD_OBSERVATION";

export interface AddObservationAction extends Action {
  type: "ADD_OBSERVATION";
  observation: Observation;
}

export const addObservationAction = (
  observation: Observation
): AddObservationAction => {
  return { type: ADD_OBSERVATION, observation };
};


const ADD_USER_JWT = "ADD_USER_JWT";

export interface AddUserForOrganisationAction extends Action {
  type: "ADD_USER_JWT";
  jwtToken: string;
}

export const addUserJwtAction = (
  jwtToken: string
): AddUserForOrganisationAction => {
  return { type: ADD_USER_JWT, jwtToken };
};


const ADD_ORGANISATION_INFO = "ADD_ORGANISATION_INFO";

export interface AddOrganisationInfoAction extends Action {
  type: "ADD_ORGANISATION_INFO";
  organisationInfo: OrganisationInfo | undefined;
}

export const addOrganisationInfoAction = (
  organisationInfo: OrganisationInfo | undefined
): AddOrganisationInfoAction => {
  return { type: ADD_ORGANISATION_INFO, organisationInfo };
};

export const CLEAN_UP_ORGANISATION = "CLEAN_UP_ORGANISATION";
export const cleanupOrganisationReducer = () => {
  return {type: CLEAN_UP_ORGANISATION}
}


const ADD_GUNSHOT = "ADD_GUNSHOT";

export interface AddGunshotAction extends Action {
  type: "ADD_GUNSHOT";
  gunshot: Gunshot;
}

export const addGunshotAction = (
  gunshot: Gunshot
): AddGunshotAction => {
  return { type: ADD_GUNSHOT, gunshot };
};

const CLEANUP_OBSERVATION = "CLEANUP_OBSERVATION";

export interface CleanupObservationAction extends Action {
  type: "CLEANUP_OBSERVATION";
}

export const cleanupObservationAction = (
): CleanupObservationAction => {
  return { type: CLEANUP_OBSERVATION };
};


const SET_FAST_TRACK_DEVICES = "SET_FAST_TRACK_DEVICES";
export interface SetFastTrackDevicesAction extends Action {
  type: "SET_FAST_TRACK_DEVICES";
  devices: string[];
}

export const setFastTrackDevicesAction = (
  devices:string[]
): SetFastTrackDevicesAction => {
  return { type: SET_FAST_TRACK_DEVICES, devices };
};

const ADD_POPUP = "ADD_POPUP";
export interface AddPopup extends Action {
  type: "ADD_POPUP";
  statusMessage: StatusMessage;
}

export const addPopupAction = (statusMessage: StatusMessage): AddPopup => {
  return {
    type: ADD_POPUP,
    statusMessage,
  };
};

const CLEAR_POPUP_QUEUE = "CLEAR_POPUP_QUEUE";
export interface ClearPopupQueue extends Action {
  type: "CLEAR_POPUP_QUEUE";
}

export const clearPopupQueueAction = (): ClearPopupQueue => {
  return {
    type: CLEAR_POPUP_QUEUE
  };
};


export interface OrganisationReducerState {
  scouts: { [deviceId: string]: Scout };
  observations: { [deviceId: string]: Observation[] };
  organisationInfo: OrganisationInfo | undefined;
  gunshots: Gunshot[];
  fastTrack: string[];
  popupQueue: StatusMessage[];
  userJwtToken: string | undefined;
}

export const organisationInitialState: OrganisationReducerState = {
  scouts: {},
  observations: {},
  organisationInfo: undefined,
  gunshots: [],
  fastTrack: [],
  popupQueue: [],
  userJwtToken: undefined
};

const reducer: Reducer<OrganisationReducerState> = (
  state: OrganisationReducerState = organisationInitialState,
  action
) => {
  switch ((action as Action).type) {
    case CLEAN_UP_ORGANISATION: {
      return organisationInitialState;
    }
    case ADD_OBSERVATION: {
      const deviceId = action.observation.deviceId;
      if (state.observations[deviceId]) {
        return {
          ...state,
          observations: {
            ...state.observations,
            [deviceId]: [...state.observations[deviceId], action.observation],
          },
        };
      } else {
        return {
          ...state,
          observations: {
            ...state.observations,
            [deviceId]: [action.observation],
          },
        };
      }
    }
    case ADD_POPUP: {
      return {
        ...state,
        popupQueue: [...state.popupQueue, action.statusMessage],
      };
    }
    case ADD_USER_JWT: {
      return {
        ...state,
        userJwtToken: action.jwtToken,
      };
    }

    case ADD_ORGANISATION_INFO: {
      return {
        ...state,
        organisationInfo: action.organisationInfo,
      };
    }

    case SET_FAST_TRACK_DEVICES: {
      return {
        ...state,
        fastTrack: action.devices,
      };
    }

    case ADD_GUNSHOT: {
      return {
        ...state,
        gunshots: [...state.gunshots, action.gunshot],
      };
    }

    case CLEANUP_OBSERVATION: {
      const newList: { [deviceId: string]: Observation[] } = {};

      const oneMinuteAgo = Date.now() - 60000;

      Object.keys(state.observations).forEach(
        (o) =>
          (newList[o] = state.observations[o].filter(
            (o2) => o2.timeInMillis > oneMinuteAgo
          ))
      );

      return {
        ...state,
        observations: newList,
      };
    }
    case SET_SCOUT: {
      return {
        ...state,
        scouts: { ...state.scouts, [action.scout.deviceId]: action.scout },
      };
    }
    case REMOVE_SCOUT: {
      const newScoutList: { [deviceId: string]: Scout } = {};
      Object.values(state.scouts).forEach((s: Scout) => {
        if (s.deviceId !== action.scout.deviceId) {
          newScoutList[`${s.deviceId}`] = s;
        }
      });
      return {
        ...state,
        scouts: newScoutList,
      };
    }
    case CLEAR_POPUP_QUEUE: {
      return {
        ...state,
        popupQueue: [],
      };
    }
    default:
      return state;
  }
};

export default reducer;
