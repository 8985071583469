import axios, { AxiosRequestConfig } from "axios";

interface ThresholdRequest {
  threshold: number;
}

interface ModelRequest {
  model: string;
}

interface DetectorRequest {
  id: string;
}

export const setThresholdForScout = (
  deviceId: string,
  threshold: string,
  jwtToken: string
) => {
  const request: ThresholdRequest = { threshold: +threshold };
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/threshold`,
      request,
      createAuthHeader(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const setOperatingMode = (
  mode: string,
  jwtToken: string,
): Promise<boolean> => {
        return axios
        .put(
          `https://api.triangula.no/alpha/configuration/mode/${mode}`,
          "",
          createAuthHeader(jwtToken)
          )
          .then((response) => {
            if (response.status === 200) {
              return true
              // tslint:disable-next-line: no-empty
            } else {
              throw Error("Could not set operating mode");
            }
          });
};

export const setPredictionThresholdForScout = (
  deviceId: string,
  threshold: string,
  jwtToken: string
) => {
  const request: ThresholdRequest = { threshold: +threshold };
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/predictionThreshold`,
      request,
      createAuthHeader(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const setGainForScout = (
  deviceId: string,
  gain: string,
  jwtToken: string
) => {
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/gain`,
      { gain: +gain },
      createAuthHeader(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const setTimeServiceForScout = (
  deviceId: string,
  timeServiceName: string,
  jwtToken: string
) => {
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/timeService`,
      { timeServiceName: timeServiceName },
      createAuthHeader(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const setModelForScout = (
  deviceId: string,
  model: string,
  jwtToken: string
) => {
  const request: ModelRequest = { model };
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/model`,
      request,
      createAuthHeader(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const resetProperty = (
  deviceId: string,
  key: string,
  jwtToken: string
) => {
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/${key}/reset`,
      {},
      createAuthHeader(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

export const setDetectorForScout = (
  deviceId: string,
  detectorId: string,
  jwtToken: string
) => {
  const request: DetectorRequest = { id: detectorId };
  axios
    .put(
      `https://api.triangula.no/alpha/scouts/${deviceId}/detector`,
      request,
      createAuthHeader(jwtToken)
    )
    .then((response) => {
      if (response.status === 200) {
        // tslint:disable-next-line: no-empty
      } else {
      }
    });
};

function createAuthHeader(jwtToken: string): AxiosRequestConfig | undefined {
  return {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
}
