import React from "react";
import { UserData } from "../model/user";
import firebase from "firebase/compat/app";
import "../dashboard/Dashboard.css";

interface StateToProps {
  jwtToken: string | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

export default ({ userData, user, jwtToken, setUserData, setUser }: Props) => {
  return (
    <div className={"Dashboard-logout"}>
      {user && jwtToken && userData ? (
        <p
          style={{ color: "white" }}
          className={"Dashboard-clickable-text"}
          onClick={() => {
            firebase
              .auth()
              .signOut()
              .then(() => {
                setUserData(undefined);
                setUser(undefined);
              });
          }}
        >
          Sign out
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};
