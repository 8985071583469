import React, { useState, useEffect } from "react";
import { RoundGunshots, RoundStatistics } from "../model/rounds";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { roundStatisticsAction } from "./roundReducer";
import { getRoundStatistics } from "../services/roundsService";
import { cssColorFromBasedOnPercentage } from "../player/Player";
import StatisticsGraphOverlay from "./StatisticsGraphOverlay";
const RoundSummaryStats = ({
  selectedRound,
  roundStatistics,
  jwtToken,
  setRoundStatistics,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [roundName, setRoundName] = useState("");
  useEffect(() => {
    if (roundName !== selectedRound!.observations[0].round.name && jwtToken && selectedRound && !loading) {
      setRoundName(selectedRound!.observations[0].round.name)
      setLoading(true);
      getRoundStatistics(
        jwtToken,
        selectedRound.observations[0].round.name,
        (stats) => {
          setRoundStatistics(stats);
          setLoading(false);
        }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundStatistics, jwtToken, selectedRound, loading, setRoundStatistics]);
  if (roundStatistics) {
    const summary = roundStatistics.roundSummary;
    const total = summary.ambiguous + summary.corrected + summary.notCorrected;
    const correctedPercentage = (summary.corrected / total) * 100;
    const ambiguousPercentage = (summary.ambiguous / total) * 100;
    const notCorrectedPercentage = (summary.notCorrected / total) * 100;
    return (
      <>
        {showGraph && (
          <StatisticsGraphOverlay
            statistics={roundStatistics}
            closeCallback={() => setShowGraph(false)}
          />
        )}
        <p
          style={{ cursor: "pointer" }}
          className={"Round-item"}
          onClick={() => setShowGraph(true)}
        >
          Corrected: {summary.corrected}
          <span
            style={{
              color: cssColorFromBasedOnPercentage(correctedPercentage),
            }}
          >
            ({correctedPercentage.toFixed(0)}%)
          </span>{" "}
          • Ambiguous:
          {summary.ambiguous}
          <span
            style={{
              color: cssColorFromBasedOnPercentage(ambiguousPercentage),
            }}
          >
            ({ambiguousPercentage.toFixed(0)}%)
          </span>{" "}
          • Not corrected: {summary.notCorrected}
          <span
            style={{
              color: cssColorFromBasedOnPercentage(notCorrectedPercentage),
            }}
          >
            ({notCorrectedPercentage.toFixed(0)}%)
          </span>
        </p>
      </>
    );
  } else {
    return <></>;
  }
};

interface Props extends StateToProps, DispatchToProps {}

interface StateToProps {
  selectedRound: RoundGunshots | undefined;
  roundStatistics?: RoundStatistics;
  jwtToken: string | undefined;
}

interface DispatchToProps {
  setRoundStatistics: (_: RoundStatistics) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  selectedRound: state.round.selectedRound,
  roundStatistics: state.round.roundStatistics,
  jwtToken: state.login.jwtToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setRoundStatistics: roundStatisticsAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchToProps, {}, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps
)(RoundSummaryStats);
