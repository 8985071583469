
// Configure Firebase.

export const config = {
    apiKey: "AIzaSyDV5sR5g5VXN00lvTKVD5YwOFnk-yvSu-w",
    authDomain: "triangula-385d2.firebaseapp.com",
    databaseURL: "triangula-commander-beta.firebaseapp.com",
    messagingSenderId: "206485845429",
    projectId: "triangula-385d2",
    storageBucket: "triangula-385d2.appspot.com",
};