import React from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import LogoutWidget from "../logout/LogoutWidget";
import { UserData } from "../model/user";
import { ApplicationState } from "../reducers";
import { bindActionCreators, Dispatch } from "redux";
import { setUserAction, setUserDataAction } from "../loginReducer";
import { connect } from "react-redux";
import "./Monitor.css";
import "../widget.scss";
import { Configuration } from "../firebase/configuration";
import { useState } from "react";
import { useEffect } from "react";
import { getConfigStore } from "../services/adminService";
import LoadingAnimation from "../animation/LoadingAnimation";
import { COLORS } from "../round/GraphOverlay";
import Plot from "react-plotly.js";
import { SmallLogoWithDashboard } from "../logo/SmallLogo";
const { parse } = require("ansicolor");

const TriangulaSetting = ({
  settingsKey,
  jwtToken,
}: {
  settingsKey: string;
  jwtToken: string;
}) => {
  const [value, setValue] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (settingsKey && !value) {
      getConfigStore(jwtToken, settingsKey, (f) => {
        //@ts-ignore
        setValue(f["value"]);
      });
    }
  }, [jwtToken, settingsKey, value]);
  if (value) {
    return (
      <div className="Widget-row">
        <p className="Widget-key">{settingsKey}</p>
        <p className="Widget-value">{value}</p>
      </div>
    );
  } else {
    return <LoadingAnimation width={15} height={15} />;
  }
};

const TriangulaSettings = ({
  jwtToken,
  settings,
}: {
  jwtToken: string;
  settings: string[];
}) => {
  return (
    <div className={"Widget-item"}>
      <h3 className={"Widget-title"}>Triangula settings</h3>
      {settings.map((e: string) => {
        return <TriangulaSetting jwtToken={jwtToken} settingsKey={e} />;
      })}
    </div>
  );
};

const TrainingAndValidationCharts = ({
  jwtToken,
  models,
}: {
  jwtToken: string;
  models: string[];
}) => {
  return (
    <>
      {models.map((model) => (
        <div>
          <h3 style={{ marginLeft: "4rem" }} className={"Widget-title"}>
            {model}
          </h3>
          <TrainingChart
            jwtToken={jwtToken}
            model={`triangula-settings-training_${model}`}
          />
          <ValidationSummary jwtToken={jwtToken} model={model} />
        </div>
      ))}
    </>
  );
};

const TrainingCharts = ({
  jwtToken,
  monitorResources,
}: {
  jwtToken: string;
  monitorResources: MonitorResources;
}) => {
  return (
    <div className={"Widget-items"}>
      <TrainingAndValidationCharts models={monitorResources.models} jwtToken={jwtToken!} />
      {monitorResources.trainingModels.map((model) => (
        <TrainingChart jwtToken={jwtToken} model={model} />
      ))}
    </div>
  );
};

const TrainingChart = ({
  jwtToken,
  model,
}: {
  jwtToken: string;
  model: string;
}) => {
  const [graphData, setGraphData] = useState<
    { [id: string]: number[] } | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (graphData === undefined && !loading) {
      setLoading(true);
      getConfigStore(jwtToken, model, (f) => {
        if (Object.keys(f).length) {
          setGraphData(f.value);
          setLoading(false);
        }
      });
    }
  }, [graphData, loading, jwtToken, model]);

  const epochs =
    graphData && Object.keys(graphData).length
      ? graphData["acc"] ? graphData["acc"].map((_, i) => i + 1) : graphData["accuracy"].map((_, i) => i + 1)
      : 0;

  const modelName = model.split("triangula-settings-training_")[1];
  return (
    <div className={"Wudget-items"}>
      <div className={"Widget-item"}>
        {graphData ? (
          <Plot
            //@ts-ignore
            data={Object.keys(graphData)
              .filter((s) => s.includes("acc"))
              .map((key, i) => ({
                name: key,
                x: epochs,
                y: graphData[key],
                type: "scatter",
                mode: "lines",
                marker: { color: COLORS[i] },
              }))}
            layout={{
              width: window.innerWidth - 200,
              title: `${modelName} accuracy`,
              xaxis: { title: "Epochs" },
            }}
          />
        ) : (
          <LoadingAnimation width={50} height={50} />
        )}
      </div>
      <div className={"Widget-item"}>
        {graphData ? (
          <Plot
            //@ts-ignore
            data={Object.keys(graphData)
              .filter((s) => s.includes("loss"))
              .map((key, i) => ({
                name: key,
                x: epochs,
                y: graphData[key],
                type: "scatter",
                mode: "lines",
                marker: { color: COLORS[i] },
              }))}
            layout={{
              width: window.innerWidth - 200,
              title: `${modelName} loss`,
              xaxis: { title: "Epochs" },
            }}
          />
        ) : (
          <LoadingAnimation width={50} height={50} />
        )}
      </div>
    </div>
  );
};

interface ValidationData {
  summary: string[];
  confusionMatrix?: string;
}

interface TextAndColor {
  text: string;
  color: string;
}

const ValidationSummary = ({
  jwtToken,
  model,
}: {
  jwtToken: string;
  model: string;
}) => {
  const [validationData, setValidationData] = useState<
    ValidationData | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!validationData && !loading) {
      setLoading(true);
      getConfigStore(
        jwtToken,
        `triangula-settings-validation_${model}`,
        (f) => {
          if (Object.keys(f).length) {
            setValidationData(f.value as ValidationData);
            setLoading(false);
          }
        }
      );
    }
  }, [validationData, loading, jwtToken, model]);

  const parsedConfusionMatrix =
    validationData && validationData.confusionMatrix
      ? parse(validationData.confusionMatrix)
      : undefined;
  const confusionMatrix =
    parsedConfusionMatrix &&
    parsedConfusionMatrix.spans.map((e: any) => {
      return {
        text: e.text,
        color: e.css.length > 0 ? e.css.split(":")[1].split(";")[0] : "black",
      } as TextAndColor;
    });
  return (
    <div className={"Monitor-validations Widget-items"}>
      <div style={{ flexGrow: 1 }} className={"Widget-item"}>
        {validationData ?
          validationData.summary.map((s) => (
            <pre style={{ margin: 0, fontSize: "10px" }}>{s}</pre>
          )) : <p>No validator summary available</p>}
      </div>
      <div className={"Widget-item"}>
        {confusionMatrix ? (
          <pre style={{ margin: 0, fontSize: "10px" }}>
            {confusionMatrix.map((e: any) => (
              <span style={{ color: e.color }}>{e.text}</span>
            ))}
          </pre>
        ) : <p>No confusion matrix available</p>}
      </div>
    </div>
  );
};

interface MonitorResources {
  settings: string[];
  trainingModels: string[];
  models: string[];
}

const MonitorPage = (props: Props) => {
  const [monitorResources, setMonitorResources] = useState<
    MonitorResources | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!monitorResources && !loading && props.jwtToken) {
      setLoading(true);
      getConfigStore(props.jwtToken, "monitor-resources", (f) => {
        setMonitorResources(f as MonitorResources);
        setLoading(false);
      });
    }
  }, [monitorResources, loading, props.jwtToken]);

  if (!monitorResources) {
    return <LoadingAnimation height={30} width={30} />;
  }

  return (
    <div className="Dashboard">
      <div className={"Dashboard-header"}>
      <SmallLogoWithDashboard fontSizeRem={1.625}/>
        <LogoutWidget {...props} />
      </div>
      <section className={"Widget-section"}>
        <div className={"Widget-content"}>
          <h3 className={"Widget-section-title"}>Triangula Settings</h3>
          <div className="Widget-widgets">
            {props.jwtToken && (
              <TriangulaSettings
                settings={monitorResources.settings}
                jwtToken={props.jwtToken!}
              />
            )}
          </div>
        </div>
      </section>
      <section className={"Widget-section"}>
        <div className={"Widget-content"}>
          <h3 className={"Widget-section-title"}>Training</h3>
          <div className="Widget-widgets">
            {props.jwtToken && (
              <>
                <TrainingCharts
                  monitorResources={monitorResources}
                  jwtToken={props.jwtToken!}
                />
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

interface StateToProps {
  jwtToken: string | undefined;
  configuration: Configuration | undefined;
  user: firebase.User | undefined;
  userData: UserData | undefined;
}

interface DispatchFromProps {
  setUser: (_: firebase.User | undefined) => void;
  setUserData: (_: UserData | undefined) => void;
}

interface Props extends StateToProps, DispatchFromProps {}

const mapStateToProps = (state: ApplicationState) => ({
  jwtToken: state.login.jwtToken,
  user: state.login.user,
  userData: state.login.userData,
  configuration: state.configuration.configuration,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setUser: setUserAction,
      setUserData: setUserDataAction,
    },
    dispatch
  );

export default connect<StateToProps, DispatchFromProps>(
  // @ts-ignore
  mapStateToProps,
  mapDispatchToProps
)(MonitorPage);
