import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";
import React from "react";

interface Props {
  graphData: number[];
  height: number;
  width: number;
}

const PredictionChart = ({ graphData, height, width }: Props) => {
  return (
    <LineChart
      data={graphData.map((v, i) => ({ prediction: v, index: i }))}
      width={width}
      height={height}
      margin={{
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="5 5 " opacity={0.2} />
      <XAxis dataKey="index" type="number" />
      <YAxis />
      <Legend />
      <Line
        type="monotone"
        dataKey="prediction"
        name={`Prediction`}
        stroke="#8884d8"
        activeDot={{ r: 8 }}
        strokeWidth={3}
      />
      <Tooltip />
    </LineChart>
  );
};

export default PredictionChart;
