import React from "react";
import styled from "styled-components";
import { COLORS_GREEN } from "../style/variables";
import ButtonStyle from "./ButtonStyle";

const Style = styled(ButtonStyle)`
  color: white;
  background: ${COLORS_GREEN};
`;

const SecondaryButton = ({ text }: { text: string }) => {
  return <Style>{text}</Style>;
};

export default SecondaryButton;
