import React, { CSSProperties } from "react";
import styled from "styled-components";
import { COLORS_BLUE } from "../style/variables";

interface Props {
    text: string;
    onClick: () => void;
    style?: CSSProperties;
}

const StyledButton = styled.div`
  color: ${COLORS_BLUE};
  font-weight: bold;
  font-size: 15px;
  margin: 0.75rem;
  cursor: pointer;
`

const ActionButton = ({text, onClick, style}: Props) => {
    return (
        <StyledButton style={style ? style : {}} onClick={onClick}>
            {text}
        </StyledButton>
    )
}

export default ActionButton;